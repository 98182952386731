import { template as template_52389190df054fdcab8c60611685bc90 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import type { PageMeta } from '../../ui-pagination';
import type Ride from '../../../models/ride';
import { dateTime } from '@onwardcare/core/utils/date-format';
import RideCompletionStatus from '../../ride-completion-status';
import RideType from '../../ride-type';
import TransportType from '../../transport-type';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiMenu from '../../ui-menu';
import UiTable, { type ColumnOutput, convertToColumnOutput, convertToSort } from '../../ui-table';
export interface AllRidesOptions {
    sort: string | null;
}
export interface AllRidesGridParams {
    page: number;
    sort: string | null;
}
export interface AllRidesGridSignature {
    Element: HTMLDivElement;
    Args: {
        isLoading: boolean;
        onParamsChanged?: (params: AllRidesGridParams) => void;
        options: AllRidesOptions;
        pageMeta: PageMeta;
        rides: Ride[];
    };
    Blocks: EmptyObject;
}
export default class AllRidesGridComponent extends Component<AllRidesGridSignature> {
    get currentSort() {
        return convertToColumnOutput(this.args.options.sort);
    }
    dateTime(date: Date, timeZone: string) {
        return dateTime(date, {
            timeZone
        });
    }
    @action
    columnClicked(output: ColumnOutput) {
        const params: AllRidesGridParams = {
            page: 1,
            sort: convertToSort(output)
        };
        this.args.onParamsChanged?.(params);
    }
    @action
    pageChanged(page: number) {
        const { sort } = this.args.options;
        const params: AllRidesGridParams = {
            page,
            sort
        };
        this.args.onParamsChanged?.(params);
    }
    static{
        template_52389190df054fdcab8c60611685bc90(`
    <div ...attributes>
      <UiTable
        class='mt-4'
        @isLoading={{@isLoading}}
        @pageMeta={{@pageMeta}}
        @onPageChanged={{this.pageChanged}}
        as |Table|
      >
        <Table.Head
          @currentSort={{this.currentSort}}
          @onColumnClick={{this.columnClicked}}
          as |Head|
        >
          <Head.Th data-test-id='id-col' @name='id'>
            ID
          </Head.Th>
          <Head.Th data-test-id='start-time-col' @name='requested_start_time'>
            Requested Start Time
          </Head.Th>
          <Head.Th
            data-test-id='ride-status-col'
            @name='ride_completion_status'
          >
            Ride Completion Status
          </Head.Th>
          <Head.Th data-test-id='rider-col' @name='rider_display_name'>
            Rider
          </Head.Th>
          <Head.Th>
            Transport Details
          </Head.Th>
          <Head.Th @align='right' />
        </Table.Head>
        <Table.Body as |Body|>
          {{#each @rides as |ride|}}
            <Body.Tr data-test-id='ride-row' as |Row|>
              <Row.Td data-test-id='ride-id'>
                {{ride.id}}
              </Row.Td>
              <Row.Td data-test-id='start-time'>
                {{this.dateTime ride.requestedStartTime ride.account.tzName}}
              </Row.Td>
              <Row.Td data-test-id='ride-status'>
                <RideCompletionStatus @status={{ride.rideCompletionStatus}} />
              </Row.Td>
              <Row.Td data-test-id='rider' @allowWrap={{true}}>
                {{ride.rider.displayName}}
              </Row.Td>
              <Row.Td data-test-id='transport-details' @allowWrap={{true}}>
                <ul>
                  {{#if ride.driver.displayName}}
                    <li>
                      <span data-test-id='driver'>
                        {{ride.driver.displayName}}
                      </span>
                      {{#if ride.driver.transportPartner.name}}
                        <span data-test-id='transport-partner'>
                          ({{ride.driver.transportPartner.name}})
                        </span>
                      {{/if}}
                    </li>
                  {{/if}}
                  {{#if ride.transportType.fullName}}
                    <li>
                      <TransportType
                        @size='small'
                        @transportType={{ride.transportType}}
                      />
                    </li>
                  {{/if}}
                </ul>
              </Row.Td>
              <Row.Td @align='right'>
                <div class='inline-flex justify-end'>
                  <UiBaseLink
                    data-test-id='details-link'
                    class='h-6 rounded-l-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-500'
                    @route='authenticated.admin.rides.details'
                    @model={{ride.id}}
                  >
                    Details
                  </UiBaseLink>

                  <UiMenu as |menu|>
                    <menu.Trigger
                      data-test-id='grid-actions'
                      class='-ml-px h-6 rounded-r-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-500'
                    >
                      <UiIcon @icon='chevron-down' @type='micro' />
                      <span class='sr-only'>Open menu</span>
                    </menu.Trigger>

                    <menu.Button data-test-id='ride-type'>
                      <span class='font-semibold'>Ride Type:</span>
                      <RideType @rideType={{ride.rideType}} />
                    </menu.Button>
                    <menu.Button data-test-id='ride-account'>
                      <span class='font-semibold'>Account:</span>
                      {{ride.account.name}}
                    </menu.Button>
                    {{#if ride.account.parentAccount}}
                      <menu.Button data-test-id='ride-parent-account'>
                        <span class='font-semibold'>Parent Account:</span>
                        {{ride.account.parentAccount.name}}
                      </menu.Button>
                    {{/if}}
                  </UiMenu>
                </div>
              </Row.Td>
            </Body.Tr>
          {{else}}
            <Body.Empty>
              There are no rides.
            </Body.Empty>
          {{/each}}
        </Table.Body>
      </UiTable>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Admin::Rides::AllRidesGrid': typeof AllRidesGridComponent;
    }
}
