import Model, { attr } from '@ember-data/model';

export default class AccountTransportTypeModel extends Model {
  @attr() declare name: string;
  @attr() declare shortName: string;
  @attr() declare fullName: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-transport-type': AccountTransportTypeModel;
  }
}
