import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import type { Resolved } from 'types';
import type RidesNewRoute from '../../../routes/authenticated/rides/new';
import type { RideType } from '../../../components/rides/ride-form';

export default class RidesNewController extends Controller {
  declare model: Resolved<ReturnType<RidesNewRoute['model']>>;

  queryParams = ['rideType'];

  @tracked rideType: RideType = 'one-way';
}
