import { template as template_ff9bb75f7e074d4c891d8fb6ac060f7a } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { queryRecord } from 'ember-data-resources';
import type { EmptyObject } from '@ember/component/helper';
import type PieChartModel from '../models/shared/pie-chart';
import type ReportingFiltersService from '../services/reporting-filters';
import UiCard from '@onwardcare/ui-components/components/ui-card';
import UiPieChart from '@onwardcare/ui-components/components/ui-pie-chart';
import InfoButton from './info-button';
import ChartContainer from './chart-container';
export interface BasicPieChartSignature {
    Element: HTMLDivElement;
    Args: {
        modelName: string;
        title: string;
        tooltipText?: string;
    };
    Blocks: EmptyObject;
}
export default class BasicPieChartComponent extends Component<BasicPieChartSignature> {
    @service
    reportingFilters: ReportingFiltersService;
    chartData = queryRecord<PieChartModel>(this, this.args.modelName, ()=>this.reportingFilters.chartParams);
    get series() {
        return this.chartData.record?.series ?? [];
    }
    get isEmpty() {
        // This handles the use case where the `series` comes back as an empty
        // array.
        //
        // Example:
        // series: []
        if (this.series.length === 0) {
            return true;
        }
        // This handles the use case where all the `data` for every series comes
        // back as an empty array.
        //
        // Example:
        // series: [{
        //   data: []
        // }]
        const emptyData = this.series.every((series)=>{
            return series.data.length === 0;
        });
        if (emptyData) {
            return true;
        }
        // This handles the use case where the the `y` value is "0" for all data
        // arrays in the series.
        //
        // Example:
        // series: [{
        //   data: [{
        //     name: "Item 1",
        //     y: 0
        //   }, {
        //     name: "Item 2",
        //     y: 0
        //   }]
        // }]
        return this.series.every((series)=>{
            return series.data.every((data)=>data.y === 0);
        });
    }
    static{
        template_ff9bb75f7e074d4c891d8fb6ac060f7a(`
    <UiCard @spacing='tight'>
      <h2
        data-test-id='chart-title'
        class='mb-4 text-center text-lg font-semibold text-gray-900 lg:text-xl'
      >
        {{@title}}

        {{#if @tooltipText}}
          <InfoButton class='text-gray-900'>
            {{@tooltipText}}
          </InfoButton>
        {{/if}}
      </h2>

      {{! This would be a w-104 height in TW, but that doesn't exist }}
      {{#let 'h-[416px]' as |heightClasses|}}
        <ChartContainer
          class={{heightClasses}}
          @dataResource={{this.chartData}}
          @isEmpty={{this.isEmpty}}
        >
          <UiPieChart class={{heightClasses}} @data={{this.series}} />
        </ChartContainer>
      {{/let}}
    </UiCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        BasicPieChart: typeof BasicPieChartComponent;
    }
}
