import { template as template_1d68257d423344619c0e752f66979e07 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TOC } from '@ember/component/template-only';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';
// @ts-expect-error TODO: Figure out how to type this.
import Menu from '@onwardcare/ember-headlessui/components/menu';
// @ts-expect-error TODO: Figure out how to type this.
import Transition from '@onwardcare/ember-headlessui/components/transition';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import { Velcro } from 'ember-velcro';
import type FeatureService from '../../services/feature';
import type SessionService from '../../services/session';
interface MenuItemSignature {
    Element: HTMLAnchorElement;
    Args: {
        close: () => void;
        nested?: boolean;
        transportType: string;
    };
    Blocks: {
        default: [];
    };
}
const UiMenuItem: TOC<MenuItemSignature> = template_1d68257d423344619c0e752f66979e07(`
  <UiBaseLink
    @route='authenticated.rides.new'
    @model={{@transportType}}
    @query={{hash rideType='one-way'}}
    data-test-id='menu-item'
    data-transport-type={{@transportType}}
    class='group flex w-full cursor-pointer items-center justify-between px-4 py-2
      {{if @nested "pl-8"}}
      text-left text-sm font-semibold leading-6 text-gray-900 hover:bg-purple-500 hover:text-white'
    ...attributes
    {{on 'click' @close}}
  >
    <span>{{yield}}</span>
    <UiIcon
      @icon='arrow-right'
      @type='mini'
      class='hidden transition group-hover:block'
    />
  </UiBaseLink>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface BookNewMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const TRANSPORT_TYPE_ORDER = [
    'companion',
    'rideshare',
    'gurney',
    'wheelchair',
    'bls_ambulance',
    'cct_ambulance',
    'als_ambulance'
];
export default class BookNewMenu extends Component<BookNewMenuSignature> {
    @service
    feature: FeatureService;
    @service
    session: SessionService;
    @tracked
    expanded = false;
    get showBookNew() {
        if (this.feature.isEnabled('portal_book_new') === false) {
            return false;
        }
        return this.session.accountTransportTypes.length > 0;
    }
    get showDelivery() {
        return this.session.accountTransportTypes?.slice().some((transportType)=>transportType.name === 'companion');
    }
    get transportTypes() {
        return (this.session.accountTransportTypes?.slice().sort((a, b)=>TRANSPORT_TYPE_ORDER.indexOf(a.name) - TRANSPORT_TYPE_ORDER.indexOf(b.name)) ?? []);
    }
    initMenu = modifier(()=>{
        this.expanded = false;
    });
    @action
    toggleRides() {
        this.expanded = !this.expanded;
    }
    static{
        template_1d68257d423344619c0e752f66979e07(`
    {{#if this.showBookNew}}
      <div class='relative mb-5'>
        <Velcro
          @placement='bottom'
          @strategy='absolute'
          @offsetOptions={{0}}
          as |velcro|
        >
          <Menu as |menu|>
            <menu.Button
              {{velcro.hook}}
              class='{{if menu.isOpen "opacity-80" "hover:bg-purple-700"}}
                inline-flex w-full items-center justify-center gap-x-1.5 whitespace-nowrap rounded-md border border-purple-600 bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm transition focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-purple-500 active:bg-purple-600 active:shadow-md'
              data-test-id='book-new'
            >
              Book New
              <UiIcon @icon='plus' @type='micro' />
            </menu.Button>

            <Transition
              @show={{menu.isOpen}}
              @appear={{true}}
              @enter='transition ease-out duration-100'
              @enterFrom='transform opacity-0 scale-95'
              @enterTo='transform opacity-100 scale-100'
              @leave='transition ease-in duration-75'
              @leaveFrom='transform opacity-100 scale-100'
              @leaveTo='transform opacity-0 scale-95'
              class='z-50 w-full'
              {{velcro.loop}}
            >
              <menu.Items
                data-test-id='book-new-menu'
                class='min-w-[12rem] rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'
                @isOpen={{true}}
                {{this.initMenu}}
                as |items|
              >
                <items.Item class='focus:bg-gray-200'>
                  <button
                    type='button'
                    data-test-id='menu-group'
                    class='flex w-full cursor-pointer items-center justify-between px-4 py-2 text-left text-sm font-semibold leading-6 text-gray-900 hover:bg-purple-500 hover:text-white
                      {{if this.expanded "bg-purple-100"}}'
                    {{on 'click' this.toggleRides}}
                  >
                    <span>Ride</span>
                    <UiIcon
                      @icon='chevron-right'
                      @type='mini'
                      class='transition {{if this.expanded "-rotate-90"}}'
                    />
                  </button>
                </items.Item>

                {{#if this.expanded}}
                  {{#each this.transportTypes as |transportType|}}
                    <UiMenuItem
                      @nested={{true}}
                      @transportType={{transportType.name}}
                      @close={{menu.close}}
                    >
                      {{transportType.fullName}}
                    </UiMenuItem>
                  {{/each}}
                {{/if}}

                {{#if this.showDelivery}}
                  <items.Item class='focus:bg-gray-200'>
                    <UiMenuItem @transportType='delivery' @close={{menu.close}}>
                      Delivery
                    </UiMenuItem>
                  </items.Item>
                {{/if}}
              </menu.Items>
            </Transition>
          </Menu>
        </Velcro>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::BookNewMenu': typeof BookNewMenu;
    }
}
