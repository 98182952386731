import { template as template_482f129337cb40d78eea4a652b5f4172 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import RideTypes from '../utils/data/ride-types';
import type { RideTypeId } from '../utils/data/ride-types';
export interface RideTypeSignature {
    Element: HTMLSpanElement;
    Args: {
        rideType: RideTypeId;
    };
    Blocks: EmptyObject;
}
export default class RideTypeComponent extends Component<RideTypeSignature> {
    get rideType() {
        return RideTypes.find((r)=>this.args.rideType === r.id);
    }
    get typeText() {
        return this.rideType?.name ?? 'Unknown';
    }
    static{
        template_482f129337cb40d78eea4a652b5f4172(`
    <span ...attributes>{{this.typeText}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        RideType: typeof RideTypeComponent;
    }
}
