import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import UiButton from '../ui-button.gts';

export interface UiFormActionsSignature {
  Element: HTMLDivElement;
  Args: {
    isRunning?: boolean;
  };
  Blocks: {
    default: [
      {
        Submit: WithBoundArgs<
          typeof UiButton,
          'type' | 'variant' | 'isRunning'
        >;
      },
    ];
  };
}

const UiFormActionsComponent: TOC<UiFormActionsSignature> = <template>
  <div ...attributes>
    {{yield
      (hash
        Submit=(component
          UiButton
          data-test-id='submit-btn'
          type='submit'
          variant='primary'
          isRunning=@isRunning
        )
      )
    }}
  </div>
</template>;

export default UiFormActionsComponent;
