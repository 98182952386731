import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

export interface UiHeadlessComboboxButtonSignature {
  Element: HTMLButtonElement;
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends UiHeadlessComboboxButtonSignature {
  Args: {
    guid: string;
    handleButtonClick: (event: MouseEvent) => void;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    isDisabled: boolean;
    isOpen: boolean;
    labelId: string;
    registerButtonElement: (element: HTMLButtonElement) => void;
  };
}

export default class UiHeadlessComboboxButtonComponent extends Component<InternalSignature> {
  registerButtonElement = modifier((element: HTMLButtonElement) => {
    this.args.registerButtonElement(element);
  });

  <template>
    <button
      type='button'
      role='combobox'
      id='{{@guid}}-button'
      aria-haspopup='listbox'
      aria-controls={{@guid}}
      aria-labelledby={{@labelId}}
      {{on 'click' @handleButtonClick}}
      {{on 'keydown' @handleKeyDown}}
      {{on 'keyup' @handleKeyUp}}
      {{on 'keypress' @handleKeyPress}}
      aria-expanded={{unless @isDisabled (if @isOpen 'true' 'false')}}
      disabled={{@isDisabled}}
      {{this.registerButtonElement}}
      ...attributes
    >
      {{yield}}
    </button>
  </template>
}
