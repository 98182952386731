import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';

export interface UiHeadlessListboxButtonSignature {
  Element: HTMLButtonElement;
  Args: {
    guid: string;
    handleButtonClick: (event: MouseEvent) => void;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    isDisabled: boolean;
    isOpen: boolean;
    labelId?: string;
    registerButtonElement: (element: HTMLButtonElement) => void;
    unregisterButtonElement: (element: HTMLButtonElement) => void;
  };
  Blocks: {
    default: [];
  };
}

export default class UiHeadlessListboxButtonComponent extends Component<UiHeadlessListboxButtonSignature> {
  registerButton = modifier((element: HTMLButtonElement) => {
    this.args.registerButtonElement(element);

    return () => {
      this.args.unregisterButtonElement(element);
    };
  });

  <template>
    <button
      type='button'
      id='{{@guid}}-button'
      data-test-id='headlessui-listbox-button'
      aria-haspopup='listbox'
      aria-controls={{@guid}}
      aria-labelledby={{@labelId}}
      {{on 'click' @handleButtonClick}}
      {{on 'keydown' @handleKeyDown}}
      {{on 'keyup' @handleKeyUp}}
      {{on 'keypress' @handleKeyPress}}
      aria-expanded={{unless @isDisabled (if @isOpen 'true' 'false')}}
      disabled={{@isDisabled}}
      {{this.registerButton}}
      ...attributes
    >
      {{yield}}
    </button>
  </template>
}
