import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { DateGrouping } from '../../utils/filters/date-range';
import type { Filters } from '../../components/reporting/filters';
import { service } from '@ember/service';
import type SessionService from '../../services/session';
import type ReportingFiltersService from '../../services/reporting-filters';
import type { AccountType } from '../../components/filters/account-filter';

export default class ReportingController extends Controller {
  @service declare reportingFilters: ReportingFiltersService;
  @service declare session: SessionService;

  queryParams = [
    'accounts',
    'accountType',
    'dateGrouping',
    'datePeriod',
    'financialResponsibilities',
    'geo',
    'regions',
    'rideStatuses',
    'transportPartners',
    'transportTypes',
    'zipcodes',
  ];

  @tracked _accounts: string[] | undefined = undefined;
  @tracked _accountType: AccountType | null | undefined = undefined;
  @tracked _dateGrouping: DateGrouping | undefined = undefined;
  @tracked _datePeriod: string | undefined = undefined;
  @tracked _financialResponsibilities: string[] | undefined = undefined;
  @tracked _geo: string | null | undefined = undefined;
  @tracked _regions: string[] | undefined = undefined;
  @tracked _rideStatuses: string[] | undefined = undefined;
  @tracked _transportPartners: string[] | undefined = undefined;
  @tracked _transportTypes: string[] | undefined = undefined;
  @tracked _zipcodes: string[] | undefined = undefined;

  get accounts() {
    return this._accounts ?? [];
  }
  set accounts(value: string[] | undefined) {
    const newValue = value ?? [];
    this._accounts = newValue;
    this.reportingFilters.accounts = newValue;
  }

  get accountType() {
    return this._accountType ?? null;
  }
  set accountType(value: AccountType | null | undefined) {
    const newValue = value ?? null;
    this._accountType = newValue;
    this.reportingFilters.accountType = newValue;
  }

  get datePeriod() {
    return this._datePeriod ?? this.reportingFilters.defaultDatePeriod;
  }
  set datePeriod(value: string | undefined) {
    const newValue = value ?? this.reportingFilters.defaultDatePeriod;
    this._datePeriod = newValue;
    this.reportingFilters.datePeriod = newValue;
  }

  get dateGrouping() {
    return this._dateGrouping ?? this.reportingFilters.defaultDateGrouping;
  }
  set dateGrouping(value: DateGrouping | undefined) {
    const newValue = value ?? this.reportingFilters.defaultDateGrouping;
    this._dateGrouping = newValue;
    this.reportingFilters.dateGrouping = newValue;
  }

  get financialResponsibilities() {
    return this._financialResponsibilities ?? [];
  }
  set financialResponsibilities(value: string[] | undefined) {
    const newValue = value ?? [];
    this._financialResponsibilities = newValue;
    this.reportingFilters.financialResponsibilities = newValue;
  }

  get geo() {
    return this._geo ?? null;
  }
  set geo(value: string | null | undefined) {
    const newValue = value === undefined ? null : value;
    this._geo = newValue;
    this.reportingFilters.geo = newValue;
  }

  get regions() {
    return this._regions ?? [];
  }
  set regions(value: string[] | undefined) {
    const newValue = value ?? [];
    this._regions = newValue;
    this.reportingFilters.regions = newValue;
  }

  get rideStatuses() {
    return this._rideStatuses ?? [];
  }
  set rideStatuses(value: string[] | undefined) {
    const newValue = value ?? [];
    this._rideStatuses = newValue;
    this.reportingFilters.rideStatuses = newValue;
  }

  get transportPartners() {
    return this._transportPartners ?? [];
  }
  set transportPartners(value: string[] | undefined) {
    const newValue = value ?? [];
    this._transportPartners = newValue;
    this.reportingFilters.transportPartners = newValue;
  }

  get transportTypes() {
    return this._transportTypes ?? [];
  }
  set transportTypes(value: string[] | undefined) {
    const newValue = value ?? [];
    this._transportTypes = newValue;
    this.reportingFilters.transportTypes = newValue;
  }

  get zipcodes() {
    return this._zipcodes ?? [];
  }
  set zipcodes(value: string[] | undefined) {
    const newValue = value ?? [];
    this._zipcodes = newValue;
    this.reportingFilters.zipcodes = newValue;
  }

  @action
  filtersChanged(filters: Filters) {
    this.accounts = filters.accountIds;
    this.accountType = filters.accountType;
    this.financialResponsibilities = filters.financialResponsibilities;
    this.geo = filters.geoId;
    this.dateGrouping = filters.dateRange.dateGrouping;
    this.datePeriod = filters.dateRange.datePeriod;
    this.regions = filters.regionIds;
    this.rideStatuses = filters.rideCompletionStatuses;
    this.transportPartners = filters.transportPartnerIds;
    this.transportTypes = filters.transportTypeIds;
    this.zipcodes = filters.zipcodeIds;

    this.reportingFilters.updateFilters(filters);
  }
}
