import Model, { attr } from '@ember-data/model';

export default class SuggestedLocationModel extends Model {
  @attr() declare address: string;
  @attr() declare endLocationInstructions: string | null;
  @attr() declare latitude: number;
  // In the future, we probably want to refactor the suggested location so that
  // it just points to a location model instead of duplicating all the fields.
  // For now, we are going to just point to the id as to prevent breaking
  // Dashboard and reduce large changes.
  @attr() declare locationId: number;
  @attr() declare locationPhotoUrl: string | null;
  @attr() declare longitude: number;
  @attr() declare name: string;
  @attr() declare startLocationInstructions: string | null;
  @attr() declare type: string;
  @attr() declare zipcode: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'suggested-location': SuggestedLocationModel;
  }
}
