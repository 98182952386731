import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn, uniqueId } from '@ember/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';
import UiIcon from '../ui-icon.ts';
import type { TabVariation } from '../ui-tab-group.gts';

export interface TabSignature {
  Element: HTMLButtonElement;
  Args: {
    disabled?: boolean;
    fitToParent?: boolean;
    onChange: (id: string) => unknown;
    onKeyUp: (event: KeyboardEvent, id: string) => unknown;
    onTabRegistered: (tabElement: HTMLButtonElement) => unknown;
    panelIds: string[];
    renderAsListbox: boolean;
    selectedIndex: number;
    showListboxOptions: boolean;
    tabIds: string[];
    variation: TabVariation;
  };
  Blocks: {
    default: [];
  };
}

const tabVariationClasses: Record<TabVariation, string> = {
  default:
    'relative overflow-hidden inline-flex h-full items-center justify-center first:rounded-l-lg last:rounded-r-lg px-10 py-4 text-sm font-medium transition-colors text-gray-500 disabled:cursor-not-allowed disabled:text-gray-400 data-[hover]:text-purple-500 data-[hover]:bg-purple-50 after:content-[""] after:h-[2px] after:bg-transparent after:absolute after:bottom-0 after:w-full after:transition-colors data-[selected]:text-gray-900 data-[selected]:after:bg-purple-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-purple-500',
  none: '',
  segmented:
    'inline-flex h-full items-center justify-center rounded-md px-6 py-2.5 text-sm font-semibold transition-colors text-gray-500 disabled:cursor-not-allowed disabled:text-gray-400 [&:not([data-selected])]:data-[hover]:text-gray-900 data-[selected]:bg-white data-[selected]:text-purple-500 data-[selected]:shadow-sm focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-purple-500',
};
const listboxOptionClasses =
  'relative block w-full text-left cursor-default select-none py-2 pl-3 pr-9';

export default class Tab extends Component<TabSignature> {
  @tracked hovered = false;

  id = uniqueId();

  get fitClasses() {
    if (this.args.variation === 'none' || this.args.renderAsListbox) {
      return '';
    }

    return this.args.fitToParent ? 'grow' : '';
  }

  get listboxOptionClasses() {
    return `${listboxOptionClasses} ${
      this.hovered ? 'bg-purple-500 text-white' : 'text-gray-900'
    }`;
  }

  get variationClasses() {
    return this.args.renderAsListbox
      ? this.listboxOptionClasses
      : tabVariationClasses[this.args.variation];
  }

  get isSelected() {
    return this.args.tabIds[this.args.selectedIndex] === this.id;
  }

  get panelId() {
    const index = this.args.tabIds.findIndex((id) => id === this.id);
    return this.args.panelIds[index];
  }

  registerTab = modifier((element: HTMLButtonElement) => {
    this.args.onTabRegistered(element);
  });

  @action
  onKeyUp(event: KeyboardEvent) {
    this.args.onKeyUp(event, this.id);
  }

  @action
  modifyHover(hovered: boolean) {
    // Disabled tabs shouldn't generate the hover state.
    if (this.args.disabled === true) {
      return;
    }

    this.hovered = hovered;
  }

  <template>
    <button
      class='appearance-none
        {{this.fitClasses}}
        {{this.variationClasses}}
        {{if @showListboxOptions "" "hidden"}}'
      id={{this.id}}
      role={{if @renderAsListbox 'option' 'tab'}}
      type='button'
      aria-selected='{{this.isSelected}}'
      aria-controls={{this.panelId}}
      tabindex={{if this.isSelected '0' '-1'}}
      data-test-id='tab'
      data-selected={{this.isSelected}}
      data-hover={{this.hovered}}
      disabled={{@disabled}}
      {{on 'click' (fn @onChange this.id)}}
      {{on 'focus' (fn @onChange this.id)}}
      {{on 'mouseenter' (fn this.modifyHover true)}}
      {{on 'mouseleave' (fn this.modifyHover false)}}
      {{on 'keyup' this.onKeyUp}}
      {{this.registerTab}}
      ...attributes
    >
      {{#if @renderAsListbox}}
        <span
          data-tab='content'
          class='{{if this.isSelected "font-semibold" "font-normal"}}
            flex items-center justify-start truncate'
        >
          {{yield}}
        </span>

        {{#if this.isSelected}}
          <span
            class='{{if this.hovered "text-white" "text-purple-500"}}
              absolute inset-y-0 right-0 flex items-center pr-4'
          >
            <UiIcon @icon='check' @type='mini' />
          </span>
        {{/if}}
      {{else}}
        <span
          data-tab='content'
          class='inline-flex h-full items-center justify-center'
        >
          {{yield}}
        </span>
      {{/if}}
    </button>
  </template>
}
