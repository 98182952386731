import Component from '@glimmer/component';
import { concat, uniqueId } from '@ember/helper';

export interface AccordionContentSignature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends AccordionContentSignature {
  Args: {
    isExpanded: boolean;
    triggerId: string;
    value: string;
  };
}

export default class AccordionContent extends Component<InternalSignature> {
  id = uniqueId();

  get isHidden() {
    return !this.args.isExpanded;
  }

  <template>
    <div
      id={{concat @triggerId '-' @value}}
      role='region'
      aria-labelledby={{@triggerId}}
      data-test-id='accordion-content'
      data-expanded={{if @isExpanded 'true' 'false'}}
      hidden={{this.isHidden}}
      class={{if @isExpanded 'p-4'}}
      ...attributes
    >
      {{#if @isExpanded}}
        {{yield}}
      {{/if}}
    </div>
  </template>
}
