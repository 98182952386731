import Controller from '@ember/controller';
import { service } from '@ember/service';
import type { Resolved } from 'types';
import type RideRoute from '../../../../routes/authenticated/admin/rides/details';
import type SessionService from '../../../../services/session';

export default class RideDetailsController extends Controller {
  declare model: Resolved<ReturnType<RideRoute['model']>>;

  @service declare session: SessionService;
}
