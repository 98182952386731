import { template as template_4cf996b21dd84dc9801b3a91becb52a1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
// @ts-expect-error: Not sure why the built-in types are not working.
import CopyButton from 'ember-cli-clipboard/components/copy-button';
// @ts-expect-error: Need to create the types for this.
import EmberTooltip from 'ember-tooltips/components/ember-tooltip';
interface CopyFieldSignature {
    Element: HTMLDivElement;
    Args: {
        text: string | null;
    };
    Blocks: {
        default: [];
    };
}
export default class CopyField extends Component<CopyFieldSignature> {
    @tracked
    showTooltip = false;
    @action
    onCopy() {
        this.showTooltip = true;
        setTimeout(()=>{
            this.showTooltip = false;
        }, 2000);
    }
    static{
        template_4cf996b21dd84dc9801b3a91becb52a1(`
    <div>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@text}}
      {{/if}}

      {{#if @text}}
        <CopyButton
          @text={{@text}}
          @onSuccess={{this.onCopy}}
          title='copy to clipboard'
          class='hover:text-gray-500 active:text-gray-700'
        >
          <EmberTooltip
            @isShown={{this.showTooltip}}
            @event='none'
            @text='Copied!'
          />
          <UiIcon @icon='clipboard-document-list' @type='micro' />
        </CopyButton>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
