import Service from '@ember/service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { all, restartableTask } from 'ember-concurrency';
import type FinancialResponsibility from '../models/financial-responsibility';
import type PaymentMethod from '../models/payment-method';
import type SessionService from './session';
import type TransportReason from '../models/transport-reason';

/**
 * The account service provides access to account based settings.
 */
export default class AccountService extends Service {
  @service declare session: SessionService;
  @service declare store: Store;

  @tracked _financialResponsibilities: FinancialResponsibility[] = [];
  @tracked _paymentMethods: PaymentMethod[] = [];
  @tracked _transportReasons: TransportReason[] = [];

  /**
   * Returns the financial responsibilities that are available to the current
   * user for their account.
   */
  get financialResponsibilities() {
    return this._financialResponsibilities;
  }

  /**
   * Returns the payment methods that are available to the current user for
   * their account.
   */
  get paymentMethods() {
    return this._paymentMethods;
  }

  /**
   * Returns the transport reasons that are available to the current user for
   * their account.
   */
  get transportReasons() {
    return this._transportReasons;
  }

  /**
   * Loads all the account data needed when booking rides.
   */
  loadRideSettings = restartableTask(async () => {
    const requests = [
      this._fetchFinancialResponsibilities.perform(),
      this._fetchPaymentMethods.perform(),
      this._fetchTransportReasons.perform(),
    ];

    await all(requests);
  });

  /**
   * Reloads the payment methods for the account so that any newly added payment
   * methods are included.
   */
  reloadPaymentMethods = restartableTask(async () => {
    this._fetchPaymentMethods.perform(true);
  });

  _fetchFinancialResponsibilities = restartableTask(async () => {
    if (this._financialResponsibilities.length > 0) {
      return;
    }

    const data = await this.store.query('financial-responsibility', {
      filter: {
        account_id: this.session.account?.id,
      },
    });
    this._financialResponsibilities = data
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder);
  });

  _fetchPaymentMethods = restartableTask(async (reload = false) => {
    if (reload === false && this._paymentMethods.length > 0) {
      return;
    }

    const data = await this.store.query('payment-method', {
      filter: {
        owner_id: this.session.account?.id,
        owner_type: 'Account',
      },
    });
    this._paymentMethods = data.slice();
  });

  _fetchTransportReasons = restartableTask(async () => {
    if (this._transportReasons.length > 0) {
      return;
    }

    const data = await this.store.findAll('transport-reason');
    this._transportReasons = data.slice();
  });
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:account')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('account') declare altName: AccountService;`.
declare module '@ember/service' {
  interface Registry {
    account: AccountService;
  }
}
