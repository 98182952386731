import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';
import type * as Highcharts from 'highcharts';
import { buildSeriesLines } from '../utils/build-series-lines.ts';
import type { ChartDateCategory, LineSeries } from '../types.ts';
import { chartSettings } from '../utils/chart-settings.ts';
import initChart from '../modifiers/init-chart.ts';

const LINE_WIDTH = 4;
const DOT_RADIUS = LINE_WIDTH;
const DOT_RADIUS_HOVER = 5;

export interface UiLineChartSignature {
  Element: HTMLDivElement;
  Args: {
    data: LineSeries[];
    categories: ChartDateCategory[] | string[];
    xAxisFormatter?: (
      context: Highcharts.AxisLabelsFormatterContextObject,
    ) => string;
    xAxisTitle?: Highcharts.XAxisTitleOptions;
  };
}

export default class UiLineChart extends Component<UiLineChartSignature> {
  get animationEnabled(): boolean {
    return macroCondition(isTesting()) ? false : true;
  }

  generateOptions(
    showPoints: boolean,
    indexNumber: number,
  ): Highcharts.SeriesLineOptions {
    let colorIndex = 0;

    if (indexNumber >= chartSettings.seriesColors.length) {
      // If there are more lines than colors, start reusing colors.
      colorIndex = indexNumber % chartSettings.seriesColors.length;
    } else {
      colorIndex = indexNumber;
    }

    const color = chartSettings.seriesColors[colorIndex];

    return {
      marker: {
        enabled: showPoints,
        symbol: 'circle',
        radius: DOT_RADIUS,
        color,
        states: {
          hover: {
            enabled: true,
            radius: DOT_RADIUS_HOVER,
          },
        },
      },
      type: 'line',
    };
  }

  get chartSeries(): Highcharts.SeriesLineOptions[] {
    const series: Highcharts.SeriesLineOptions[] = [];
    const chartData = this.args.data ?? null;

    if (chartData !== null) {
      chartData.forEach((item, index) => {
        const hideMarkerOptions = this.generateOptions(false, index);
        const showMarkerOptions = this.generateOptions(true, index);

        const seriesLines = buildSeriesLines(
          item,
          hideMarkerOptions,
          showMarkerOptions,
        );

        if (seriesLines.solidLine) {
          series.push(seriesLines.solidLine);
        }

        if (seriesLines.pointLine) {
          series.push(seriesLines.pointLine);
        }
      });
    }

    return series;
  }

  get xAxisFormatter() {
    if (this.args.xAxisFormatter) {
      return this.args.xAxisFormatter;
    }

    return function formatter(
      context: Highcharts.AxisLabelsFormatterContextObject,
    ): string {
      return `<span style="font-size: ${chartSettings.axisTextSize}; color: ${chartSettings.axisTextColor}">${context.value}</span>`;
    };
  }

  get categories() {
    return this.args.categories;
  }

  get categoryLabels() {
    return this.categories.map((category) => {
      if (typeof category === 'string' || typeof category === 'number') {
        return category;
      }

      return category.label;
    });
  }

  get xAxisTitle() {
    return this.args.xAxisTitle ? this.args.xAxisTitle : undefined;
  }

  get chartOptions(): Highcharts.Options {
    const series = this.chartSeries;
    const rawColors = series.map((item) => item.marker?.color);
    const colors = rawColors.filter((color) => color !== undefined) as string[];

    return {
      credits: {
        // Gets rid of the "highcharts.com" watermark.
        enabled: false,
      },

      chart: {
        type: 'line',
        animation: this.animationEnabled,
        backgroundColor: 'transparent',
      },

      colors,

      plotOptions: {
        line: {
          lineWidth: LINE_WIDTH,
        },
        series: {
          animation: this.animationEnabled,
        },
      },

      series,

      title: {
        // To get rid of the default title, need to set `text` to `undefined`.
        text: undefined,
      },

      tooltip: {
        formatter: (tooltip: Highcharts.Tooltip) => {
          const hoveredPoint = tooltip.chart.hoverPoint;

          if (hoveredPoint) {
            const { category, series, y } = hoveredPoint;

            const color =
              typeof hoveredPoint.color === 'string' ? hoveredPoint.color : '';
            const yDisplay = y?.toLocaleString();

            return `
              <span class="text-xs">${category}</span>
              <br><span><span style="color: ${color};">●</span> ${series.name}: <b>${yDisplay}</b></span>
            `;
          }

          return undefined;
        },
      },

      xAxis: {
        type: 'linear',
        categories: this.categoryLabels,
        labels: {
          // This needs to use an arrow function so that the `this` context is
          // this component and not the Highcharts context.
          formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
            return this.xAxisFormatter(context);
          },
        },
        lineColor: chartSettings.axisLineColor,
        tickColor: chartSettings.axisTickColor,
        tickWidth: 2,
        tickmarkPlacement: 'on',
        title: this.xAxisTitle,
      },

      yAxis: {
        gridLineColor: chartSettings.gridLineColor,
        gridLineDashStyle: 'ShortDash',
        labels: {
          // If we need to not show decimal places, use the following format:
          // format: '{value:,.0f}',
          // This format uses a comma as thousands separator and allows normal
          // decimal places
          format: '{value:,f}',
          style: {
            color: chartSettings.axisTextColor,
            fontSize: chartSettings.axisTextSize,
          },
        },
        // To get rid of the default title, need to set `text` to `undefined`.
        title: undefined,
      },
    };
  }

  <template>
    <div
      data-test-id='line-chart'
      class='w-full'
      ...attributes
      {{initChart this.chartOptions}}
    ></div>
  </template>
}
