/**
 * Gets the initials of a person.
 *
 * @param firstName The first name of the person.
 * @param lastName The last name of the person.
 * @returns Returns the initials of the person.
 */
export function getInitials(
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) {
  let initials = '';

  if (firstName && firstName[0]) {
    initials += firstName[0].toUpperCase();
  }

  if (lastName && lastName[0]) {
    initials += lastName[0].toUpperCase();
  }

  return initials;
}
