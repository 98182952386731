import Component from '@glimmer/component';
import UiIcon from './ui-icon.ts';

export type AlertVariant = 'danger' | 'info' | 'success' | 'warning';

export interface UiAlertSignature {
  Element: HTMLDivElement;
  Args: {
    variant: AlertVariant;
  };
  Blocks: {
    default: [];
  };
}

const icon: Record<AlertVariant, string> = {
  danger: 'x-circle',
  info: 'information-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
};

const variantBackgroundClasses: Record<AlertVariant, string> = {
  danger: 'bg-red-50',
  info: 'bg-blue-50',
  success: 'bg-green-50',
  warning: 'bg-yellow-50',
};

const variantIconClasses: Record<AlertVariant, string> = {
  danger: 'text-red-400',
  info: 'text-blue-400',
  success: 'text-green-400',
  warning: 'text-yellow-400',
};

const variantTextClasses: Record<AlertVariant, string> = {
  danger: 'text-red-900',
  info: 'text-blue-900',
  success: 'text-green-900',
  warning: 'text-yellow-900',
};

export default class UiAlertComponent extends Component<UiAlertSignature> {
  get icon() {
    return icon[this.args.variant];
  }

  get variantBackgroundClasses() {
    return variantBackgroundClasses[this.args.variant];
  }

  get variantIconClasses() {
    return variantIconClasses[this.args.variant];
  }

  get variantTextClasses() {
    return variantTextClasses[this.args.variant];
  }

  <template>
    <div
      data-test-id='alert'
      data-test-variant={{@variant}}
      class='rounded-md p-4 {{this.variantBackgroundClasses}}'
      ...attributes
    >
      <div class='flex'>
        <div class='flex-shrink-0'>
          <UiIcon
            @icon={{this.icon}}
            @type='mini'
            class={{this.variantIconClasses}}
          />
        </div>
        <div class='ml-3 text-sm {{this.variantTextClasses}}'>
          {{yield}}
        </div>
      </div>
    </div>
  </template>
}
