import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { TrackedArray } from 'tracked-built-ins';
import TabList from './ui-tab-group/tab-list.gts';
import TabPanels from './ui-tab-group/tab-panels.gts';

export type TabVariation = 'default' | 'none' | 'segmented';
type TabMode = 'controlled' | 'uncontrolled';

export interface UiTabGroupSignature {
  Element: HTMLDivElement;
  Args: {
    defaultIndex?: number;
    fitToParent?: boolean;
    onChange?: (index: number) => unknown;
    selectedIndex?: number;
    variation?: TabVariation;
  };
  Blocks: {
    default: [
      {
        TabList: WithBoundArgs<
          typeof TabList,
          | 'fitToParent'
          | 'onChange'
          | 'onKeyUp'
          | 'onTabRegistered'
          | 'panelIds'
          | 'renderAsListbox'
          | 'selectedIndex'
          | 'selectedTab'
          | 'tabIds'
          | 'variation'
        >;
        TabPanels: WithBoundArgs<
          typeof TabPanels,
          | 'onPanelRegistered'
          | 'panelIds'
          | 'selectedIndex'
          | 'tabIds'
          | 'variation'
        >;
      },
    ];
  };
}

export default class UiTabGroupComponent extends Component<UiTabGroupSignature> {
  /**
   * This is the selected tab when we are in 'uncontrolled' mode. This will be
   * ignored when in 'controlled' mode.
   */
  @tracked _selectedTab: HTMLButtonElement | undefined = undefined;
  @tracked renderAsListbox = false;
  tabs = new TrackedArray<HTMLButtonElement>([]);
  panels = new TrackedArray<HTMLDivElement>([]);

  get variation() {
    return this.args.variation ?? 'default';
  }

  /**
   * Determine whether the tabs are in 'controlled' or 'uncontrolled' mode.
   *
   * If the caller has provided the `selectedIndex` argument, then we are in a
   * controlled mode. This means the caller wants to control the selected tab
   * rather than relying on our internal tab tracking.
   */
  get mode(): TabMode {
    return isPresent(this.args.selectedIndex) ? 'controlled' : 'uncontrolled';
  }

  get isControlled() {
    return this.mode === 'controlled';
  }

  @cached
  get tabIds() {
    return this.tabs.map((tab) => tab.id);
  }

  @cached
  get panelIds() {
    return this.panels.map((panel) => panel.id);
  }

  get selectedIndex() {
    if (this.isControlled) {
      return this.args.selectedIndex;
    }

    return this.selectedTab ? this.indexOfTab(this.selectedTab.id) : undefined;
  }

  get selectedTab() {
    if (this.isControlled) {
      return this.tabForIndex(this.args.selectedIndex);
    }

    if (this._selectedTab !== undefined) {
      return this._selectedTab;
    }

    return this.getDefaultIndexTab();
  }

  get selectedPanel() {
    const selectedTabId = this.selectedTab?.id;

    if (selectedTabId === undefined) {
      return undefined;
    }

    const index = this.indexOfTab(selectedTabId);

    if (index === -1) {
      return undefined;
    }

    return this.panels[index];
  }

  isTabDisabled(tab: HTMLButtonElement | undefined) {
    return tab?.disabled;
  }

  tabById(id: string) {
    return this.tabs.find((tab) => tab.id === id);
  }

  indexOfTab(id: string) {
    return this.tabs.findIndex((tab) => tab.id === id);
  }

  tabForIndex(index: number | undefined) {
    if (index !== undefined && index < this.tabs.length) {
      return this.tabs[index];
    }

    return this.tabs.length > 0 ? this.tabs[0] : undefined;
  }

  getDefaultIndexTab() {
    const index = this.args.defaultIndex ?? 0;

    if (!this.isTabDisabled(this.tabs[index])) {
      return this.tabs[index];
    }

    return this.getNextTab(index);
  }

  getNextTab(index: number) {
    const count = this.tabs.length;

    // Look for non-disabled tab from index to the last tab on the right.
    for (let i = index + 1; i < count; i++) {
      const tab = this.tabs[i];

      if (!this.isTabDisabled(tab)) {
        return tab;
      }
    }

    // If no tab found, continue searching from first on left to index.
    for (let i = 0; i < index; i++) {
      const tab = this.tabs[i];

      if (!this.isTabDisabled(tab)) {
        return tab;
      }
    }

    // No tabs are found. This would only happen if all tabs are disabled.
    return undefined;
  }

  getPrevTab(index: number) {
    let i = index;

    // Look for non-disabled tab from index to first tab on the left.
    while (i--) {
      const tab = this.tabs[i];

      if (!this.isTabDisabled(tab)) {
        return tab;
      }
    }

    // If no tab found, continue searching from last tab on right to index.
    i = this.tabs.length;

    while (i-- > index) {
      const tab = this.tabs[i];

      if (!this.isTabDisabled(tab)) {
        return tab;
      }
    }

    // No tabs are found. This would only happen if all tabs are disabled.
    return undefined;
  }

  @action
  handleTabChange(id: string) {
    // If the user clics on a tab, this action will be called twice; once for
    // the focus event and once for the click event. Because of this, we need to
    // make sure and not call the `onChange` event if the tab is already
    // selected.

    if (this.isControlled) {
      const tabIndex = this.indexOfTab(id);

      // Don't fire the change event if the tab is already selected.
      if (this.args.selectedIndex === tabIndex) {
        return;
      }

      this.args.onChange?.(tabIndex);
    } else {
      // Don't fire the change event if the tab is already selected.
      if (this._selectedTab?.id === id) {
        return;
      }

      this._selectedTab = this.tabById(id);
      this.args.onChange?.(this.indexOfTab(id));
    }
  }

  @action
  tabRegistered(tabElement: HTMLButtonElement) {
    this.tabs.push(tabElement);
  }

  @action
  panelRegistered(panelElement: HTMLDivElement) {
    this.panels.push(panelElement);
  }

  @action
  handleKeyUp(event: KeyboardEvent, id: string) {
    const index = this.indexOfTab(id);

    if (event.key === 'ArrowLeft') {
      this.getPrevTab(index)?.focus();
    } else if (event.key === 'ArrowRight') {
      this.getNextTab(index)?.focus();
    }
  }

  initTabs = modifier((element: HTMLDivElement) => {
    // When we need to render as a listbox, make sure to change `role="tablist"` to `role="listbox"`.
    // Also change `role="tab"` to `role="option"`. Can

    const tablistElement =
      element.querySelector<HTMLDivElement>('[role="tablist"]');
    let breakpoint: number | null = null;

    if ((tablistElement?.offsetWidth as number) > element.offsetWidth) {
      breakpoint = tablistElement?.offsetWidth as number;
      this.renderAsListbox = true;
    }

    const handleResize = () => {
      if (breakpoint === null) {
        if ((tablistElement?.offsetWidth as number) > element.offsetWidth) {
          breakpoint = tablistElement?.offsetWidth as number;
          this.renderAsListbox = true;
        } else {
          this.renderAsListbox = false;
        }
      } else if (breakpoint <= element.offsetWidth) {
        breakpoint = null;
        this.renderAsListbox = false;
      }
    };

    window.addEventListener('resize', handleResize);

    return function onDestroy() {
      window.removeEventListener('resize', handleResize);
    };
  });

  <template>
    <div
      data-test-id='tab-group'
      class='relative'
      ...attributes
      {{this.initTabs}}
    >
      {{yield
        (hash
          TabList=(component
            TabList
            fitToParent=@fitToParent
            onChange=this.handleTabChange
            onKeyUp=this.handleKeyUp
            onTabRegistered=this.tabRegistered
            panelIds=this.panelIds
            renderAsListbox=this.renderAsListbox
            selectedIndex=this.selectedIndex
            selectedTab=this.selectedTab
            tabIds=this.tabIds
            variation=this.variation
          )
          TabPanels=(component
            TabPanels
            onPanelRegistered=this.panelRegistered
            panelIds=this.panelIds
            selectedIndex=this.selectedIndex
            tabIds=this.tabIds
            variation=this.variation
          )
        )
      }}
    </div>
  </template>
}
