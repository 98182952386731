/* import __COLOCATED_TEMPLATE__ from './ui-modal.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

type UiModalSize = 'md' | 'lg' | 'xl';

export interface UiModalSignature {
  Element: HTMLDivElement;
  Args: {
    isOpen?: boolean;
    onClose?: () => unknown;
    size?: UiModalSize;
  };

  Blocks: {
    body: [{ close: () => unknown }];
    footer: [{ close: () => unknown }];
    title: [{ close: () => unknown }];
  };
}

export default class UiModalComponent extends Component<UiModalSignature> {
  // The ember-headlessui modal already uses the `in-element` helper. But
  // because we have a `<Transition />` wrapped around it, we need to also
  // render it in the root element of the application. Otherwise, this can mess
  // up the layout of your application.
  modalRoot = document.getElementById('modal_root');

  get isOpen() {
    return this.args.isOpen ?? false;
  }

  get sizeClass() {
    switch (this.args.size) {
      case 'lg':
        return 'sm:max-w-xl';
      case 'xl':
        return 'sm:max-w-2xl';
      case 'md':
      default:
        return 'sm:max-w-lg';
    }
  }

  @action
  onClose() {
    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UiModal: typeof UiModalComponent;
  }
}
