import { template as template_e12217af89c84772be871b0e3b1cdba0 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { eq, or } from 'ember-truth-helpers';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import FinancialResponsibilitiesListbox from './financial-responsibilities-listbox';
import UiStripeCard from '../../../ui-stripe-card';
import UiSubfield from '../../../ui-subfield';
import type AccountService from '../../../../services/account';
import type FinancialResponsibilityModel from '../../../../models/financial-responsibility';
import PayerContactDetails, { type PayerContact } from './payer-contact-details';
import { type PaymentDetails, type PaymentFormDetails, type PaymentOption } from '../payment';
import type PaymentMethodModel from '../../../../models/payment-method';
import type RideCostEstimateModel from '../../../../models/ride-cost-estimate';
import type RiderModel from '../../../../models/rider';
import type SessionService from '../../../../services/session';
interface PaymentFormSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        isInvoiced: boolean;
        onFinancialResponsibilityChange: (value: FinancialResponsibilityModel) => void;
        onPayerContactChange: (payerContact: PayerContact) => void;
        onPaymentOptionChange: (value: PaymentOption) => void;
        onStripeCardAdded: () => void;
        paymentDetails: PaymentDetails;
        paymentFormDetails: PaymentFormDetails;
        paymentOptions: PaymentOption[];
        rideCostEstimate: RideCostEstimateModel | null;
        rider: RiderModel | null;
        riderPaymentMethods: PaymentMethodModel[];
        selectedPaymentOption: PaymentOption | null;
    };
}
export default class PaymentFormComponent extends Component<PaymentFormSignature> {
    @service
    account: AccountService;
    @service
    session: SessionService;
    @tracked
    _showStripeForm = false;
    get selectedFinancialResponsibility() {
        const { paymentDetails } = this.args;
        const records = this.account.financialResponsibilities;
        return (records.find((record)=>record.displayName === paymentDetails.payerType && record.costCenterName === paymentDetails.costCenter) ?? null);
    }
    get isPrivatePay() {
        return this.selectedFinancialResponsibility?.name === 'Private';
    }
    get showPaymentOptions() {
        const hasFinancialResponsibilities = this.account.financialResponsibilities.length > 0;
        const hasSelectedFinancialResponsibility = this.selectedFinancialResponsibility !== null;
        const isInvoiced = this.args.paymentFormDetails.availablePaymentMethods[0] && this.args.paymentFormDetails.availablePaymentMethods[0].id === 'invoice';
        return ((hasFinancialResponsibilities && hasSelectedFinancialResponsibility && isInvoiced !== true) || hasFinancialResponsibilities === false);
    }
    get showPaymentOptionSubform() {
        return (this.args.selectedPaymentOption && (this.args.selectedPaymentOption.value === 'new' || this._showStripeForm || this.args.selectedPaymentOption.value === 'collect'));
    }
    @action
    handleFinancialResponsibilityChange(value: FinancialResponsibilityModel) {
        this.args.onFinancialResponsibilityChange(value);
    }
    @action
    handlePaymentChange(value: PaymentOption) {
        this._showStripeForm = false;
        this.args.onPaymentOptionChange(value);
    }
    @action
    async stripeCardAdded() {
        this._showStripeForm = true;
        this.args.onStripeCardAdded();
    }
    static{
        template_e12217af89c84772be871b0e3b1cdba0(`
    <div data-test-id='payment-form' class='flex flex-col gap-6' ...attributes>
      <FinancialResponsibilitiesListbox
        @financialResponsibilities={{this.account.financialResponsibilities}}
        @selectedFinancialResponsibility={{this.selectedFinancialResponsibility}}
        @formGroup={{@formGroup}}
        @onChange={{this.handleFinancialResponsibilityChange}}
      />

      {{#if this.showPaymentOptions}}
        <@formGroup data-test-id='payment-options' as |Group|>
          <Group.Label>Payment Options</Group.Label>
          <Group.Listbox
            @value={{@selectedPaymentOption}}
            @onChange={{this.handlePaymentChange}}
            as |listbox|
          >
            <listbox.Button>
              <:placeholder>
                <span class='flex items-center gap-2'>
                  <UiIcon @icon='credit-card' @type='mini' />
                  <span>Select a payment option...</span>
                </span>
              </:placeholder>

              <:default>
                {{#if @selectedPaymentOption}}
                  <PaymentItem @option={{@selectedPaymentOption}} />
                {{/if}}
              </:default>
            </listbox.Button>

            <listbox.Options as |options|>
              {{#each @paymentOptions as |paymentOption|}}
                <options.Option @value={{paymentOption}} as |option|>
                  <PaymentItem
                    @isActive={{option.active}}
                    @option={{paymentOption}}
                  />
                </options.Option>
              {{/each}}
            </listbox.Options>
          </Group.Listbox>
        </@formGroup>
      {{/if}}

      {{#if this.showPaymentOptionSubform}}
        <UiSubfield>
          {{#if
            (or (eq @selectedPaymentOption.value 'new') this._showStripeForm)
          }}
            <UiStripeCard
              @ownerType={{@paymentFormDetails.currentPayer.type}}
              @ownerId={{@paymentFormDetails.currentPayer.id}}
              @onSubmit={{this.stripeCardAdded}}
            />
          {{else if (eq @selectedPaymentOption.value 'collect')}}
            <PayerContactDetails
              @formGroup={{@formGroup}}
              @onChange={{@onPayerContactChange}}
              @payerContact={{@paymentDetails.payerContact}}
              @riderId={{@rider.id}}
            />
          {{/if}}
        </UiSubfield>
      {{/if}}

      <UiSubfield>
        {{#if (or @rideCostEstimate @isInvoiced)}}
          <div
            class='flex flex-col gap-2 text-base font-semibold text-purple-500'
          >
            {{#if @isInvoiced}}
              <div>
                <span data-test-id='invoiced'>To Be Invoiced</span>
              </div>
            {{/if}}

            {{#if @rideCostEstimate}}
              <div class='flex justify-between'>
                <span>Fare Estimate:</span>
                <span data-test-id='ride-cost-estimate'>
                  {{@rideCostEstimate.estimatedCostFormatted}}
                </span>
              </div>
            {{/if}}
          </div>
        {{/if}}
      </UiSubfield>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const PaymentItem: TOC<{
    Args: {
        isActive?: boolean;
        option: PaymentOption | null;
    };
}> = template_e12217af89c84772be871b0e3b1cdba0(`
  {{#if (eq @option.value 'new')}}
    <span
      class='flex items-center gap-4
        {{if @isActive "text-white" "text-purple-500"}}'
    >
      <UiIcon @icon='plus' @type='mini' />

      <span class='inline-flex flex-col'>
        <span class='font-semibold sm:text-sm/6'>
          {{@option.label}}
        </span>
      </span>
    </span>
  {{else}}
    <span class='flex items-center gap-4'>
      <UiIcon
        @icon={{if
          (eq @option.value 'collect')
          'document-arrow-up'
          'credit-card'
        }}
        @type='mini'
      />

      <span class='inline-flex flex-col'>
        <span class='font-semibold sm:text-sm/6'>
          {{@option.label}}
        </span>
      </span>
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
