import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type { WithBoundArgs } from '@glint/template';
import { on } from '@ember/modifier';
import { hash } from '@ember/helper';
import UiFormActions from './ui-form/actions.gts';
import UiFormGroup from './ui-form/group.gts';

export type FormSpacing = 'none' | 'default';

export interface UiFormSignature {
  Element: HTMLFormElement;
  Args: {
    noStyles?: boolean;
    onSubmit?: (event: SubmitEvent) => void;
    spacing?: FormSpacing;
  };
  Blocks: {
    default: [
      {
        Actions: WithBoundArgs<typeof UiFormActions, 'isRunning'>;
        Group: typeof UiFormGroup;
      },
    ];
  };
}

const baseClasses = 'flex flex-col';
const spacingClasses: Record<FormSpacing, string> = {
  none: '',
  default: 'space-y-6',
};

export default class UiFormComponent extends Component<UiFormSignature> {
  get spacing() {
    return this.args.spacing ?? 'default';
  }

  get spacingClasses(): string {
    return spacingClasses[this.spacing];
  }

  get classes() {
    if (this.args.noStyles) {
      return '';
    }

    return `${baseClasses} ${this.spacingClasses}`;
  }

  submitTask = task(async (event: SubmitEvent) => {
    event.preventDefault();

    if (this.args.onSubmit) {
      // Wrap this in a Promise because it might just be a regular function and
      // you shouldn't await regular functions.
      await Promise.resolve(this.args.onSubmit(event));
    }
  });

  <template>
    <form
      data-test-id='form'
      class={{this.classes}}
      ...attributes
      {{on 'submit' this.submitTask.perform}}
    >
      {{yield
        (hash
          Actions=(component UiFormActions isRunning=this.submitTask.isRunning)
          Group=UiFormGroup
        )
      }}
    </form>
  </template>
}
