import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type SessionService from './session';
import type {
  DateGrouping,
  DateGroupingOption,
  ReportingDateRangeOption,
} from '../utils/filters/date-range';
import { dateRanges } from '../components/reporting/reporting-date-range-filter';
import type { Filters } from '../components/reporting/filters';
import type { ChartParams } from '../utils/chart';
import type { AccountType } from '../components/filters/account-filter';

export default class ReportingFiltersService extends Service {
  @service declare session: SessionService;

  @tracked accounts: string[] = [];
  @tracked accountType: AccountType | null = null;
  @tracked dateGrouping: DateGrouping = this.defaultDateGrouping;
  @tracked datePeriod = this.defaultDatePeriod;
  @tracked financialResponsibilities: string[] = [];
  @tracked geo: string | null = null;
  @tracked regions: string[] = [];
  @tracked rideStatuses: string[] = [];
  @tracked transportPartners: string[] = [];
  @tracked transportTypes: string[] = [];
  @tracked zipcodes: string[] = [];

  get dateGroupingDisplay() {
    return this.dateRange?.dateGroupingOptions?.find(
      (option: DateGroupingOption) => option.id === this.dateGrouping,
    )?.label;
  }

  get dateRange() {
    return dateRanges.find(
      (range: ReportingDateRangeOption) => range.id === this.datePeriod,
    );
  }

  get defaultDateGrouping() {
    return 'week' as DateGrouping;
  }

  get defaultDatePeriod() {
    // We will for sure get a date range since we have it specified in the file.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return dateRanges.find(
      (range: ReportingDateRangeOption) => range.id === 'last-4-full-weeks',
    )!.id;
  }

  get filters(): Filters {
    if (!this.dateRange) {
      throw new Error(`Date range of '${this.datePeriod}' is not defined`);
    }

    return {
      accountIds: this.accounts,
      accountType: this.accountType,
      dateRange: {
        datePeriod: this.dateRange.id,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        dateGrouping: this.dateGrouping,
      },
      financialResponsibilities: this.financialResponsibilities,
      geoId: this.geo,
      regionIds: this.regions,
      rideCompletionStatuses: this.rideStatuses,
      transportPartnerIds: this.transportPartners,
      transportTypeIds: this.transportTypes,
      zipcodeIds: this.zipcodes,
    };
  }

  get chartParams() {
    const accountIds: string[] =
      this.filters.accountType === 'selected' ||
      this.session.isOnwardAssociate === false
        ? this.filters.accountIds
        : [];
    const channel =
      this.filters.accountType === 'b2b' || this.filters.accountType === 'b2c'
        ? this.filters.accountType
        : null;
    let parentAccountId: string | null = null;

    if (this.filters.accountType === 'parent') {
      parentAccountId = this.filters.accountIds[0]
        ? this.filters.accountIds[0]
        : null;
    }

    const params: ChartParams = {
      accountIds,
      channel,
      dateGrouping: this.filters.dateRange.dateGrouping,
      endDate: this.filters.dateRange.endDate,
      payerTypes: this.filters.financialResponsibilities,
      geoId: this.filters.geoId,
      parentAccountId,
      regionIds: this.filters.regionIds,
      rideCompletionStatuses: this.filters.rideCompletionStatuses,
      startDate: this.filters.dateRange.startDate,
      transportPartnerIds: this.filters.transportPartnerIds,
      transportTypeIds: this.filters.transportTypeIds,
      zipcodeIds: this.filters.zipcodeIds,
    };

    return params;
  }

  @action
  updateFilters(filters: Filters) {
    this.accounts = filters.accountIds;
    this.accountType = filters.accountType;
    this.dateGrouping = filters.dateRange.dateGrouping;
    this.datePeriod = filters.dateRange.datePeriod;
    this.financialResponsibilities = filters.financialResponsibilities;
    this.geo = filters.geoId;
    this.regions = filters.regionIds;
    this.rideStatuses = filters.rideCompletionStatuses;
    this.transportPartners = filters.transportPartnerIds;
    this.transportTypes = filters.transportTypeIds;
    this.zipcodes = filters.zipcodeIds;
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('reporting-filters')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('reporting-filters') declare altName: ReportingFiltersService;`.
declare module '@ember/service' {
  interface Registry {
    'reporting-filters': ReportingFiltersService;
  }
}
