import { template as template_d7106555a7a948c78371f3c33a812518 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type SessionService from '../../services/session';
import type { EmptyObject } from '@ember/component/helper';
export interface AccountMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class AccountMenuComponent extends Component<AccountMenuSignature> {
    @service
    session: SessionService;
    static{
        template_d7106555a7a948c78371f3c33a812518(`
    <div data-test-id='account-context'>
      <span class='text-sm leading-6 text-gray-400'>
        {{this.session.user.accountName}}
      </span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::AccountMenu': typeof AccountMenuComponent;
    }
}
