import type { TOC } from '@ember/component/template-only';
import { type UiHeadlessListboxOptionsSignature } from '../ui-headless-listbox/-options.gts';

export interface UiListboxEmptySignature {
  Element: HTMLElement;
  Args: {
    optionsBlock: UiHeadlessListboxOptionsSignature['Blocks']['default'][0];
  };
  Blocks: {
    default: [];
  };
}

const UiListboxEmptyComponent: TOC<UiListboxEmptySignature> = <template>
  <@optionsBlock.Option
    data-test-id='listbox-empty'
    @disabled={{true}}
    @value={{null}}
    class='outline-none'
    ...attributes
  >
    <span
      class='relative block cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
    >
      <span class='block truncate'>
        {{yield}}
      </span>
    </span>
  </@optionsBlock.Option>
</template>;

export default UiListboxEmptyComponent;
