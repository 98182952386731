import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';
import type * as Highcharts from 'highcharts';
import type { ChartDateCategory, ColumnSeries, CustomData } from '../types.ts';
import { chartSettings } from '../utils/chart-settings.ts';
import initChart from '../modifiers/init-chart.ts';

export type ColumnFormat = 'currency' | 'number' | 'percent';
export type ColumnStacking = 'normal' | 'percent';

export interface UiColumnChartArgs {
  data: ColumnSeries[];
  categories: ChartDateCategory[] | string[];
  format?: ColumnFormat;
  stacking?: ColumnStacking;
  xAxisFormatter?: (
    context: Highcharts.AxisLabelsFormatterContextObject,
  ) => string;
  xAxisTitle?: Highcharts.XAxisTitleOptions;
}

export interface UiColumnChartSignature {
  Element: HTMLDivElement;
  Args: UiColumnChartArgs;
}

function extractCustomData(component: UiColumnChart, series: ColumnSeries) {
  return series.data.map((dataPoint) => {
    // TODO: Look at how to do this for stacked when all series are missing the
    //       same data point.
    // Only do this if the chart isn't stacked.
    if (dataPoint.y === null && component.args.stacking === undefined) {
      return {
        // You must set the y value to 0 because `null` will not render.
        y: 0,
        custom: { noValue: true },
      };
    }

    if (dataPoint.count !== undefined) {
      return {
        y: dataPoint.y,
        custom: { count: dataPoint.count },
      };
    }

    return dataPoint;
  });
}

export default class UiColumnChart extends Component<UiColumnChartSignature> {
  get animationEnabled(): boolean {
    return macroCondition(isTesting()) ? false : true;
  }

  get chartSeries(): Highcharts.SeriesColumnOptions[] {
    if (this.args.data) {
      return this.args.data.map((series) => {
        const newSeries = {
          ...series,
          type: 'column',
        };

        if (series.data) {
          newSeries.data = extractCustomData(this, series);
        }

        return newSeries;
      }) as Highcharts.SeriesColumnOptions[];
    }

    return [];
  }

  get xAxisFormatter() {
    if (this.args.xAxisFormatter) {
      return this.args.xAxisFormatter;
    }

    return function formatter(
      context: Highcharts.AxisLabelsFormatterContextObject,
    ): string {
      return `<span style="font-size: ${chartSettings.axisTextSize}; color: ${chartSettings.axisTextColor}">${context.value}</span>`;
    };
  }

  get categories() {
    return this.args.categories;
  }

  get categoryLabels() {
    return this.categories.map((category) => {
      if (typeof category === 'string' || typeof category === 'number') {
        return category;
      }

      return category.label;
    });
  }

  get format() {
    return this.args.format ?? 'number';
  }
  get xAxisTitle() {
    return this.args.xAxisTitle ? this.args.xAxisTitle : undefined;
  }

  get yAxisFormat() {
    // If we need to not show decimal places, use the following format:
    // format: '{value:,.0f}',
    // This format uses a comma as thousands separator and allows normal
    // decimal places

    if (this.args.stacking === 'percent' || this.args.format === 'percent') {
      return '{value:,f}%';
    }

    if (this.args.format === 'currency') {
      return '${value:,f}';
    }

    return '{value:,f}';
  }

  get yAxisStackLabelFormat() {
    if (this.args.format === 'currency') {
      return '${total:,f}';
    }

    return '{total:,f}';
  }

  get chartOptions(): Highcharts.Options {
    return {
      credits: {
        // Gets rid of the "highcharts.com" watermark.
        enabled: false,
      },

      colors: chartSettings.seriesColors,

      chart: {
        type: 'column',
        animation: this.animationEnabled,
        backgroundColor: 'transparent',
        spacingTop: 20,
      },

      plotOptions: {
        column: {
          stacking: this.args.stacking,
          dataLabels: {
            enabled: true,
            formatter(this: Highcharts.PointLabelObject): string | undefined {
              if (this.point.options.custom !== undefined) {
                const { count, noValue } = this.point.options
                  .custom as CustomData;

                if (noValue === true) {
                  return 'N/A';
                }

                if (count !== undefined) {
                  return count.toLocaleString();
                }
              }
            },
            style: {
              color: chartSettings.dataLabelColor,
              fontSize: chartSettings.dataLabelSize,
              fontWeight: 'normal',
            },
            // These `crop` and `overflow` values force the label to display
            // above the bar instead of going inside it.
            crop: false,
            overflow: 'allow',
          },
        },
        series: {
          animation: this.animationEnabled,
        },
      },

      series: this.chartSeries,

      title: {
        // To get rid of the default title, need to set `text` to `undefined`.
        text: undefined,
      },

      tooltip: {
        formatter: (tooltip: Highcharts.Tooltip) => {
          const hoveredPoint = tooltip.chart.hoverPoint;

          if (hoveredPoint) {
            const { category, options, percentage, series, y } = hoveredPoint;
            const color =
              typeof hoveredPoint.color === 'string' ? hoveredPoint.color : '';
            let yDisplay = '';

            // - If `noValue` is true, then we show "N/A".
            // - If format is `percent`, then we show the percent, then the `count` in parenthesis.
            // - If format is `currency`, then we show the dollar amount, then the `count` in parenthesis.
            // - If `stacking` is "percent", then we show the percent, then the `y` in parenthesis.
            // - Else, just show `y`.
            if (
              y === undefined ||
              (options.custom && options.custom['noValue'] === true)
            ) {
              yDisplay = 'N/A';
            } else if (this.args.format === 'percent') {
              const countDisplay =
                options.custom && options.custom['count']
                  ? ` (${options.custom['count'].toLocaleString()})`
                  : '';
              yDisplay = `${y}%${countDisplay}`;
            } else if (this.args.format === 'currency') {
              const countDisplay =
                options.custom && options.custom['count']
                  ? ` (${options.custom['count'].toLocaleString()})`
                  : '';
              yDisplay = `$${y.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}${countDisplay}`;
            } else if (this.args.stacking === 'percent') {
              const percentString =
                percentage === 100 ? '100' : percentage?.toPrecision(2);
              yDisplay = `${percentString}% (${y.toLocaleString()})`;
            } else {
              yDisplay = y.toLocaleString();
            }

            return `
              <span class="text-xs">${category}</span>
              <br><span><span style="color: ${color};">●</span> ${series.name}: <b>${yDisplay}</b></span>
            `;
          }

          return undefined;
        },
      },

      xAxis: {
        categories: this.categoryLabels,
        labels: {
          // This needs to use an arrow function so that the `this` context is
          // this component and not the Highcharts context.
          formatter: (context: Highcharts.AxisLabelsFormatterContextObject) => {
            return this.xAxisFormatter(context);
          },
        },
        lineColor: chartSettings.axisLineColor,
        title: this.xAxisTitle,
      },

      yAxis: {
        ceiling: this.args.format === 'percent' ? 100 : undefined,
        floor: this.args.format === 'percent' ? 0 : undefined,
        tickAmount:
          this.args.format === 'percent' || this.args.stacking === 'percent'
            ? 5
            : undefined,
        tickPositions:
          this.args.format === 'percent' ? [0, 25, 50, 75, 100] : undefined,
        gridLineColor: chartSettings.gridLineColor,
        gridLineDashStyle: 'ShortDash',
        labels: {
          format: this.yAxisFormat,
          style: {
            color: chartSettings.axisTextColor,
            fontSize: chartSettings.axisTextSize,
          },
        },
        stackLabels: {
          // Shows the total value of the stacked bars.
          enabled: true,
          format: this.yAxisStackLabelFormat,
          verticalAlign: 'top',
          style: {
            color: chartSettings.dataLabelColor,
            fontSize: chartSettings.dataLabelSize,
            fontWeight: 'normal',
          },
          // These `crop` and `overflow` values force the label to display above
          // the bar instead of going inside it.
          crop: false,
          overflow: 'allow',
        },
        // To get rid of the default title, need to set `text` to `undefined`.
        title: undefined,
      },
    };
  }

  <template>
    <div
      data-test-id='column-chart'
      class='w-full'
      ...attributes
      {{initChart this.chartOptions}}
    ></div>
  </template>
}
