import type RouterService from '@ember/routing/router-service';
import type { RouteModel } from '@ember/routing/router-service';

/**
 * Gets the route models to use for the router service. If a single model is
 * provided, then a single model array is returned. If an array of models is
 * provided, then that array is returned. If neither is provided, then an empty
 * array is returned.
 *
 * @param model (Optional) A single route model.
 * @param models (Optional) An array of route models.
 * @returns Returns an array of route models or an empty array.
 */
function getModels(model?: RouteModel, models?: RouteModel[]) {
  if (model) {
    return [model];
  } else if (models) {
    return models;
  }

  return [];
}

/**
 * Checks to see if the route is the active route.
 *
 * @param router The router service.
 * @param route (Optional) The route to check if it is active.
 * @param model (Optional) A single route model.
 * @param models (Optional) An array of route models.
 * @returns Returns true if the route is active; otherwise, false.
 */
export function isActiveRoute(
  router: RouterService,
  route?: string,
  model?: RouteModel,
  models?: [RouteModel],
) {
  if (route) {
    const modelsParam = getModels(model, models);

    return modelsParam.length > 0
      ? router.isActive(route, modelsParam)
      : router.isActive(route);
  }

  return false;
}
