import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import type { ValidationError } from './ui-validation-errors.gts';

type State = 'default' | 'disabled' | 'error';

export interface UiTextAreaSignature {
  Element: HTMLTextAreaElement;
  Args: {
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    value?: string | null;
  };
}

// We are still using `ring` instead of `outline` here because that's what @tailwindcss/forms uses.
const baseClasses =
  'form-textarea transition block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6';
const stateClasses: Record<State, string> = {
  default: 'bg-white text-gray-900 ring-gray-300 focus:ring-purple-500',
  disabled: 'bg-gray-200 text-gray-600 ring-gray-300 cursor-not-allowed',
  error: 'bg-white text-gray-900 ring-red-400 focus:ring-red-500',
};

export default class UiTextAreaComponent extends Component<UiTextAreaSignature> {
  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  @action
  handleChange(eventType: 'blur' | 'input', event: Event) {
    const value = (event.target as HTMLTextAreaElement).value;

    if (eventType === 'blur') {
      this.args.onBlur?.(value);
    } else if (eventType === 'input') {
      this.args.onChange?.(value);
    }
  }

  <template>
    <textarea
      data-test-id='textarea'
      id={{@id}}
      class='{{this.baseClasses}} {{this.stateClasses}}'
      value={{if @value @value ''}}
      disabled={{@disabled}}
      aria-invalid={{this.hasErrors}}
      {{on 'blur' (fn this.handleChange 'blur')}}
      {{on 'input' (fn this.handleChange 'input')}}
      ...attributes
    >
    </textarea>
  </template>
}
