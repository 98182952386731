import { template as template_d6ac9f04627747c390f05cac5689eeb7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { hash } from '@ember/helper';
import { restartableTask } from 'ember-concurrency';
import { modifier } from 'ember-modifier';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import type AccountService from '../../../services/account';
import type FinancialResponsibilityModel from '../../../models/financial-responsibility';
import { PayerContact } from './payment/payer-contact-details';
import type PaymentMethodModel from '../../../models/payment-method';
import type RideCostEstimateModel from '../../../models/ride-cost-estimate';
import type RiderModel from '../../../models/rider';
import type SessionService from '../../../services/session';
import type { WithBoundArgs } from '@glint/template';
import PaymentForm from './payment/payment-form';
import PaymentSummary from './payment/payment-summary';
export class PaymentDetails {
    @tracked
    costCenter: string | null = null;
    @tracked
    payerContact = new PayerContact();
    @tracked
    payerType: string | null = null;
    @tracked
    paymentMethod: string | null = null;
    constructor(initialState?: PaymentDetails){
        if (initialState) {
            this.costCenter = initialState.costCenter;
            this.payerContact = initialState.payerContact;
            this.payerType = initialState.payerType;
            this.paymentMethod = initialState.paymentMethod;
        }
    }
    reset() {
        this.costCenter = null;
        this.payerContact = new PayerContact();
        this.payerType = null;
        this.paymentMethod = null;
    }
}
export interface PaymentFormDetails {
    availablePaymentMethods: PaymentMethodModel[];
    currentPayer: {
        id: string;
        type: string;
    };
}
export interface PaymentOption {
    label: string;
    value: string;
}
function getNewestPaymentOption(paymentMethods: PaymentMethodModel[], paymentOptions: PaymentOption[]) {
    // Sort the payment methods in descending order by created date.
    const sortedPaymentMethods = [
        ...paymentMethods
    ].sort((a, b)=>b.createdAt.localeCompare(a.createdAt));
    const newestPaymentMethod = sortedPaymentMethods[0] ?? null;
    return (paymentOptions.find((option)=>option.value === newestPaymentMethod?.id) ?? null);
}
interface PaymentSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        onChange: (paymentDetails: PaymentDetails) => void;
        paymentDetails: PaymentDetails;
        rideCostEstimate: RideCostEstimateModel | null;
        rider: RiderModel | null;
    };
    Blocks: {
        default: [{
                Summary: WithBoundArgs<typeof PaymentSummary, 'isInvoiced' | 'paymentDetails' | 'selectedPaymentOption'>;
                Form: WithBoundArgs<typeof PaymentForm, 'formGroup' | 'isInvoiced' | 'onFinancialResponsibilityChange' | 'onPayerContactChange' | 'onPaymentOptionChange' | 'onStripeCardAdded' | 'paymentDetails' | 'paymentFormDetails' | 'paymentOptions' | 'rideCostEstimate' | 'riderPaymentMethods' | 'rider' | 'selectedPaymentOption'>;
            }];
    };
}
export default class PaymentComponent extends Component<PaymentSignature> {
    @service
    account: AccountService;
    @service
    session: SessionService;
    @service
    store: Store;
    @tracked
    riderPaymentMethods: PaymentMethodModel[] = [];
    get selectedFinancialResponsibility() {
        const { paymentDetails } = this.args;
        const records = this.account.financialResponsibilities;
        return (records.find((record)=>record.displayName === paymentDetails?.payerType && record.costCenterName === paymentDetails?.costCenter) ?? null);
    }
    get accountChannel() {
        // The `account` should always exist here, but using optional value in case.
        return this.session.account?.channel ?? 'b2c';
    }
    get isPrivatePay() {
        return this.selectedFinancialResponsibility?.name === 'Private';
    }
    get paymentFormDetails(): PaymentFormDetails {
        if (this.accountChannel === 'b2c') {
            return {
                availablePaymentMethods: this.account.paymentMethods,
                currentPayer: {
                    id: this.session.account?.id ?? '',
                    type: 'Account'
                }
            };
        } else {
            // B2B or B2B2C
            if (this.isPrivatePay) {
                return {
                    availablePaymentMethods: this.riderPaymentMethods,
                    currentPayer: {
                        id: this.args.rider?.id ?? '',
                        type: 'Rider'
                    }
                };
            } else {
                return {
                    availablePaymentMethods: this.session.account?.canBeInvoiced ? [
                        {
                            id: 'invoice',
                            name: 'Invoice this ride'
                        } as PaymentMethodModel
                    ] : this.account.paymentMethods,
                    currentPayer: {
                        id: this.session.account?.id ?? '',
                        type: 'Account'
                    }
                };
            }
        }
    }
    get requirePayment() {
        return (this.selectedFinancialResponsibility?.requirePaymentFromPrivatePayerOnBooking ?? true);
    }
    get paymentOptions() {
        const paymentMethods = this.paymentFormDetails.availablePaymentMethods;
        let options: PaymentOption[] = paymentMethods.map((paymentMethod)=>({
                label: paymentMethod.name,
                value: paymentMethod.id
            }));
        if (options[0] === undefined || (options[0] && options[0].value !== 'invoice')) {
            options = [
                ...options,
                {
                    label: 'Add New Card',
                    value: 'new'
                }
            ];
        }
        if (this.requirePayment === false && this.isPrivatePay) {
            options = [
                ...options,
                {
                    label: 'Collect Payment',
                    value: 'collect'
                }
            ];
        }
        return options;
    }
    get selectedPaymentOption() {
        return (this.paymentOptions.find((record)=>record.value === this.args.paymentDetails?.paymentMethod) ?? null);
    }
    get isInvoiced() {
        if (this.args.paymentDetails?.payerType && !this.isPrivatePay && this.session.account?.canBeInvoiced) {
            return true;
        }
        return false;
    }
    fetchRiderPaymentMethods = restartableTask(async ()=>{
        if (this.args.rider === null) {
            this.riderPaymentMethods = [];
            return;
        }
        const paymentMethods = await this.store.query('payment-method', {
            filter: {
                owner_id: this.args.rider.id,
                owner_type: 'Rider'
            }
        });
        this.riderPaymentMethods = paymentMethods.slice();
    });
    initForm = modifier((// eslint-disable-next-line @typescript-eslint/no-unused-vars
    _element: HTMLDivElement, // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [_riderId]: [_riderId: string | undefined])=>{
        this.fetchRiderPaymentMethods.perform();
    });
    @action
    handleFinancialResponsibilityChange(value: FinancialResponsibilityModel) {
        const paymentDetails = new PaymentDetails(this.args.paymentDetails);
        paymentDetails.payerType = value.displayName;
        paymentDetails.costCenter = value.costCenterName;
        paymentDetails.payerContact.reset();
        paymentDetails.paymentMethod = null;
        this.args.onChange(paymentDetails);
    }
    @action
    handlePaymentOptionChange(paymentOption: PaymentOption) {
        const paymentDetails = new PaymentDetails(this.args.paymentDetails);
        paymentDetails.paymentMethod = paymentOption.value;
        paymentDetails.payerContact.reset();
        this.args.onChange(paymentDetails);
    }
    @action
    handlePayerContactChange(payerContact: PayerContact) {
        const paymentDetails = new PaymentDetails(this.args.paymentDetails);
        paymentDetails.payerContact = payerContact;
        this.args.onChange(paymentDetails);
    }
    @action
    async stripeCardAdded() {
        let newestPaymentOption: PaymentOption | null = null;
        if (this.isPrivatePay) {
            await this.fetchRiderPaymentMethods.perform();
            newestPaymentOption = getNewestPaymentOption(this.riderPaymentMethods, this.paymentOptions);
        } else {
            await this.account.reloadPaymentMethods.perform();
            newestPaymentOption = getNewestPaymentOption(this.account.paymentMethods, this.paymentOptions);
        }
        if (newestPaymentOption) {
            // Select the most recent payment method.
            const paymentDetails = new PaymentDetails(this.args.paymentDetails);
            paymentDetails.paymentMethod = newestPaymentOption.value;
            this.args.onChange(paymentDetails);
        }
    }
    static{
        template_d6ac9f04627747c390f05cac5689eeb7(`
    <div {{this.initForm @rider.id}}>
      {{yield
        (hash
          Form=(component
            PaymentForm
            formGroup=@formGroup
            isInvoiced=this.isInvoiced
            onFinancialResponsibilityChange=this.handleFinancialResponsibilityChange
            onPayerContactChange=this.handlePayerContactChange
            onPaymentOptionChange=this.handlePaymentOptionChange
            onStripeCardAdded=this.stripeCardAdded
            paymentDetails=@paymentDetails
            paymentFormDetails=this.paymentFormDetails
            paymentOptions=this.paymentOptions
            rideCostEstimate=@rideCostEstimate
            riderPaymentMethods=this.riderPaymentMethods
            rider=@rider
            selectedPaymentOption=this.selectedPaymentOption
          )
          Summary=(component
            PaymentSummary
            isInvoiced=this.isInvoiced
            paymentDetails=@paymentDetails
            selectedPaymentOption=this.selectedPaymentOption
          )
        )
      }}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::Payment': typeof PaymentComponent;
    }
}
