import Component from '@glimmer/component';
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import UiIcon from '../ui-icon.ts';

export interface AccordionTriggerSignature {
  Element: HTMLButtonElement;
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends AccordionTriggerSignature {
  Args: {
    id: string;
    isExpanded: boolean;
    onChange: (value: string) => void;
    value: string;
  };
}

export default class AccordionTrigger extends Component<InternalSignature> {
  @action
  handleChange() {
    this.args.onChange(this.args.value);
  }

  <template>
    <button
      type='button'
      class='flex w-full items-center justify-between rounded-lg p-4 font-bold text-gray-900 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-purple-500'
      aria-expanded={{@isExpanded}}
      aria-controls={{concat @id '-' @value}}
      id={{@id}}
      data-test-id='accordion-trigger'
      data-expanded={{if @isExpanded 'true' 'false'}}
      {{on 'click' this.handleChange}}
      ...attributes
    >
      <span class='grow truncate'>
        {{yield}}
      </span>

      <UiIcon
        @icon='chevron-down'
        @type='solid'
        class='h-4 w-4 shrink-0 text-gray-900 transition sm:h-5 sm:w-5
          {{if @isExpanded "-rotate-180"}}'
      />
    </button>
  </template>
}
