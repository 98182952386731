import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { action } from '@ember/object';
import { hash } from '@ember/helper';
import UiHeadlessListbox from './ui-headless-listbox.gts';
import type { ValidationError } from './ui-validation-errors';
import ListboxButton from './ui-listbox/button.gts';
import ListboxOptions from './ui-listbox/options.gts';

export interface UiListboxSignature {
  Element: HTMLDivElement;
  Args: {
    allowClear?: boolean;
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    labelId?: string;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<
          typeof ListboxButton,
          | 'disabled'
          | 'hasErrors'
          | 'id'
          | 'listboxComponent'
          | 'onClear'
          | 'showClear'
          | 'value'
        >;
        Options: WithBoundArgs<typeof ListboxOptions, 'listboxComponent'>;
      },
    ];
  };
}

export default class UiListboxComponent extends Component<UiListboxSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get showClear() {
    if (this.args.disabled) {
      return false;
    }

    if (this.args.multiple) {
      if (Array.isArray(this.args.value)) {
        return this.args.value.length > 0;
      }

      return this.args.value !== null;
    } else {
      return this.args.allowClear && this.args.value !== null;
    }
  }

  @action
  handleClear(event: Event) {
    // This prevents event propagation so that it doesn't open up the listbox
    // options when the clear button is clicked.
    event.stopPropagation();
    this.args.onChange?.(this.args.multiple ? [] : null);
  }

  <template>
    <UiHeadlessListbox
      @disabled={{@disabled}}
      @labelId={{@labelId}}
      @multiple={{@multiple}}
      @onChange={{@onChange}}
      @value={{@value}}
      as |listbox|
    >
      <div data-test-id='listbox' class='relative' ...attributes>
        {{yield
          (hash
            Button=(component
              ListboxButton
              disabled=@disabled
              hasErrors=this.hasErrors
              id=@id
              listboxComponent=listbox
              onClear=this.handleClear
              showClear=this.showClear
              value=@value
            )
            Options=(component ListboxOptions listboxComponent=listbox)
          )
        }}
      </div>
    </UiHeadlessListbox>
  </template>
}
