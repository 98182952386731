import { template as template_00e8007f07354bbb8985b589dbe59a9a } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
const UiSubfield: TOC<{
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}> = template_00e8007f07354bbb8985b589dbe59a9a(`
  <div class='flex gap-3' ...attributes>
    <div
      class='ml-3 w-[6px] min-w-[6px] max-w-[6px] flex-1 shrink-0 rounded-b-full rounded-t-full bg-purple-200'
    ></div>
    <div class='grow'>
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiSubfield;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiSubfield: typeof UiSubfield;
    }
}
