import { template as template_584c4e1d62ff4077a6564011963d5054 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface UiSectionTitleSignature {
    Element: HTMLHeadingElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
const UiSectionTitleComponent: TOC<UiSectionTitleSignature> = template_584c4e1d62ff4077a6564011963d5054(`
  <h2 class='text-xl font-semibold text-gray-900 lg:text-2xl'>
    {{yield}}
  </h2>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiSectionTitleComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiSectionTitle: typeof UiSectionTitleComponent;
    }
}
