import { template as template_947acd012144471b8738b96fadc7e339 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import type TransportTypeModel from '../../models/transport-type';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import { modifier } from 'ember-modifier';
import { restartableTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
const ambulanceNames = [
    'als_ambulance',
    'bls_ambulance',
    'cct_ambulance'
];
export interface TransportTypeFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        transportTypeIds: string[];
        onChange: (transportTypeIds: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class TransportTypeFilterComponent extends Component<TransportTypeFilterSignature> {
    @service
    store: Store;
    @tracked
    transportTypes: TransportTypeModel[] = [];
    get transportTypesDisplay() {
        const { transportTypeIds } = this.args;
        const transportTypes = this.transportTypes?.filter((transportType)=>transportTypeIds.includes(transportType.id)).sort((a: TransportTypeModel, b: TransportTypeModel)=>a.fullName.localeCompare(b.fullName));
        return transportTypes ? transportTypes.map((transportType)=>transportType.fullName).join(', ') : null;
    }
    get ambulanceTransportTypes() {
        return this.transportTypes.filter((transportType)=>ambulanceNames.includes(transportType.name));
    }
    get referredTransportTypes() {
        return this.transportTypes.filter((transportType)=>transportType.name !== 'companion');
    }
    loadTransportTypes = restartableTask(async ()=>{
        const transportTypes = await this.store.findAll('transport-type');
        this.transportTypes = transportTypes.slice();
    });
    initListbox = modifier(()=>{
        this.loadTransportTypes.perform();
    });
    @action
    toggleTransportType(type: 'ambulance' | 'referred') {
        const transportTypes = type === 'ambulance' ? this.ambulanceTransportTypes : this.referredTransportTypes;
        this.args.onChange(transportTypes.map((transportType)=>transportType.id));
    }
    static{
        template_947acd012144471b8738b96fadc7e339(`
    <@form.Group data-test-id='transport-types' {{this.initListbox}} as |Group|>
      <Group.Label>Transport Types</Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@transportTypeIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Transport Types'>
          {{this.transportTypesDisplay}}
        </listbox.Button>

        {{!
          TODO: Need to figure out why the items aren't returning in the
                correct order even though the API is returning them sorted.
        }}
        <listbox.Options as |options|>
          {{#each (sortBy 'fullName' this.transportTypes) as |transportType|}}
            <options.Option @value={{transportType.id}}>
              {{transportType.fullName}}
            </options.Option>
          {{/each}}
        </listbox.Options>
      </Group.Listbox>

      <div class='mt-1 flex items-center gap-2 text-xs'>
        <span class='text-gray-500'>Select:</span>
        <ul class='flex gap-2'>
          <li>
            <button
              type='button'
              data-test-id='ambulance'
              class='text-xs text-blue-500 underline hover:no-underline'
              {{on 'click' (fn this.toggleTransportType 'ambulance')}}
            >
              Ambulance
            </button>
          </li>
          {{!
            TODO: Should we show this to non-Onward users once this component
                  is used by the Reporting module?
          }}
          <li>
            <button
              type='button'
              data-test-id='referred'
              class='text-xs text-blue-500 underline hover:no-underline'
              {{on 'click' (fn this.toggleTransportType 'referred')}}
            >
              Referred
            </button>
          </li>
        </ul>
      </div>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::TransportTypeFilter': typeof TransportTypeFilterComponent;
    }
}
