import { template as template_78a5d6db6c8b42f790b07d5da5e5c4e5 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import set from 'ember-set-helper/helpers/set';
import { modifier } from 'ember-modifier';
import { addHours, startOfHour } from 'date-fns';
import type Store from '@ember-data/store';
import type { SegmentedValue } from '@onwardcare/ui-components/components/ui-segmented-control';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiButtonSet from '@onwardcare/ui-components/components/ui-button-set';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiAccordion from '@onwardcare/ui-components/components/ui-accordion';
import type AccountService from '../../../services/account';
import type AccountTransportType from '../../../models/account-transport-type';
import Payment, { PaymentDetails } from './payment';
import type RideEta from '../../../models/ride-eta';
import type RideCostEstimate from '../../../models/ride-cost-estimate';
import RiderCombobox from './rider-combobox';
import RiderDetails from './rider-details';
import RouteSummary from './route-summary';
import type SessionService from '../../../services/session';
import Location, { type RideLocation } from './location';
import type RiderModel from '../../../models/rider';
import TransportReasonsListbox from './transport-reasons-listbox';
import type TransportTypeOption from '../../../models/transport-type-option';
import UiDateTimePicker from '../../ui-date-time-picker';
import UiTimeline from '../../ui-timeline';
import { convertDateToISOInTimezone } from '../../../utils/date-format';
import { getHttpStatusCode } from '../../../utils/error-handlers';
function generateLocationEtaQueryParams(location: RideLocation) {
    return {
        address: location.address,
        latitude: location.latitude,
        longitude: location.longitude
    };
}
function generateLocationEstimateQueryParams(location: RideLocation | null) {
    if (!location) {
        return undefined;
    }
    return {
        address: location.address,
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.name
    };
}
interface OneWayFormSignature {
    Element: HTMLFormElement;
    Args: {
        transportType: AccountTransportType;
        transportTypeOptions: TransportTypeOption;
        onCanceled: () => unknown;
        onSaved: (rideId: string) => unknown;
    };
    Blocks: EmptyObject;
}
export default class OneWayFormComponent extends Component<OneWayFormSignature> {
    @service
    account: AccountService;
    @service
    session: SessionService;
    @service
    store: Store;
    @tracked
    _rideEta: RideEta | null = null;
    @tracked
    rideCostEstimate: RideCostEstimate | null = null;
    @tracked
    dropletPrecautionsRequired = false;
    @tracked
    dropoffLocation: RideLocation | null = null;
    @tracked
    expandedSection = 'rider';
    @tracked
    notes: string | null = null;
    @tracked
    passengerCount = '1';
    @tracked
    paymentDetails = new PaymentDetails();
    @tracked
    pickupLocation: RideLocation | null = null;
    @tracked
    pickupDateTime: string = this.defaultPickupTime;
    @tracked
    recurring = false;
    @tracked
    rider: RiderModel | null = null;
    @tracked
    transportReasonId: string | null = null;
    @tracked
    transportReasonComment: string | null = null;
    /**
   * The default pickup time that's used. It's one hour from now, then the start
   * of the hour. That does mean that if it's 10:59am, this would default to
   * 11:00am.
   */ get defaultPickupTime() {
        const start = startOfHour(addHours(new Date(), 1));
        return convertDateToISOInTimezone(start, this.session.timeZone);
    }
    /**
   * The minimum time for the pickup time is the current time.
   */ get pickupTimeMin() {
        return convertDateToISOInTimezone(new Date(), this.session.timeZone);
    }
    get notesPlaceholderText() {
        const { rideNotesPlaceholderText } = this.args.transportTypeOptions;
        return isPresent(rideNotesPlaceholderText) ? rideNotesPlaceholderText : 'Please include any special needs, precautions, medications, or anything else the driver should know about this rider and transport.';
    }
    get rideEtaParams() {
        if (!this.dropoffLocation || !this.pickupLocation) {
            return undefined;
        }
        return {
            endLocation: generateLocationEtaQueryParams(this.dropoffLocation),
            startLocation: generateLocationEtaQueryParams(this.pickupLocation),
            requestedStartTime: this.pickupDateTime
        };
    }
    fetchRideEta = restartableTask(async ()=>{
        // Wait a bit before fetching the ride ETA in case the user is typing into
        // the input fields.
        await timeout(500);
        const params = this.rideEtaParams;
        if (params) {
            try {
                this._rideEta = await this.store.queryRecord('ride-eta', {
                    ...this.rideEtaParams
                });
            } catch (ex) {
                const status = getHttpStatusCode(ex);
                // If the ETA API returns a 400 error, this just means that either the
                // date or time is in the past, or we're missing the pickup or dropoff
                // location. We don't want to throw an error in this case. Otherwise,
                // we'll throw the error so it gets logged to Sentry.
                if (status !== 400) {
                    throw ex;
                }
            }
        } else {
            this._rideEta = null;
        }
    });
    fetchRideCostEstimate = restartableTask(async ()=>{
        if (this.pickupLocation === null || this.dropoffLocation === null || this.rider === null) {
            this.rideCostEstimate = null;
            return;
        }
        try {
            const params = {
                requested_start_time: this.pickupDateTime,
                ride_type: 'one_way',
                transport_type: this.args.transportType.name,
                contact_precautions_required: this.dropletPrecautionsRequired,
                start_location: generateLocationEstimateQueryParams(this.pickupLocation),
                end_location: generateLocationEstimateQueryParams(this.dropoffLocation),
                payer_type: this.paymentDetails.payerType
            };
            this.rideCostEstimate = await this.store.queryRecord('ride-cost-estimate', params);
        } catch (ex) {
            const status = getHttpStatusCode(ex);
            // If the ride fare API returns a 400 error, this just means that either
            // one of the required params are not populated. We don't want to throw an
            // error in this case. Otherwise, we'll throw the error so it gets logged
            // to Sentry.
            if (status !== 400) {
                throw ex;
            }
        }
    });
    save = restartableTask(async ()=>{
        console.log('Saving...');
    });
    /**
   * Modifier to reset the form when the transport type changes.
   *
   * Although we aren't making use of the transport type in the modifier, it
   * must be present here in order for it to be triggered when it changes.
   */ initForm = modifier((// eslint-disable-next-line @typescript-eslint/no-unused-vars
    _element: HTMLFormElement, // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [_transportType]: [_transportType: AccountTransportType])=>{
        // TODO: This is not how we'll want to handle this in the future. We will
        // want to prompt the user that they have unsaved changes first.
        this.resetForm();
    });
    resetForm() {
        this.dropletPrecautionsRequired = false;
        this.dropoffLocation = null;
        this.expandedSection = 'rider';
        this.notes = null;
        this.passengerCount = '1';
        this.paymentDetails.reset();
        this.pickupLocation = null;
        this.pickupDateTime = this.defaultPickupTime;
        this.recurring = false;
        this.rider = null;
        this.transportReasonId = null;
        this.transportReasonComment = null;
    }
    @action
    handleRiderChange(rider: RiderModel | null) {
        this.rider = rider;
        // TODO: Should we only reset if 'Private' was selected?
        // Reset the payment method since it could be specific to the rider.
        this.paymentDetails.paymentMethod = null;
    }
    @action
    handlePassengerCount(value: SegmentedValue) {
        this.passengerCount = value.selectedValue ?? '1';
    }
    @action
    handleDropletPrecautionsRequired(value: boolean) {
        this.dropletPrecautionsRequired = value;
        this.fetchRideCostEstimate.perform();
    }
    @action
    handleTransportReason(transportReasonId: string | null, comment: string | null) {
        this.transportReasonId = transportReasonId;
        this.transportReasonComment = comment;
    }
    @action
    handlePickupLocationChanged(location: RideLocation) {
        this.pickupLocation = location;
        this.fetchRideEta.perform();
        this.fetchRideCostEstimate.perform();
    }
    @action
    handleDropoffLocationChanged(location: RideLocation) {
        this.dropoffLocation = location;
        this.fetchRideEta.perform();
        this.fetchRideCostEstimate.perform();
    }
    @action
    handlePickupTimeChanged(dateTime: string) {
        this.pickupDateTime = dateTime;
        this.fetchRideEta.perform();
        this.fetchRideCostEstimate.perform();
    }
    @action
    handlePaymentChanged(paymentDetails: PaymentDetails) {
        const updateRideCostEstimate = this.paymentDetails.payerType !== paymentDetails.payerType;
        this.paymentDetails.payerType = paymentDetails.payerType;
        this.paymentDetails.costCenter = paymentDetails.costCenter;
        this.paymentDetails.payerContact = paymentDetails.payerContact;
        this.paymentDetails.paymentMethod = paymentDetails.paymentMethod;
        if (updateRideCostEstimate) {
            this.fetchRideCostEstimate.perform();
        }
    }
    static{
        template_78a5d6db6c8b42f790b07d5da5e5c4e5(`
    <UiForm
      class='flex flex-col gap-6'
      data-test-id='one-way-ride-form'
      @noStyles={{true}}
      @onSubmit={{perform this.save}}
      {{this.initForm @transportType}}
      as |Form|
    >
      <UiAccordion
        @type='single'
        @value={{this.expandedSection}}
        @onValueChange={{set this 'expandedSection'}}
        class='flex flex-col gap-6'
        as |Accordion|
      >
        <Accordion.Item @value='rider' as |Item|>
          <Item.Trigger data-test-id='rider-trigger'>
            <div class='flex items-center gap-4'>
              <UiIcon @icon='user' class='shrink-0' />

              <span class='inline-flex flex-col text-left'>
                <span class='block'>
                  Rider
                </span>

                {{#unless Item.isExpanded}}
                  {{#if this.rider}}
                    <RiderDetails
                      @highlightName={{true}}
                      @rider={{this.rider}}
                    />
                  {{/if}}
                {{/unless}}
              </span>
            </div>
          </Item.Trigger>
          <Item.Content>
            <div class='flex flex-col gap-6'>
              <RiderCombobox
                @formGroup={{Form.Group}}
                @onChange={{this.handleRiderChange}}
                @rider={{this.rider}}
              />

              <Form.Group
                class='flex items-center justify-between'
                data-test-id='passenger-count'
                as |Group|
              >
                <span class='text-sm font-medium leading-6 text-gray-900'>
                  Passenger Count
                </span>

                <Group.SegmentedControl
                  @name='topping-count'
                  @selectedValue={{this.passengerCount}}
                  @onChange={{this.handlePassengerCount}}
                  as |s|
                >
                  <s.Segment @value='1'>1</s.Segment>
                  <s.Segment @value='2'>2</s.Segment>
                  <s.Segment @value='3'>3</s.Segment>
                  <s.Segment @value='4'>4</s.Segment>
                </Group.SegmentedControl>
              </Form.Group>

              <TransportReasonsListbox
                @formGroup={{Form.Group}}
                @onChange={{this.handleTransportReason}}
                @transportReasonId={{this.transportReasonId}}
                @transportReasonComment={{this.transportReasonComment}}
              />

              {{#if @transportTypeOptions.contactPrecautionsRequired}}
                <Form.Group
                  class='flex items-center justify-between'
                  data-test-id='droplet-precautions'
                  as |Group|
                >
                  <Group.Label>
                    Droplet Precautions Required
                  </Group.Label>
                  <Group.Switch
                    @checked={{this.dropletPrecautionsRequired}}
                    @onChange={{this.handleDropletPrecautionsRequired}}
                  />
                </Form.Group>
              {{/if}}
            </div>
          </Item.Content>
        </Accordion.Item>

        <Accordion.Item @value='route' as |Item|>
          <Item.Trigger data-test-id='route-trigger'>
            <div class='flex items-center gap-4'>
              <UiIcon @icon='map-pin' class='shrink-0' />

              <span class='inline-flex flex-col text-left'>
                <span class='block'>
                  Route
                </span>

                {{#unless Item.isExpanded}}
                  <RouteSummary
                    @pickupLocation={{this.pickupLocation}}
                    @dropoffLocation={{this.dropoffLocation}}
                    @pickupDateTime={{this.pickupDateTime}}
                    @rideEta={{this._rideEta}}
                    @timeZone={{this.session.timeZone}}
                  />
                {{/unless}}
              </span>
            </div>
          </Item.Trigger>
          <Item.Content>
            <div class='flex flex-col gap-6'>
              <UiTimeline as |timeline|>
                <timeline.Item>
                  <Location
                    data-test-id='pickup-location'
                    @formGroup={{Form.Group}}
                    @location={{this.pickupLocation}}
                    @onChange={{this.handlePickupLocationChanged}}
                    @riderId={{this.rider.id}}
                    @transportType={{@transportType}}
                    @type='pickup'
                  />
                </timeline.Item>

                <timeline.Item @isLast={{true}}>
                  <Location
                    data-test-id='dropoff-location'
                    @formGroup={{Form.Group}}
                    @location={{this.dropoffLocation}}
                    @onChange={{this.handleDropoffLocationChanged}}
                    @riderId={{this.rider.id}}
                    @transportType={{@transportType}}
                    @type='dropoff'
                  />
                </timeline.Item>
              </UiTimeline>

              <Form.Group>
                <fieldset>
                  <legend class='text-sm font-medium leading-6 text-gray-900'>
                    Pickup Day / Time
                  </legend>

                  <UiDateTimePicker
                    data-test-id='pickup-date-time'
                    @minDate={{this.pickupTimeMin}}
                    @onChange={{this.handlePickupTimeChanged}}
                    @showCalculator={{true}}
                    @timezone={{this.session.timeZone}}
                    @value={{this.pickupDateTime}}
                  />
                </fieldset>
              </Form.Group>
            </div>
          </Item.Content>
        </Accordion.Item>

        <Accordion.Item @value='payment' as |Item|>
          <Payment
            @formGroup={{Form.Group}}
            @onChange={{this.handlePaymentChanged}}
            @paymentDetails={{this.paymentDetails}}
            @rideCostEstimate={{this.rideCostEstimate}}
            @rider={{this.rider}}
            as |p|
          >
            <Item.Trigger data-test-id='payment-trigger'>
              <div class='flex items-center gap-4'>
                <UiIcon @icon='banknotes' class='shrink-0' />

                <span class='inline-flex flex-col text-left'>
                  <span class='block'>
                    Payment
                  </span>

                  {{#unless Item.isExpanded}}
                    <p.Summary />
                  {{/unless}}
                </span>
              </div>
            </Item.Trigger>
            <Item.Content>
              <p.Form />
            </Item.Content>
          </Payment>
        </Accordion.Item>
      </UiAccordion>

      <Form.Group data-test-id='ride-notes' as |Group|>
        <Group.Label class='flex items-center'>
          Ride Notes
        </Group.Label>
        <Group.Textarea
          @value={{this.notes}}
          @onChange={{set this 'notes'}}
          placeholder={{this.notesPlaceholderText}}
          rows='4'
        />
      </Form.Group>

      <Form.Group data-test-id='recurring-trip' as |Group|>
        <div class='flex items-center justify-between'>
          <Group.Label>
            Recurring Trip
          </Group.Label>
          <Group.Switch
            @checked={{this.recurring}}
            @onChange={{set this 'recurring'}}
          />
        </div>

        <Group.HelpText>
          Create a repeating trip based on a schedule.
        </Group.HelpText>
      </Form.Group>

      <Form.Actions
        class='flex justify-end border-t border-t-gray-200 py-4'
        as |Actions|
      >
        <UiButtonSet>
          <UiButton @onClick={{@onCanceled}}>Cancel</UiButton>
          <Actions.Submit>Book this Ride
            {{#if this.rideCostEstimate}}
              -
              <span data-test-id='ride-cost-estimate'>
                {{this.rideCostEstimate.estimatedCostFormatted}}
              </span>
            {{/if}}
          </Actions.Submit>
        </UiButtonSet>
      </Form.Actions>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::OneWayForm': typeof OneWayFormComponent;
    }
}
