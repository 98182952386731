import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { and, or } from 'ember-truth-helpers';

export interface UiComboboxButtonSignature {
  Element: HTMLSpanElement;
  Args: {
    placeholder?: string;
    showClear: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    placeholder?: [];
    default: [];
  };
}

export default class UiComboboxButtonComponent extends Component<UiComboboxButtonSignature> {
  get noValue() {
    return isEmpty(this.args.value);
  }

  <template>
    {{#let
      (and this.noValue (or (has-block 'placeholder') @placeholder))
      as |showPlaceholder|
    }}
      <span
        class='col-start-1 row-start-1 truncate
          {{if showPlaceholder "text-gray-400"}}
          {{if @showClear "pr-14" "pr-6"}}'
      >
        {{#if showPlaceholder}}
          <span class='text-gray-400'>
            {{#if (has-block 'placeholder')}}
              {{yield to='placeholder'}}
            {{else}}
              {{@placeholder}}
            {{/if}}
          </span>
        {{else}}
          {{yield}}
        {{/if}}
      </span>
    {{/let}}
  </template>
}
