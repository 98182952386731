import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
import { hash, uniqueId } from '@ember/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import type { ValidationError } from './ui-validation-errors.gts';

type State = 'default' | 'disabled' | 'error';

interface UiRadioSignature {
  Element: HTMLDivElement;
  Args: {
    disabled?: boolean;
    errors?: ValidationError[];
    groupValue: string;
    hasErrors?: boolean;
    id?: string;
    name: string;
    onChange?: (value: string) => unknown;
    value: string;
  };
  Blocks: {
    default: [];
  };
}

const inputBaseClasses = 'form-radio size-4 transition';
const inputStateClasses: Record<State, string> = {
  default: 'border-gray-300 text-purple-500 focus:ring-purple-500',
  disabled: 'cursor-not-allowed border-gray-300 text-purple-300',
  error: 'border-red-300 text-red-500 focus:ring-red-500',
};
const labelBaseClasses = 'ml-2 block text-sm/6 font-medium';
const labelStateClasses: Record<State, string> = {
  default: 'text-gray-900',
  disabled: 'text-gray-900',
  error: 'text-red-600',
};

class UiRadio extends Component<UiRadioSignature> {
  get id() {
    return this.args.id ?? uniqueId();
  }

  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get inputBaseClasses() {
    return inputBaseClasses;
  }

  get inputStateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return inputStateClasses[state];
  }

  get labelBaseClasses() {
    return labelBaseClasses;
  }

  get labelStateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return labelStateClasses[state];
  }

  @action
  handleChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.args.onChange?.(value);
  }

  <template>
    <div data-test-id='radio' class='flex items-center' ...attributes>
      <input
        type='radio'
        data-test-id='radio-input'
        id={{this.id}}
        name={{@name}}
        value={{@value}}
        disabled={{@disabled}}
        data-invalid={{this.hasErrors}}
        defaultChecked={{eq @groupValue @value}}
        class='{{this.inputBaseClasses}} {{this.inputStateClasses}}'
        {{on 'change' this.handleChange}}
      />
      <label
        data-test-id='radio-label'
        for={{this.id}}
        class='{{this.labelBaseClasses}} {{this.labelStateClasses}}'
      >
        {{yield}}
      </label>
    </div>
  </template>
}

export interface UiRadioButtonsSignature {
  Args: {
    disabled?: boolean;
    errors?: ValidationError[];
    groupValue: string;
    hasErrors?: boolean;
    name: string;
    onChange?: (value: string) => unknown;
  };
  Blocks: {
    default: [
      {
        Radio: WithBoundArgs<
          typeof UiRadio,
          | 'disabled'
          | 'errors'
          | 'groupValue'
          | 'hasErrors'
          | 'name'
          | 'onChange'
        >;
      },
    ];
  };
}

const UiRadioButtonsComponent: TOC<UiRadioButtonsSignature> = <template>
  {{yield
    (hash
      Radio=(component
        UiRadio
        disabled=@disabled
        errors=@errors
        groupValue=@groupValue
        hasErrors=@hasErrors
        name=@name
        onChange=@onChange
      )
    )
  }}
</template>;

export default UiRadioButtonsComponent;
