import { template as template_62bacb25a59743f095e5fe0fed873433 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface TransportTypeVolumeSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const TransportTypeVolume: TOC<TransportTypeVolumeSignature> = template_62bacb25a59743f095e5fe0fed873433(`
  <BasicColumnChart
    @modelName='volume-by-transport-type'
    @stacking='normal'
    @title='Volume by Transport Type'
    @tooltipText='Ordering trends segmented by transport type.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TransportTypeVolume;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::TransportTypeVolume': typeof TransportTypeVolume;
    }
}
