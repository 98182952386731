/* global Highcharts */ import { template as template_c390f340ea744901a60a6ea6e6484a0a } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { queryRecord } from 'ember-data-resources';
import type { EmptyObject } from '@ember/component/helper';
import UiColumnChart, { type UiColumnChartSignature } from '@onwardcare/ui-components/components/ui-column-chart';
import type { ChartDateCategory } from '@onwardcare/ui-components/types';
import { partialOrFutureDateXAxisFormatter, partialOrFutureDateXAxisTitleOptions } from '../utils/chart';
import type DefaultChartModel from '../models/shared/default-chart';
import type ReportingFiltersService from '../services/reporting-filters';
import UiCard from '@onwardcare/ui-components/components/ui-card';
import InfoButton from './info-button';
import ChartContainer from './chart-container';
export interface BasicColumnChartSignature {
    Element: HTMLDivElement;
    Args: {
        modelName: string;
        format?: UiColumnChartSignature['Args']['format'];
        stacking?: UiColumnChartSignature['Args']['stacking'];
        title: string;
        tooltipText?: string;
    };
    Blocks: EmptyObject;
}
export default class BasicColumnChartComponent extends Component<BasicColumnChartSignature> {
    @service
    reportingFilters: ReportingFiltersService;
    chartData = queryRecord<DefaultChartModel>(this, this.args.modelName, ()=>this.reportingFilters.chartParams);
    get categories() {
        return this.chartData.record?.categories ? this.chartData.record.categories : [];
    }
    get series() {
        return this.chartData.record?.series ?? [];
    }
    get isEmpty() {
        // This handles the use case where the `series` comes back as an empty
        // array.
        //
        // Example:
        // series: []
        if (this.series.length === 0) {
            return true;
        }
        if (this.args.format === 'percent') {
            // This handles the use case where the the `y` value is "null" for all
            // data arrays in the series. For percent charts, we still want to show
            // the chart when all `y` values are "0".
            //
            // Example:
            // series: [{
            //   name: "Comparion",
            //   data: [{ y: null }, { y: null }, { y: null }]
            // }, {
            //   name: "Rideshare",
            //   data: [{ y: null }, { y: null }, { y: null }]
            // }]
            return this.series.every((series)=>{
                return series.data.every((data)=>data.y === null);
            });
        } else {
            // This handles the use case where the the `y` value is "0" for all data
            // arrays in the series.
            //
            // Example:
            // series: [{
            //   name: "Comparion",
            //   data: [{ y: 0 }, { y: 0 }, { y: 0 }]
            // }, {
            //   name: "Rideshare",
            //   data: [{ y: 0 }, { y: 0 }, { y: 0 }]
            // }]
            return this.series.every((series)=>{
                return series.data.every((data)=>data.y === 0);
            });
        }
    }
    get xAxisTitle() {
        const containsPartial = this.categories.some((category)=>category.isPartial);
        return containsPartial ? partialOrFutureDateXAxisTitleOptions(this.reportingFilters.chartParams.dateGrouping) : undefined;
    }
    xAxisFormatter(context: Highcharts.AxisLabelsFormatterContextObject) {
        const category = this.categories[context.pos] as ChartDateCategory;
        return partialOrFutureDateXAxisFormatter(category.isPartial, context.value);
    }
    static{
        template_c390f340ea744901a60a6ea6e6484a0a(`
    <UiCard @spacing='tight'>
      <h2
        data-test-id='chart-title'
        class='mb-4 text-center text-lg font-semibold text-gray-900 lg:text-xl'
      >
        {{@title}}

        {{#if @tooltipText}}
          <InfoButton class='text-gray-900'>
            {{@tooltipText}}
          </InfoButton>
        {{/if}}
      </h2>

      {{! This would be a w-104 height in TW, but that doesn't exist }}
      {{#let 'h-[416px]' as |heightClasses|}}
        <ChartContainer
          class={{heightClasses}}
          @dataResource={{this.chartData}}
          @isEmpty={{this.isEmpty}}
        >
          <UiColumnChart
            class={{heightClasses}}
            @data={{this.series}}
            @categories={{this.categories}}
            @format={{@format}}
            @stacking={{@stacking}}
            @xAxisFormatter={{this.xAxisFormatter}}
            @xAxisTitle={{this.xAxisTitle}}
          />
        </ChartContainer>
      {{/let}}
    </UiCard>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        BasicColumnChart: typeof BasicColumnChartComponent;
    }
}
