import { format, isEqual, formatISO } from 'date-fns';

function formatISODate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function formatISOTimestamp(date: Date) {
  return formatISO(date);
}

function formatDescriptionDate(
  date: Date,
  formatDescription: string = 'MMM do, yyyy',
) {
  return format(date, formatDescription);
}

export type DateGrouping = 'day' | 'week' | 'month' | 'quarter';

export type DateGroupingOption = {
  id: DateGrouping;
  label: string;
};

export type DateRangeOption = {
  id: string;
  label: string;
  description: string;
  startDate: string | null;
  endDate: string | null;
  startDateValue: Date | null;
  endDateValue: Date | null;
  transformValue?: DateTransformationFn;
};

export type ReportingDateRangeOption = {
  id: string;
  label: string;
  description: string;
  startDate: string;
  endDate: string;
  startDateValue: Date | null;
  endDateValue: Date | null;
  dateGroupingOptions: DateGroupingOption[];
};

export type FilterDateRange = {
  datePeriod: string;
  startDate: string | null;
  endDate: string | null;
};

export type ReportingFilterDateRange = {
  datePeriod: string;
  startDate: string;
  endDate: string;
  dateGrouping: DateGrouping;
};

export type DateRangeItemOptions = {
  startDescription?: string | undefined;
  endDescription?: string | undefined;
  transformValue?: DateTransformationFn;
};

export type DateTransformationFn = {
  (date: Date): string | undefined;
};

export function createDateRangeItem(
  id: string,
  label: string,
  startDate: Date | null,
  endDate: Date | null,
  options?: DateRangeItemOptions,
): DateRangeOption {
  let description = '';

  if (startDate === null && endDate === null) {
    // custom option is selected
    description = 'Select a custom date range.';
  } else if (startDate && endDate && isEqual(startDate, endDate)) {
    // dates are the same denoting a single date
    description = formatDescriptionDate(startDate);
  } else {
    // calculate description of range unless provided
    const startDescriptionText =
      options?.startDescription ||
      (startDate ? formatDescriptionDate(startDate) : 'Beginning of time');

    const endDescriptionText =
      options?.endDescription ||
      (endDate ? formatDescriptionDate(endDate) : 'End of time');

    description = `${startDescriptionText} - ${endDescriptionText}`;
  }

  return {
    id,
    label,
    description,
    startDate: startDate ? formatISODate(startDate) : null,
    endDate: endDate ? formatISODate(endDate) : null,
    startDateValue: startDate,
    endDateValue: endDate,
    transformValue: options?.transformValue,
  };
}

export function createReportingDateRangeItem(
  id: string,
  label: string,
  startDate: Date,
  endDate: Date,
  dateGroupingOptions: DateGroupingOption[],
): ReportingDateRangeOption {
  const item = createDateRangeItem(
    id,
    label,
    startDate,
    endDate,
    undefined,
  ) as ReportingDateRangeOption;
  return {
    ...item,
    dateGroupingOptions,
  };
}

const dateGroupingOptions: DateGroupingOption[] = [
  {
    id: 'day',
    label: 'Day',
  },
  {
    id: 'week',
    label: 'Week',
  },
  {
    id: 'month',
    label: 'Month',
  },
  {
    id: 'quarter',
    label: 'Quarter',
  },
];

export function findDateGroupings(dateGroupings: DateGrouping[]) {
  return dateGroupingOptions.filter((dateGrouping) => {
    return dateGroupings.includes(dateGrouping.id);
  });
}

function dateValueText(
  date: Date | null,
  formatFn: DateTransformationFn,
): string | undefined {
  if (!date) {
    return;
  }

  return formatFn(date);
}

/**
 * Builds an object to use with the API filters for a date range.
 *
 * @param dateRange The date range to build the filter for.
 * @param options The date range objects to use for the filter.
 * @returns Returns the filter object to use with the API.
 */
export function buildDateRangeFilter(
  dateRange: FilterDateRange,
  options: DateRangeOption[],
): string | object | undefined {
  const { datePeriod } = dateRange;

  if (datePeriod === 'custom') {
    const { startDate, endDate } = dateRange;

    // ignorring custom date transformations for the custom case
    return {
      gte: startDate ?? undefined,
      lte: endDate ?? undefined,
    };
  }

  const dateRangeOption = options.find(
    (range: DateRangeOption) => range.id === datePeriod,
  );

  if (dateRangeOption === undefined) {
    return;
  }

  const { startDateValue, endDateValue, transformValue } = dateRangeOption;

  const formattedStartDate = dateValueText(
    startDateValue,
    transformValue || formatISODate,
  );
  const formattedEndDate = dateValueText(
    endDateValue,
    transformValue || formatISODate,
  );

  return {
    gte: formattedStartDate ?? undefined,
    lte: formattedEndDate ?? undefined,
  };
}
