import Component from '@glimmer/component';
import type { ValidationError } from './ui-validation-errors.gts';

export interface UiLabelSignature {
  Element: HTMLLabelElement;
  Args: {
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    for?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class UiLabelComponent extends Component<UiLabelSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  <template>
    <label
      data-test-id='label'
      id={{@id}}
      for={{@for}}
      class='block text-sm font-medium leading-6
        {{if this.hasErrors "text-red-600" "text-gray-900"}}'
      ...attributes
    >
      {{yield}}
    </label>
  </template>
}
