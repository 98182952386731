import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from '../services/session';
import type SessionStorageService from '../services/session-storage';

export default class SignInRoute extends Route {
  @service declare session: SessionService;
  @service declare sessionStorage: SessionStorageService;

  beforeModel() {
    this.session.prohibitAuthentication('authenticated');
    this.sessionStorage.clear();
  }
}
