import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../services/session';
import type SessionStorageService from '../services/session-storage';

export default class SignOutRoute extends Route {
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare sessionStorage: SessionStorageService;

  async beforeModel() {
    this.sessionStorage.clear();
    await this.session.invalidate();

    return this.router.replaceWith('sign-in');
  }
}
