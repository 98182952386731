import { template as template_2229dd19cad746e5a30e69174c7ec138 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import rideCompletionStatuses, { type StatusState } from '../../utils/data/ride-completion-status';
import RideStatusInfo from './ride-status-info';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export interface RideStatusFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        rideStatusIds: string[];
        onChange: (statuses: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class RideStatusFilterComponent extends Component<RideStatusFilterSignature> {
    get rideStatusDisplay() {
        const rideStatuses = rideCompletionStatuses.filter((rideStatus)=>this.args.rideStatusIds.includes(rideStatus.id));
        return rideStatuses.length > 0 ? rideStatuses.map((rideStatus)=>rideStatus.name).join(', ') : null;
    }
    @action
    toggleRideStatus(state: StatusState) {
        const statuses = rideCompletionStatuses.filter((rideStatus)=>rideStatus.state === state).map((rideStatus)=>rideStatus.id);
        this.args.onChange(statuses);
    }
    static{
        template_2229dd19cad746e5a30e69174c7ec138(`
    <@form.Group data-test-id='ride-status' as |Group|>
      <Group.Label class='flex items-center gap-1'>
        Ride Status

        <RideStatusInfo @rideCompletionStatuses={{rideCompletionStatuses}} />
      </Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@rideStatusIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Ride Statuses'>
          {{this.rideStatusDisplay}}
        </listbox.Button>

        <listbox.Options as |options|>
          {{#each rideCompletionStatuses as |rideStatus|}}
            <options.Option @value={{rideStatus.id}}>
              {{rideStatus.name}}
            </options.Option>
          {{/each}}
        </listbox.Options>
      </Group.Listbox>

      <div class='mt-1 flex items-center gap-2 text-xs'>
        <span class='text-gray-500'>Select:</span>
        <ul class='flex gap-2'>
          <li>
            <button
              type='button'
              data-test-id='upcoming'
              class='text-xs text-blue-500 underline hover:no-underline'
              {{on 'click' (fn this.toggleRideStatus 'upcoming')}}
            >
              All Upcoming
            </button>
          </li>
          <li>
            <button
              type='button'
              data-test-id='historical'
              class='text-xs text-blue-500 underline hover:no-underline'
              {{on 'click' (fn this.toggleRideStatus 'historical')}}
            >
              All Historical
            </button>
          </li>
        </ul>
      </div>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RideStatusFilter': typeof RideStatusFilterComponent;
    }
}
