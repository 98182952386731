import Model, { attr } from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  @attr() declare cardBrand: string;
  @attr() declare cardLast4: string;
  @attr() declare createdAt: string;
  @attr() declare name: string;
  @attr() declare updatedAt: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-method': PaymentMethodModel;
  }
}
