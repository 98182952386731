import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import type { ValidationError } from './ui-validation-errors.gts';

export type InputState = 'default' | 'disabled' | 'error';

export interface UiInputSignature {
  Element: HTMLInputElement;
  Args: {
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    value?: string | null;
    /**
     * @private
     * Set by the `UiInputGroup` component.
     */
    isInGroup?: boolean;
    /**
     * @private
     * Set by the `UiInputGroup` component.
     */
    leadingIcon?: boolean;
    /**
     * @private
     * Set by the `UiInputGroup` component.
     */
    trailingIcon?: boolean;
  };
}

const baseClasses =
  'transition block w-full border-0 py-1.5 placeholder:text-gray-400 sm:text-sm sm:leading-6';
const standardBaseClasses =
  'form-input ring-1 ring-inset rounded-md shadow-sm focus:ring-2 focus:ring-inset';
const groupOnlyBaseClasses =
  'appearance-none min-w-0 grow bg-transparent focus:outline focus:outline-0';
const stateClasses: Record<InputState, string> = {
  default: 'bg-white text-gray-900 ring-gray-300 focus:ring-purple-500',
  disabled: 'bg-gray-200 text-gray-600 ring-gray-300 cursor-not-allowed',
  error: 'bg-white text-gray-900 ring-red-400 focus:ring-red-500',
};
const groupStateClasses: Record<InputState, string> = {
  default: 'text-gray-900',
  disabled: 'text-gray-600 cursor-not-allowed',
  error: 'text-gray-900',
};

export default class UiInputComponent extends Component<UiInputSignature> {
  get baseClasses() {
    if (this.args.isInGroup) {
      const { leadingIcon, trailingIcon } = this.args;
      const leadingClasses = leadingIcon ? 'pl-2' : 'pl-0';
      const trailingClasses = trailingIcon ? 'pr-2' : 'pr-0';

      return `${baseClasses} ${leadingClasses} ${trailingClasses} ${groupOnlyBaseClasses}`;
    }

    return `${baseClasses} ${standardBaseClasses}`;
  }

  get stateClasses() {
    let state: InputState = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return this.args.isInGroup ? groupStateClasses[state] : stateClasses[state];
  }

  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  @action
  handleChange(eventType: 'blur' | 'input', event: Event) {
    const value = (event.target as HTMLInputElement).value;

    if (eventType === 'blur') {
      this.args.onBlur?.(value);
    } else if (eventType === 'input') {
      this.args.onChange?.(value);
    }
  }

  <template>
    <input
      data-test-id='input'
      type='text'
      id={{@id}}
      class='{{this.baseClasses}} {{this.stateClasses}}'
      value={{if @value @value ''}}
      disabled={{@disabled}}
      aria-invalid={{this.hasErrors}}
      {{on 'blur' (fn this.handleChange 'blur')}}
      {{on 'input' (fn this.handleChange 'input')}}
      ...attributes
    />
  </template>
}
