import { template as template_84ccdfa0aefd45529b888ee7331b35c9 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { query } from 'ember-data-resources';
import type { EmptyObject } from '@ember/component/helper';
import type RideSummaryModel from '../../models/ride-summary';
import type ReportingFiltersService from '../../services/reporting-filters';
import UiStats from '../ui-stats';
export interface RideSummariesSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class RideSummariesComponent extends Component<RideSummariesSignature> {
    @service
    reportingFilters: ReportingFiltersService;
    chartData = query<RideSummaryModel>(this, 'ride-summary', ()=>this.reportingFilters.chartParams);
    get rideSummaries() {
        return this.chartData.records?.map((record)=>{
            return {
                label: record.label,
                value: record.value,
                tooltipText: this.summaryTooltipText(record.label)
            };
        });
    }
    // This is a hack until we can either have the tooltip text come from the API
    // or we add an `id` to each summary so that we can better add text.
    summaryTooltipText(label: string) {
        switch(label){
            case 'Completed Trips':
                return 'Count of Completed Trips within the Date Range.';
            case 'Upcoming Trips':
                return 'Count of Upcoming Trips within the Date Range.';
            case '% Accepted':
                return 'Percentage of trips assigned a driver at least one hour prior to the start time.';
            case '% On Time':
                return 'Percentage of Completed Trips started on time (within 15 minutes of the requested start time).';
        }
    }
    static{
        template_84ccdfa0aefd45529b888ee7331b35c9(`
    <UiStats as |stats|>
      {{#each this.rideSummaries as |summary|}}
        <stats.Stat
          @label={{summary.label}}
          @value={{summary.value}}
          @tooltipText={{summary.tooltipText}}
        />
      {{else}}
        {{! TODO: Might want to figure out a better way to get the count. }}
        <stats.Empty @count={{4}} />
      {{/each}}
    </UiStats>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::RideSummaries': typeof RideSummariesComponent;
    }
}
