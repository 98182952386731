import type { TOC } from '@ember/component/template-only';

export interface UiHelpTextSignature {
  Element: HTMLSpanElement;
  Blocks: {
    default: [];
  };
}

const UiHelpTextComponent: TOC<UiHelpTextSignature> = <template>
  <span
    class='text-xs italic text-gray-500'
    data-test-id='help-text'
    ...attributes
  >
    {{yield}}
  </span>
</template>;

export default UiHelpTextComponent;
