import type { TOC } from '@ember/component/template-only';

export interface UiComboboxEmptySignature {
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const UiComboboxEmptyComponent: TOC<UiComboboxEmptySignature> = <template>
  <span
    data-test-id='combobox-option-empty'
    class='relative block cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
  >
    <span class='block truncate'>
      {{yield}}
    </span>
  </span>
</template>;

export default UiComboboxEmptyComponent;
