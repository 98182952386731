import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { modifier } from 'ember-modifier';
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';

export interface UiHeadlessComboboxOptionSignature {
  Element: HTMLLIElement;
  Args: {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
  Blocks: {
    default: [
      {
        active: boolean;
        selected: boolean;
        disabled: boolean;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessComboboxOptionSignature {
  Args: {
    activeOptionGuid?: string | null;
    disabled?: boolean;
    hold?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    registerOptionElement: (
      optionComponent: UiHeadlessComboboxOptionComponent,
      element: HTMLLIElement,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (
      optionComponent: UiHeadlessComboboxOptionComponent,
    ) => void;
    setSelectedOption: (event: Event) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    unregisterOptionElement: (
      optionComponent: UiHeadlessComboboxOptionComponent,
      element: HTMLLIElement,
    ) => void;
    unsetActiveOption: () => void;
  };
}

export default class UiHeadlessComboboxOptionComponent extends Component<InternalSignature> {
  registerOption = modifier((element: HTMLLIElement) => {
    this.args.registerOptionElement(this, element);

    return () => {
      this.args.unregisterOptionElement(this, element);
    };
  });

  @tracked guid = `${guidFor(this)}-headlessui-combobox-option`;

  @action
  handleOptionClick(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (this.args.disabled) return;

    this.args.setSelectedOption(e);
    this.callOnChangeWithSelectedValue();
  }

  @action
  handleMouseOut() {
    if (this.args.hold) return;

    this.args.unsetActiveOption();
  }

  callOnChangeWithSelectedValue() {
    if (this.args.onChange) {
      let newSelectedValue;

      if (this.isMultiselectable) {
        if (this.isSelectedOption) {
          newSelectedValue = this.args.selectedValue.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (s: any) => s !== this.args.value,
          );
        } else {
          newSelectedValue = [...this.args.selectedValue, this.args.value];
        }
      } else {
        newSelectedValue = this.args.value;
      }
      this.args.onChange(newSelectedValue);
    }
  }

  get isActiveOption() {
    return this.args.activeOptionGuid === this.guid;
  }

  get isSelectedOption() {
    if (this.isMultiselectable) {
      return this.args.selectedValue.includes(this.args.value);
    } else {
      return this.args.selectedValue === this.args.value;
    }
  }

  get isMultiselectable() {
    return Array.isArray(this.args.selectedValue);
  }

  <template>
    <li
      role='option'
      id={{this.guid}}
      tabindex={{unless @disabled '-1'}}
      {{this.registerOption}}
      {{on 'focus' (fn @setActiveOption this)}}
      {{on 'mouseover' (fn @setActiveOption this)}}
      {{on 'mouseout' this.handleMouseOut}}
      {{on 'click' this.handleOptionClick}}
      aria-selected={{if this.isSelectedOption 'true' 'false'}}
      aria-disabled={{if @disabled 'true' 'false'}}
      data-is-active={{if this.isActiveOption 'true'}}
      disabled={{if @disabled true false}}
      ...attributes
    >
      {{yield
        (hash
          active=this.isActiveOption
          selected=this.isSelectedOption
          disabled=(if @disabled true false)
        )
      }}
    </li>
  </template>
}
