import { template as template_349f448b070648ac988d32ef5deeb113 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface UiMenuDividerSignature {
    Element: HTMLHRElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const UiMenuDividerComponent: TOC<UiMenuDividerSignature> = template_349f448b070648ac988d32ef5deeb113(`
  <hr class='my-1 border-gray-200' />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiMenuDividerComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-menu/divider': typeof UiMenuDividerComponent;
        'UiMenu::Divider': typeof UiMenuDividerComponent;
    }
}
