import { template as template_2339efb056da496c9ebc12134e76e2dc } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import fileQueue from 'ember-file-upload/helpers/file-queue';
import type { UploadFile } from 'ember-file-upload';
import UiButton from '@onwardcare/ui-components/components/ui-button';
export interface UiFilePickerSignature {
    Element: HTMLDivElement;
    Args: {
        accept?: string;
        fieldName: string;
        fileQueueName: string;
        label: string;
        onFileSelected: (file: UploadFile) => unknown;
    };
    Blocks: EmptyObject;
}
export default class UiFilePickerComponent extends Component<UiFilePickerSignature> {
    @tracked
    selectedFile?: UploadFile;
    @action
    fileSelected(file: UploadFile) {
        this.selectedFile = file;
        this.args.onFileSelected(file);
    }
    static{
        template_2339efb056da496c9ebc12134e76e2dc(`
    {{#let
      (fileQueue name=@fileQueueName onFileAdded=this.fileSelected)
      as |queue|
    }}
      <div
        class='relative focus-within:rounded-md focus-within:ring-2 focus-within:ring-purple-500'
      >
        <label for={{@fieldName}} class='block'>
          <span class='sr-only'>{{@label}}</span>

          <div class='flex items-center gap-2'>
            <UiButton
              data-test-id='file-button'
              @href=''
              aria-controls={{@fieldName}}
              class='flex-none cursor-pointer'
            >
              Choose file
            </UiButton>

            <span
              data-test-id='selected-file-name'
              class='truncate text-sm text-gray-700'
              title={{this.selectedFile.name}}
            >
              {{#if this.selectedFile}}
                {{this.selectedFile.name}}
              {{else}}
                No file chosen
              {{/if}}
            </span>
          </div>
        </label>

        <input
          type='file'
          id={{@fieldName}}
          accept={{@accept}}
          {{! We need to push this off the page. }}
          class='absolute top-[-10000px]'
          {{queue.selectFile}}
        />
      </div>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiFilePicker: typeof UiFilePickerComponent;
    }
}
