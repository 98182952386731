import Component from '@glimmer/component';

export type CardSpacing = 'none' | 'default' | 'tight';

export interface UiCardSignature {
  Element: HTMLDivElement;
  Args: {
    spacing?: CardSpacing;
  };
  Blocks: {
    default: [];
  };
}

export default class UiCardComponent extends Component<UiCardSignature> {
  get spacing() {
    return this.args.spacing || 'default';
  }

  get classes() {
    const baseClasses =
      'bg-white rounded-md border border-gray-300 overflow-hidden';
    let spacingClasses = '';

    switch (this.spacing) {
      case 'none':
        spacingClasses = 'p-0';
        break;

      case 'tight':
        spacingClasses = 'p-2 sm:p-4';
        break;

      case 'default':
      default:
        spacingClasses = 'p-4 sm:p-6';
        break;
    }

    return `${baseClasses} ${spacingClasses}`;
  }

  <template>
    <div data-test-id='card' ...attributes class={{this.classes}}>
      {{yield}}
    </div>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UiCard: typeof UiCardComponent;
  }
}
