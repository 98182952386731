import { template as template_0f47dff9bf5f47ffa92c3eca510d8504 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
export interface CopyrightYearSignature {
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class CopyrightYearComponent extends Component<CopyrightYearSignature> {
    year = new Date().getFullYear();
    static{
        template_0f47dff9bf5f47ffa92c3eca510d8504(`{{this.year}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        CopyrightYear: typeof CopyrightYearComponent;
    }
}
