import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import type { WithBoundArgs } from '@glint/template';
// @ts-expect-error TODO: Figure out how to fix this.
import Transition from '@onwardcare/ember-headlessui/components/transition';
import { type UiHeadlessListboxSignature } from '../ui-headless-listbox.gts';
import ListboxEmpty from './empty.gts';
import ListboxGroup from './group.gts';
import ListboxOption from './option.gts';

export interface UiListboxOptionsSignature {
  Element: HTMLSpanElement;
  Blocks: {
    default: [
      {
        Empty: WithBoundArgs<typeof ListboxEmpty, 'optionsBlock'>;
        Group: WithBoundArgs<typeof ListboxGroup, 'optionsBlock'>;
        Option: WithBoundArgs<typeof ListboxOption, 'optionsBlock'>;
      },
    ];
  };
}

interface InternalSignature extends UiListboxOptionsSignature {
  Args: {
    listboxComponent: UiHeadlessListboxSignature['Blocks']['default'][0];
  };
}

const UiListboxOptionComponent: TOC<InternalSignature> = <template>
  <Transition
    @show={{@listboxComponent.isOpen}}
    @appear={{true}}
    @leave='transition ease-in duration-100'
    @leaveFrom='opacity-100'
    @leaveTo='opacity-0'
    class='absolute z-10 w-full'
  >
    <@listboxComponent.Options
      data-test-id='listbox-options'
      class='mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
      @isOpen={{true}}
      as |options|
    >
      {{yield
        (hash
          Empty=(component ListboxEmpty optionsBlock=options)
          Group=(component ListboxGroup optionsBlock=options)
          Option=(component ListboxOption optionsBlock=options)
        )
      }}
    </@listboxComponent.Options>
  </Transition>
</template>;

export default UiListboxOptionComponent;
