import { template as template_4107b05111fb4333b0bc7bfb39d452bc } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface GrossMarginPercentageSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const GrossMarginPercentage: TOC<GrossMarginPercentageSignature> = template_4107b05111fb4333b0bc7bfb39d452bc(`
  <BasicColumnChart
    @format='percent'
    @modelName='gross-margin-percentage'
    @title='Gross Margin'
    @tooltipText='(Total Ride Charges - Total Ride Payouts) / Total Ride Charges for trips with a Ride Charge and/or Ride Payout during the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GrossMarginPercentage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::GrossMarginPercentage': typeof GrossMarginPercentage;
    }
}
