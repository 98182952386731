import { toDateFormat } from '@onwardcare/core/utils/date-format';

/**
 * Converts a date object to an ISO string in the provided time zone.
 *
 * @param date The date object.
 * @param timeZone The time zone to convert the date to.
 * @returns Returns the date in the ISO format in the provided time zone.
 */
export function convertDateToISOInTimezone(date: Date, timeZone: string) {
  // Need to type as `string` since we are requiring a `date` param here.
  return toDateFormat(date, "yyyy-MM-dd'T'HH:mm:ssXXX", timeZone) as string;
}

/**
 * Updates a date string with the date from a date picker and maintains the time.
 *
 * The date must be in the ISO format with the timezone offset that looks like
 * this: `2021-08-12T12:00:00-07:00`.
 *
 * @param date The date in ISO format.
 * @param datePickerValue The value from the date picker an ISO string (YYYY-MM-DD).
 * @returns Returns a new date string updated with the provided date.
 */
export function updateDateFromDatePicker(
  date: string,
  datePickerValue: string,
) {
  const [, timeString] = date.split('T');
  return `${datePickerValue}T${timeString}`;
}

/**
 * Updates a date string with the time from a time picker and maintains the date.
 *
 * The date must be in the ISO format with the timezone offset that looks like
 * this: `2021-08-12T12:00:00-07:00`.
 *
 * @param date The date in ISO format.
 * @param timePickerValue The value from the time picker a 24-hour format.
 * @returns Returns a new date string updated with the provided time.
 */
export function updateDateFromTimePicker(
  date: string,
  timePickerValue: string,
) {
  const [dateString, timeParts] = date.split('T');
  if (!timeParts) {
    return date;
  }
  // This code is not great and is fragile. As soon as we are able to upgrade to
  // `date-fns` v4, we should be able to change this logic.
  const timeZoneSign = timeParts.includes('+') ? '+' : '-';
  const [, timeZone] = timeParts.split(timeZoneSign);
  return `${dateString}T${timePickerValue}:00${timeZoneSign}${timeZone}`;
}
