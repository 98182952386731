import { template as template_f81fa7b34d924d728d7fba22097119a3 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type AccountTransportType from '../../../models/account-transport-type';
import { DEFAULT_ROUTE } from '../../../utils/default-routes';
interface HeaderSectionSignature {
    Element: HTMLDivElement;
    Args: {
        transportType: AccountTransportType;
    };
    Blocks: EmptyObject;
}
const HeaderSectionComponent: TOC<HeaderSectionSignature> = template_f81fa7b34d924d728d7fba22097119a3(`
  <div
    data-test-id='book-ride-header'
    class='flex h-full items-center justify-between border-b border-b-gray-200 p-6'
  >
    <div class='flex items-center gap-2 font-semibold text-gray-900'>
      <span>Book New</span>
      <UiIcon @icon='arrow-right' @inheritSize={{true}} />
      <span data-test-id='transport-type'>{{@transportType.fullName}}</span>
    </div>

    <UiBaseLink
      @route={{DEFAULT_ROUTE}}
      class='text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-purple-500'
    >
      <UiIcon @icon='x-mark' />
    </UiBaseLink>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default HeaderSectionComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::HeaderSection': typeof HeaderSectionComponent;
    }
}
