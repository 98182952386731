import OnwardJSONAPIAdapter from './shared/onward-jsonapi';
import { baseNamespace } from '../utils/adapter-namespaces';

export default class SetupIntentAdapter extends OnwardJSONAPIAdapter {
  namespace = `${baseNamespace}/stripe`;

  pathForType() {
    return 'setup_intent';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'setup-intent': SetupIntentAdapter;
  }
}
