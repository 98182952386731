import type { TOC } from '@ember/component/template-only';
import { gt } from 'ember-truth-helpers';

export type ValidationError = {
  message: string;
};

export interface UiValidationErrorsSignature {
  Element: HTMLUListElement;
  Args: {
    errors?: ValidationError[];
  };
}

const UiValidationErrorsComponent: TOC<UiValidationErrorsSignature> = <template>
  {{#if (gt @errors.length 0)}}
    <ul
      data-test-id='validation-errors'
      class='text-sm text-red-500'
      ...attributes
    >
      {{#each @errors as |error|}}
        <li
          data-test-id='validation-error'
          class='flex items-center gap-1 leading-6'
        >
          {{error.message}}
        </li>
      {{/each}}
    </ul>
  {{/if}}
</template>;

export default UiValidationErrorsComponent;
