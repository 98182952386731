import { template as template_e34c21e0f32f49dc914fbb5c3ec47600 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import type { RideCompletionStatus } from '../../utils/data/ride-completion-status';
import InfoButton from '../info-button';
export interface RideStatusInfoSignature {
    Element: HTMLButtonElement;
    Args: {
        rideCompletionStatuses: RideCompletionStatus[];
    };
    Blocks: EmptyObject;
}
export default class RideStatusInfoComponent extends Component<RideStatusInfoSignature> {
    get rideStatusesInfo() {
        const historical = this.args.rideCompletionStatuses.filter((rideStatus)=>rideStatus.state === 'historical');
        const upcoming = this.args.rideCompletionStatuses.filter((rideStatus)=>rideStatus.state === 'upcoming');
        return {
            historical,
            upcoming
        };
    }
    static{
        template_e34c21e0f32f49dc914fbb5c3ec47600(`
    <InfoButton @popover={{true}}>
      <div class='flex flex-col gap-5'>
        {{#if this.rideStatusesInfo.upcoming}}
          <div>
            <div class='bold uppercase text-gray-600'>Upcoming</div>
            <ul class='flex flex-col gap-1'>
              {{#each this.rideStatusesInfo.upcoming as |rideStatus|}}
                <li>
                  <span class='font-semibold'>
                    {{rideStatus.name}}:
                  </span>
                  {{rideStatus.definition}}
                </li>
              {{/each}}
            </ul>
          </div>
        {{/if}}

        {{#if this.rideStatusesInfo.historical}}
          <div>
            <div class='bold uppercase text-gray-600'>Historical</div>
            <ul class='flex flex-col gap-1'>
              {{#each this.rideStatusesInfo.historical as |rideStatus|}}
                <li>
                  <span class='font-semibold'>
                    {{rideStatus.name}}:
                  </span>
                  {{rideStatus.definition}}
                </li>
              {{/each}}
            </ul>
          </div>
        {{/if}}
      </div>
    </InfoButton>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RideStatusInfo': typeof RideStatusInfoComponent;
    }
}
