import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { tracked } from '@glimmer/tracking';
import type { ComponentLike } from '@glint/template';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import type { UiHeadlessListboxOptionSignature } from '../ui-headless-listbox/-option.ts';
import type { UiHeadlessListboxOptionsSignature } from '../ui-headless-listbox/-options.ts';
import UiIcon from '../ui-icon.ts';

interface ListboxEmptySignature {
  Element: HTMLElement;
  Args: {
    emptyMessage?: string;
    optionComponent: ListboxOptionSignature['Args']['optionComponent'];
  };
  Blocks: {
    default: [];
  };
}

const ListboxEmptyComponent: TOC<ListboxEmptySignature> = <template>
  <@optionComponent
    data-test-id='nested-listbox-empty'
    @disabled={{true}}
    @value=''
    class='outline-none'
    ...attributes
  >
    <span
      class='relative block cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
    >
      <span class='block truncate'>
        {{#if @emptyMessage}}
          {{@emptyMessage}}
        {{else}}
          No options found.
        {{/if}}
      </span>
    </span>
  </@optionComponent>
</template>;

interface OptionItemSignature {
  Element: HTMLSpanElement;
  Args: {
    displayParam?: string;
    option: ListboxOptionSignature['Args']['option'];
  };
  Blocks: {
    default: [];
  };
}

class OptionItemComponent extends Component<OptionItemSignature> {
  get displayText() {
    const { displayParam, option } = this.args;
    return displayParam ? option[displayParam] : option;
  }

  <template>
    <span>{{this.displayText}}</span>
  </template>
}

interface ListboxOptionSignature {
  Element: HTMLElement;
  Args: {
    displayParam?: string;
    inGroup: boolean;
    optionComponent: ComponentLike<UiHeadlessListboxOptionSignature>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: any;
  };
  Blocks: {
    default: [];
  };
}

const ListboxOptionComponent: TOC<ListboxOptionSignature> = <template>
  <@optionComponent
    data-test-id='nested-listbox-option'
    @value={{@option}}
    as |opt|
  >
    <span
      class='{{if opt.active "bg-purple-500 text-white" "text-gray-900"}}
        relative block cursor-default select-none py-2 pr-9
        {{if @inGroup "pl-8" "pl-3"}}'
    >
      <span
        class='{{if opt.selected "font-semibold" "font-normal"}}
          {{if opt.disabled "text-gray-400"}}
          block truncate'
      >
        <OptionItemComponent
          @displayParam={{@displayParam}}
          @option={{@option}}
        />
      </span>

      {{#if opt.selected}}
        <span
          class='{{if opt.active "text-white" "text-purple-500"}}
            absolute inset-y-0 right-0 flex items-center pr-4'
        >
          <UiIcon @icon='check' @type='mini' />
        </span>
      {{/if}}
    </span>
  </@optionComponent>
</template>;

interface ListboxGroupedOptionSignature {
  Element: HTMLElement;
  Args: {
    displayParam?: string;
    expandedGroup: string | null;
    listboxOptionsComponent: ComponentLike<UiHeadlessListboxOptionsSignature>;
    onExpand: (groupName: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: any;
  };
  Blocks: {
    default: [];
  };
}

class ListboxGroupedOptionComponent extends Component<ListboxGroupedOptionSignature> {
  get isExpanded() {
    return this.args.option.groupName === this.args.expandedGroup;
  }

  get isGroup() {
    const { option } = this.args;
    return typeof option === 'string' ? false : 'groupName' in option;
  }

  <template>
    {{#if this.isGroup}}
      {{! @glint-expect-error - Need to figure out how to fix the type. }}
      <@listboxOptionsComponent.Group
        data-test-id='nested-listbox-group'
        as |group|
      >
        <group.Title class='block' data-test-id='nested-listbox-group-title'>
          <button
            type='button'
            class='flex w-full justify-between px-3 py-2 text-left font-medium text-gray-900 hover:bg-purple-200 focus:outline-none
              {{if this.isExpanded "bg-purple-100"}}'
            data-test-id='nested-listbox-group-button'
            {{on 'click' (fn @onExpand @option.groupName)}}
          >
            <span class='block truncate'>
              {{@option.groupName}}
            </span>

            <span>
              <UiIcon
                @icon='chevron-down'
                @type='mini'
                class='text-purple-500 transition
                  {{if this.isExpanded "rotate-180"}}'
              />
            </span>
          </button>
        </group.Title>

        {{#if this.isExpanded}}
          {{#each @option.options as |groupOption|}}
            <ListboxOptionComponent
              @displayParam={{@displayParam}}
              @inGroup={{true}}
              @option={{groupOption}}
              @optionComponent={{group.Option}}
            />
          {{/each}}
        {{/if}}
        {{! @glint-expect-error - Need to figure out how to fix the type. }}
      </@listboxOptionsComponent.Group>
    {{else}}
      <ListboxOptionComponent
        @displayParam={{@displayParam}}
        @inGroup={{false}}
        @option={{@option}}
        {{! @glint-expect-error - Need to figure out how to fix the type. }}
        @optionComponent={{@listboxOptionsComponent.Option}}
      />
    {{/if}}
  </template>
}

interface UiNestedListboxOptionsSignature {
  Element: HTMLElement;
  Args: {
    displayParam?: string;
    emptyMessage?: string;
    expandedGroup: string | null;
    listboxOptionsComponent: ComponentLike<UiHeadlessListboxOptionsSignature>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
  };
  Blocks: {
    default: [];
  };
}

export default class UiNestedListboxOptionsComponent extends Component<UiNestedListboxOptionsSignature> {
  @tracked _expandedGroup: string | null = null;

  get expandedGroup() {
    return this._expandedGroup ?? this.args.expandedGroup;
  }

  @action
  expand(groupName: string) {
    this._expandedGroup = this.expandedGroup === groupName ? null : groupName;
  }

  <template>
    {{#each @options as |option|}}
      <ListboxGroupedOptionComponent
        @displayParam={{@displayParam}}
        @expandedGroup={{this.expandedGroup}}
        @listboxOptionsComponent={{@listboxOptionsComponent}}
        @onExpand={{this.expand}}
        @option={{option}}
      />
    {{else}}
      <ListboxEmptyComponent
        @emptyMessage={{@emptyMessage}}
        {{! @glint-expect-error - Need to figure out how to fix the type. }}
        @optionComponent={{@listboxOptionsComponent.Option}}
      />
    {{/each}}
  </template>
}
