import Component from '@glimmer/component';

export type IconType = 'outline' | 'solid' | 'mini' | 'micro';

export interface UiIconSignature {
  Element: SVGElement;
  Args: {
    icon: string;
    inheritSize?: boolean;
    spin?: boolean;
    type?: IconType;
  };
}

const spinClasses = 'animate-spin-fast';

export default class UiIconComponent extends Component<UiIconSignature> {
  get spinClasses() {
    return this.args.spin ? spinClasses : '';
  }
}
