import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import type { ValidationError } from './ui-validation-errors.gts';

type State = 'default' | 'disabled' | 'error';

export interface UiCheckboxSignature {
  Element: HTMLLabelElement;
  Args: {
    checked?: boolean;
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    name?: string;
    onChange?: (checked: boolean) => unknown;
  };
  Blocks: {
    default: [];
  };
}

const inputBaseClasses =
  'form-checkbox rounded transition focus:outline-none focus:ring-2';
const inputStateClasses: Record<State, string> = {
  default: 'border-gray-300 text-purple-500 focus:ring-purple-500',
  disabled: 'cursor-not-allowed border-gray-300 text-purple-300',
  error: 'border-red-300 text-red-500 focus:ring-red-500',
};

export default class UiCheckboxComponent extends Component<UiCheckboxSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get inputBaseClasses() {
    return inputBaseClasses;
  }

  get inputStateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return inputStateClasses[state];
  }

  @action
  handleChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.args.onChange?.(target.checked);
  }

  <template>
    <label
      data-test-id='checkbox-label'
      class='inline-flex items-center gap-2 text-sm/6 font-medium
        {{if this.hasErrors "text-red-600"}}'
      ...attributes
    >
      <input
        data-test-id='checkbox'
        id={{@id}}
        name={{@name}}
        type='checkbox'
        class='{{this.inputBaseClasses}} {{this.inputStateClasses}}'
        checked={{@checked}}
        disabled={{@disabled}}
        aria-invalid={{this.hasErrors}}
        {{on 'change' this.handleChange}}
      />

      {{yield}}
    </label>
  </template>
}
