import Component from '@glimmer/component';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';
import ComboboxOption from './-option.gts';
import { type UiHeadlessComboboxOptionSignature } from './-option.gts';

export interface UiHeadlessComboboxOptionsSignature {
  Element: HTMLUListElement;
  Blocks: {
    default: [
      {
        Option: ComponentLike<UiHeadlessComboboxOptionSignature>;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessComboboxOptionsSignature {
  Args: {
    activeOptionGuid?: string | null;
    guid: string;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    hold?: boolean;
    labelId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    registerOptionElement: (
      optionComponent: ComboboxOption,
      element: HTMLLIElement,
    ) => void;
    registerOptionsElement: (element: HTMLUListElement) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (optionComponent: ComboboxOption) => void;
    setSelectedOption: (event: Event) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    unregisterOptionElement: (
      optionComponent: ComboboxOption,
      element: HTMLLIElement,
    ) => void;
    unsetActiveOption: () => void;
  };
  Blocks: {
    default: [
      {
        Option: WithBoundArgs<
          typeof ComboboxOption,
          | 'activeOptionGuid'
          | 'hold'
          | 'onChange'
          | 'registerOptionElement'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unregisterOptionElement'
          | 'unsetActiveOption'
        >;
      },
    ];
  };
}

export default class UiHeadlessComboboxOptionsComponent extends Component<InternalSignature> {
  registerOptionsElement = modifier((element: HTMLUListElement) => {
    this.args.registerOptionsElement(element);
  });

  <template>
    <ul
      id={{@guid}}
      tabindex='0'
      role='listbox'
      aria-labelledby={{@labelId}}
      aria-activedescendant={{@activeOptionGuid}}
      aria-orientation='vertical'
      {{this.registerOptionsElement}}
      {{on 'keypress' @handleKeyPress}}
      {{on 'keydown' @handleKeyDown}}
      {{on 'keyup' @handleKeyUp}}
      ...attributes
    >
      {{yield
        (hash
          Option=(component
            ComboboxOption
            activeOptionGuid=@activeOptionGuid
            hold=@hold
            onChange=@onChange
            registerOptionElement=@registerOptionElement
            selectedValue=@selectedValue
            setActiveOption=@setActiveOption
            setSelectedOption=@setSelectedOption
            unregisterOptionElement=@unregisterOptionElement
            unsetActiveOption=@unsetActiveOption
          )
        )
      }}
    </ul>
  </template>
}
