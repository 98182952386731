import Model, { attr } from '@ember-data/model';

export default class TransportTypeOptionModel extends Model {
  @attr() declare contactPrecautionsRequired: boolean;
  @attr() declare oxygenRequired: boolean;
  @attr() declare promptForBedsideNurseContact: boolean;
  @attr() declare promptForMncs: boolean;
  @attr() declare rideNotesPlaceholderText: string | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'transport-type-option': TransportTypeOptionModel;
  }
}
