import type { TOC } from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';
import { hash } from '@ember/helper';
import OptionComponent, { type UiListboxOptionSignature } from './option.gts';
import GroupTitleComponent, {
  type UiListboxGroupTitleSignature,
} from './group-title.gts';
import { type UiHeadlessListboxOptionsSignature } from '../ui-headless-listbox/-options.gts';

export interface UiListboxGroupSignature {
  Element: HTMLDivElement;
  Blocks: {
    default: [
      {
        Title: ComponentLike<UiListboxGroupTitleSignature>;
        Option: ComponentLike<UiListboxOptionSignature>;
      },
    ];
  };
}

interface InternalSignature extends UiListboxGroupSignature {
  Args: {
    optionsBlock: UiHeadlessListboxOptionsSignature['Blocks']['default'][0];
  };
}

const UiListboxGroupComponent: TOC<InternalSignature> = <template>
  <@optionsBlock.Group as |group|>
    {{yield
      (hash
        Title=(component GroupTitleComponent titleComponent=group.Title)
        Option=(component
          OptionComponent optionsBlock=@optionsBlock inGroup=true
        )
      )
    }}
  </@optionsBlock.Group>
</template>;

export default UiListboxGroupComponent;
