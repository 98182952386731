import Component from '@glimmer/component';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { concat, hash } from '@ember/helper';
import GroupTitleComponent, {
  type UiHeadlessListboxGroupTitleSignature,
} from './-group-title.gts';
import OptionComponent, {
  type UiHeadlessListboxOptionSignature,
} from './-option.gts';

export interface UiHeadlessListboxGroupSignature {
  Element: HTMLUListElement;
  Blocks: {
    default: [
      {
        Title: ComponentLike<UiHeadlessListboxGroupTitleSignature>;
        Option: ComponentLike<UiHeadlessListboxOptionSignature>;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessListboxGroupSignature {
  Args: {
    activeOptionGuid?: string;
    multiple?: boolean;
    registerOptionElement: (
      optionComponent: OptionComponent,
      optionElement: HTMLLIElement,
    ) => void;
    scrollIntoView: (optionElement: HTMLLIElement) => void;
    selectedOptionGuids: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (optionComponent: OptionComponent) => void;
    setSelectedOption: (optionComponent: OptionComponent, event: Event) => void;
    unsetActiveOption: () => void;
  };
  Blocks: {
    default: [
      {
        Title: WithBoundArgs<typeof GroupTitleComponent, 'guid'>;
        Option: WithBoundArgs<
          typeof OptionComponent,
          | 'activeOptionGuid'
          | 'multiple'
          | 'registerOptionElement'
          | 'scrollIntoView'
          | 'selectedOptionGuids'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unsetActiveOption'
        >;
      },
    ];
  };
}

export default class UiHeadlessListboxGroupComponent extends Component<InternalSignature> {
  @tracked guid = `${guidFor(this)}-headlessui-listbox-group`;

  <template>
    <ul
      id={{this.guid}}
      role='group'
      aria-labelledby={{concat this.guid '-title'}}
      ...attributes
    >
      {{yield
        (hash
          Title=(component GroupTitleComponent guid=(concat this.guid '-title'))
          Option=(component
            OptionComponent
            activeOptionGuid=@activeOptionGuid
            multiple=@multiple
            registerOptionElement=@registerOptionElement
            scrollIntoView=@scrollIntoView
            selectedOptionGuids=@selectedOptionGuids
            selectedValue=@selectedValue
            setActiveOption=@setActiveOption
            setSelectedOption=@setSelectedOption
            unsetActiveOption=@unsetActiveOption
          )
        )
      }}
    </ul>
  </template>
}
