import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import UiIcon from '../ui-icon.ts';
import type { TabVariation } from '../ui-tab-group.gts';
import Tab from './tab.gts';

export interface TabListSignature {
  Element: HTMLDivElement;
  Args: {
    fitToParent?: boolean;
    onChange: (id: string) => unknown;
    onKeyUp: (event: KeyboardEvent, id: string) => unknown;
    onTabRegistered: (tabElement: HTMLButtonElement) => unknown;
    panelIds: string[];
    renderAsListbox: boolean;
    selectedIndex: number;
    selectedTab: HTMLButtonElement;
    tabIds: string[];
    variation: TabVariation;
  };
  Blocks: {
    default: [
      {
        Tab: WithBoundArgs<
          typeof Tab,
          | 'fitToParent'
          | 'onChange'
          | 'onKeyUp'
          | 'onTabRegistered'
          | 'panelIds'
          | 'renderAsListbox'
          | 'selectedIndex'
          | 'showListboxOptions'
          | 'tabIds'
          | 'variation'
        >;
      },
    ];
  };
}

const tabListVariationClasses: Record<TabVariation, string> = {
  default:
    'inline-flex items-start justify-start rounded-lg bg-white shadow-sm divide-x divide-gray-200',
  none: '',
  segmented:
    'inline-flex items-start justify-start gap-x-1.5 rounded-lg bg-gray-200 p-0.5',
};
const listboxOptionsClasses =
  'absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm';

export default class TabList extends Component<TabListSignature> {
  @tracked _showOptions = false;

  get variationClasses() {
    return this.args.renderAsListbox
      ? this._showOptions
        ? listboxOptionsClasses
        : 'hidden'
      : tabListVariationClasses[this.args.variation];
  }

  get showOptions() {
    return this.args.renderAsListbox ? this._showOptions : true;
  }

  @action
  toggleShowOptions() {
    this._showOptions = !this._showOptions;
  }

  @action
  handleOnChange(id: string) {
    this._showOptions = false;
    this.args.onChange(id);
  }

  get selectedTabHtml() {
    return (
      this.args.selectedTab?.querySelector('[data-tab="content"]')?.innerHTML ??
      ''
    );
  }

  <template>
    {{#if @renderAsListbox}}
      <button
        type='button'
        class='grid h-9 w-full grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 shadow-sm outline outline-1 -outline-offset-1 outline-gray-300 transition-[outline] focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-purple-500 sm:text-sm/6'
        {{on 'click' this.toggleShowOptions}}
      >
        <span
          class='col-start-1 row-start-1 inline-flex items-center justify-start truncate pr-6'
        >
          {{htmlSafe this.selectedTabHtml}}
        </span>

        <span class='col-start-1 row-start-1 self-center justify-self-end'>
          <UiIcon @icon='chevron-up-down' @type='mini' class='text-gray-400' />
        </span>
      </button>
    {{/if}}

    <div
      data-test-id='tab-list'
      role={{if @renderAsListbox 'listbox' 'tablist'}}
      aria-orientation={{if @renderAsListbox undefined 'horizontal'}}
      class='{{if @fitToParent "min-w-full"}} {{this.variationClasses}}'
      ...attributes
    >
      {{yield
        (hash
          Tab=(component
            Tab
            fitToParent=@fitToParent
            onChange=this.handleOnChange
            onKeyUp=@onKeyUp
            onTabRegistered=@onTabRegistered
            panelIds=@panelIds
            renderAsListbox=@renderAsListbox
            selectedIndex=@selectedIndex
            showListboxOptions=this.showOptions
            tabIds=@tabIds
            variation=@variation
          )
        )
      }}
    </div>
  </template>
}
