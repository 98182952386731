import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';
import type * as Highcharts from 'highcharts';
import type { PieSeries } from '../types.ts';
import { chartSettings } from '../utils/chart-settings.ts';
import initChart from '../modifiers/init-chart.ts';

export interface UiPieChartArgs {
  data: PieSeries[];
  showLegend?: boolean;
}

export interface UiPieChartSignature {
  Element: HTMLDivElement;
  Args: UiPieChartArgs;
}

export default class UiPieChart extends Component<UiPieChartSignature> {
  get animationEnabled(): boolean {
    return macroCondition(isTesting()) ? false : true;
  }

  get chartSeries(): Highcharts.SeriesPieOptions[] {
    if (this.args.data) {
      return this.args.data.map((series) => {
        return {
          ...series,
          type: 'pie',
        };
      });
    }

    return [];
  }

  get chartOptions(): Highcharts.Options {
    return {
      credits: {
        // Gets rid of the "highcharts.com" watermark.
        enabled: false,
      },

      colors: chartSettings.seriesColors,

      chart: {
        type: 'pie',
        animation: this.animationEnabled,
        backgroundColor: 'transparent',
        plotBackgroundColor: 'transparent',
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        // To get rid of the default title, need to set `text` to `undefined`.
        text: undefined,
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: this.args.showLegend !== true,
            format:
              '<span class="font-semibold">{point.name}:</span> {point.percentage:.1f}%',
            color: chartSettings.axisTextColor,
            style: {
              textOutline: 'none',
              fontWeight: 'normal',
            },
          },
          showInLegend: this.args.showLegend === true,
        },
        series: {
          animation: this.animationEnabled,
        },
      },

      series: this.chartSeries,

      tooltip: {
        // Gets rid of the tooltip header.
        headerFormat: undefined,
        pointFormat:
          '{point.name}: <b>{point.percentage:.1f}% ({point.y:,.0f})</b>',
      },

      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
    };
  }

  <template>
    <div
      data-test-id='pie-chart'
      class='w-full'
      ...attributes
      {{initChart this.chartOptions}}
    ></div>
  </template>
}
