import Component from '@glimmer/component';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import { concat, hash } from '@ember/helper';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import focusTrap from '../../modifiers/headlessui-focus-trap.ts';
import GroupComponent, {
  type UiHeadlessListboxGroupSignature,
} from './-group.gts';
import OptionComponent, {
  type UiHeadlessListboxOptionSignature,
} from './-option.gts';

export interface UiHeadlessListboxOptionsSignature {
  Element: HTMLUListElement;
  Args: {
    isOpen: boolean;
    static?: boolean;
  };
  Blocks: {
    default: [
      {
        Group: ComponentLike<UiHeadlessListboxGroupSignature>;
        Option: ComponentLike<UiHeadlessListboxOptionSignature>;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessListboxOptionsSignature {
  Args: {
    activeOptionGuid?: string;
    guid: string;
    handleClickOutside: (event: MouseEvent) => void;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    isOpen: boolean;
    labelId?: string;
    multiple?: boolean;
    registerOptionElement: (
      optionComponent: OptionComponent,
      optionElement: HTMLLIElement,
    ) => void;
    registerOptionsElement: (element: HTMLUListElement) => void;
    scrollIntoView: (optionElement: HTMLLIElement) => void;
    selectedOptionGuids: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (optionComponent: OptionComponent) => void;
    setSelectedOption: (optionComponent: OptionComponent, event: Event) => void;
    static?: boolean;
    unregisterOptionsElement: () => void;
    unsetActiveOption: () => void;
  };
  Blocks: {
    default: [
      {
        Group: WithBoundArgs<
          typeof GroupComponent,
          | 'activeOptionGuid'
          | 'multiple'
          | 'registerOptionElement'
          | 'scrollIntoView'
          | 'selectedOptionGuids'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unsetActiveOption'
        >;
        Option: WithBoundArgs<
          typeof OptionComponent,
          | 'activeOptionGuid'
          | 'multiple'
          | 'registerOptionElement'
          | 'scrollIntoView'
          | 'selectedOptionGuids'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unsetActiveOption'
        >;
      },
    ];
  };
}

export default class UiHeadlessListboxOptionsComponent extends Component<InternalSignature> {
  registerOptions = modifier((element: HTMLUListElement) => {
    this.args.registerOptionsElement(element);

    return () => {
      this.args.unregisterOptionsElement();
    };
  });

  <template>
    {{#if (or @isOpen @static)}}
      <ul
        id={{@guid}}
        data-test-id='headlessui-listbox-options'
        tabindex='0'
        role='listbox'
        aria-labelledby={{if @labelId @labelId (concat @guid '-button')}}
        aria-activedescendant={{@activeOptionGuid}}
        aria-orientation='vertical'
        aria-multiselectable={{if @multiple 'true'}}
        {{this.registerOptions}}
        {{on 'keypress' @handleKeyPress}}
        {{on 'keydown' @handleKeyDown}}
        {{on 'keyup' @handleKeyUp}}
        {{focusTrap
          focusTrapOptions=(hash
            allowOutsideClick=@handleClickOutside
            fallbackFocus=(concat '#' @guid)
          )
        }}
        ...attributes
      >
        {{yield
          (hash
            Group=(component
              GroupComponent
              activeOptionGuid=@activeOptionGuid
              multiple=@multiple
              registerOptionElement=@registerOptionElement
              scrollIntoView=@scrollIntoView
              selectedOptionGuids=@selectedOptionGuids
              selectedValue=@selectedValue
              setActiveOption=@setActiveOption
              setSelectedOption=@setSelectedOption
              unsetActiveOption=@unsetActiveOption
            )
            Option=(component
              OptionComponent
              activeOptionGuid=@activeOptionGuid
              multiple=@multiple
              registerOptionElement=@registerOptionElement
              scrollIntoView=@scrollIntoView
              selectedOptionGuids=@selectedOptionGuids
              selectedValue=@selectedValue
              setActiveOption=@setActiveOption
              setSelectedOption=@setSelectedOption
              unsetActiveOption=@unsetActiveOption
            )
          )
        }}
      </ul>
    {{/if}}
  </template>
}
