import type { TOC } from '@ember/component/template-only';

export interface UiButtonSetSignature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

const UiButtonSetComponent: TOC<UiButtonSetSignature> = <template>
  <div class='flex gap-3' ...attributes>
    {{yield}}
  </div>
</template>;

export default UiButtonSetComponent;
