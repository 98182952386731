import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { get } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import UiCheckbox from '../ui-checkbox.gts';
import UiCombobox from '../ui-combobox.ts';
import UiHelpText from './help-text.gts';
import UiInput from '../ui-input.gts';
import UiInputGroup from '../ui-input-group.gts';
import UiLabel from '../ui-label.gts';
import UiListbox from '../ui-listbox.gts';
import UiNestedListbox from '../ui-nested-listbox.gts';
import UiRadioButtons from '../ui-radio-buttons.gts';
import UiSegmentedControl from '../ui-segmented-control.gts';
import UiSwitch from '../ui-switch.gts';
import UiTextarea from '../ui-textarea.gts';
import UiValidationErrors from '../ui-validation-errors.gts';
import type { ValidationError } from '../ui-validation-errors.gts';

interface UiFormGroupSignature {
  Element: HTMLDivElement;
  Args: {
    errors?: ValidationError[];
    model?: object | null;
    property?: string;
  };
  Blocks: {
    default: [
      {
        Checkbox: WithBoundArgs<typeof UiCheckbox, 'id' | 'errors'>;
        Combobox: WithBoundArgs<typeof UiCombobox, 'id' | 'labelId' | 'errors'>;
        HelpText: WithBoundArgs<typeof UiHelpText, never>;
        Input: WithBoundArgs<typeof UiInput, 'errors' | 'id'>;
        InputGroup: WithBoundArgs<typeof UiInputGroup, 'errors' | 'id'>;
        Label: WithBoundArgs<typeof UiLabel, 'errors' | 'id' | 'for'>;
        Listbox: WithBoundArgs<typeof UiListbox, 'id' | 'labelId' | 'errors'>;
        NestedListbox: WithBoundArgs<
          typeof UiNestedListbox,
          'id' | 'labelId' | 'errors'
        >;
        RadioButtons: WithBoundArgs<typeof UiRadioButtons, 'errors'>;
        SegmentedControl: WithBoundArgs<typeof UiSegmentedControl, never>;
        Switch: WithBoundArgs<typeof UiSwitch, 'id' | 'labelId' | 'errors'>;
        Textarea: WithBoundArgs<typeof UiTextarea, 'id' | 'errors'>;
        ValidationErrors: WithBoundArgs<typeof UiValidationErrors, 'errors'>;
      },
    ];
  };
}

export default class UiFormGroupComponent extends Component<UiFormGroupSignature> {
  // Would like to use the `unique-id` helper, but it's not available in JS in
  // in Ember 4.x.
  id = guidFor(this);
  labelId = `${this.id}-label`;

  get errors(): ValidationError[] {
    if (this.args.model && this.args.property) {
      return (
        (get(
          this.args.model,
          `errors.${this.args.property}`,
        ) as ValidationError[]) || []
      );
    }

    return [];
  }

  <template>
    <div ...attributes>
      {{yield
        (hash
          Checkbox=(component UiCheckbox id=this.id errors=this.errors)
          Combobox=(component
            UiCombobox id=this.id labelId=this.labelId errors=this.errors
          )
          HelpText=(component UiHelpText)
          Input=(component UiInput id=this.id errors=this.errors)
          InputGroup=(component UiInputGroup id=this.id errors=this.errors)
          Label=(component
            UiLabel id=this.labelId for=this.id errors=this.errors
          )
          Listbox=(component
            UiListbox id=this.id labelId=this.labelId errors=this.errors
          )
          NestedListbox=(component
            UiNestedListbox id=this.id labelId=this.labelId errors=this.errors
          )
          RadioButtons=(component UiRadioButtons errors=this.errors)
          SegmentedControl=(component UiSegmentedControl)
          Switch=(component
            UiSwitch id=this.id labelId=this.labelId errors=this.errors
          )
          Textarea=(component UiTextarea id=this.id errors=this.errors)
          ValidationErrors=(component UiValidationErrors errors=this.errors)
        )
      }}
    </div>
  </template>
}
