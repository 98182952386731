import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { hash } from '@ember/helper';
import AccordionItem from './ui-accordion/item.gts';

type AccordionSingleArgs = {
  /**
   * The type of accordion. If `single`, only one item can be selected at a
   * time. If `multiple`, multiple items can be selected at a time.
   */
  type: 'single';
} & (
  | {
      /**
       * The currently selected value. To be used in a controlled fashion in
       * conjunction with `onValueChange`.
       */
      value: string;
      /**
       * A callback that is called when the selected value changes. To be used
       * in a controlled fashion in conjunction with `value`.
       */
      onValueChange: (value: string | undefined) => void;
      /**
       * Not available in a controlled fashion.
       */
      defaultValue?: never;
    }
  | {
      /**
       * Not available in an uncontrolled fashion.
       */
      value?: never;
      /**
       * Not available in an uncontrolled fashion.
       */
      onValueChange?: never;
      /**
       * The default value of the accordion. To be used in an uncontrolled
       * fashion.
       */
      defaultValue?: string;
    }
);

type AccordionMultipleArgs = {
  /**
   * The type of accordion. If `single`, only one item can be selected at a
   * time. If `multiple`, multiple items can be selected at a time.
   */
  type: 'multiple';
} & (
  | {
      /**
       * The currently selected values. To be used in a controlled fashion in
       * conjunction with `onValueChange`.
       */
      value: string[];
      /**
       * A callback that is called when the selected values change. To be used
       * in a controlled fashion in conjunction with `value`.
       */
      onValueChange: (value?: string[]) => void;
      /**
       * Not available in a controlled fashion.
       */
      defaultValue?: never;
    }
  | {
      /**
       * Not available in an uncontrolled fashion.
       */
      value?: never;
      /**
       * Not available in an uncontrolled fashion.
       */
      onValueChange?: never;
      /**
       * The default values of the accordion. To be used in an uncontrolled
       * fashion.
       */
      defaultValue?: string[];
    }
);

export interface UiAccordionSignature {
  Element: HTMLDivElement;
  Args: AccordionSingleArgs | AccordionMultipleArgs;
  Blocks: {
    default: [
      {
        Item: WithBoundArgs<typeof AccordionItem, 'onChange' | 'selectedValue'>;
      },
    ];
  };
}

export default class UiAccordionComponent extends Component<UiAccordionSignature> {
  // We purposely don't want to update `_selectedValue` when `args.defaultValue`
  // changes. We just want to use it once when the component is initialized.
  // eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked _selectedValue: string | string[] | undefined =
    this.args.defaultValue;

  get selectedValue() {
    return this.args.value ?? this._selectedValue;
  }

  @action
  toggleItem(value: string) {
    if (this.args.type === 'single') {
      this.toggleItemSingle(value);
    } else if (this.args.type === 'multiple') {
      this.toggleItemMultiple(value);
    }
  }

  toggleItemSingle(value: string) {
    const newValue = value === this.selectedValue ? undefined : value;

    if (this.args.onValueChange) {
      // @ts-expect-error: It's generating bad types for this
      this.args.onValueChange(newValue);
    } else {
      this._selectedValue = newValue;
    }
  }

  toggleItemMultiple(value: string) {
    const currentValues = (this.selectedValue as string[] | undefined) ?? [];
    const indexOfValue = currentValues.indexOf(value);
    let newValue: string[];

    if (indexOfValue === -1) {
      newValue = [...currentValues, value];
    } else {
      newValue = [
        ...currentValues.slice(0, indexOfValue),
        ...currentValues.slice(indexOfValue + 1),
      ];
    }

    if (this.args.onValueChange) {
      // @ts-expect-error: It's generating bad types for this
      this.args.onValueChange(newValue);
    } else {
      this._selectedValue = newValue;
    }
  }

  <template>
    <div ...attributes>
      {{yield
        (hash
          Item=(component
            AccordionItem
            onChange=this.toggleItem
            selectedValue=this.selectedValue
          )
        )
      }}
    </div>
  </template>
}
