import { template as template_90fd686f2527461e980f1ba746a4fa60 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { and, notEq } from 'ember-truth-helpers';
import { dateTime as dateTimeFormat } from '@onwardcare/core/utils/date-format';
import type RideEta from '../../../models/ride-eta';
import UiTimeline from '../../ui-timeline';
import { colorForType } from '../../ui-location-combobox/location-item';
type Location = {
    address: string;
    name: string;
    type?: string;
};
class SummaryItem extends Component<{
    Element: HTMLSpanElement;
    Args: {
        dateTime?: string;
        label: string;
        location: Location;
        timeZone: string;
    };
}> {
    get color() {
        return colorForType(this.args.location.type);
    }
    get dateTimeDisplay() {
        const { dateTime } = this.args;
        return dateTime ? dateTimeFormat(dateTime, {
            timeZone: this.args.timeZone
        }) : null;
    }
    static{
        template_90fd686f2527461e980f1ba746a4fa60(`
    <span class='inline-flex flex-col truncate' ...attributes>
      <span
        data-test-id='name'
        class='truncate text-sm font-bold {{this.color}}'
      >
        {{@location.name}}
      </span>
      <span
        data-test-id='address'
        class='truncate text-xs font-normal text-gray-400'
      >
        {{@location.address}}
      </span>
      <span class='truncate text-xs font-normal text-gray-400'>
        <span data-test-id='time-label'>{{@label}}:</span>
        {{#if this.dateTimeDisplay}}
          <span data-test-id='time'>{{this.dateTimeDisplay}}</span>
        {{else}}
          <span
            data-test-id='time-loading'
            class='inline-block h-2 w-28 animate-pulse rounded-sm bg-gray-200'
          ></span>
        {{/if}}
      </span>
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const RouteSummary: TOC<{
    Args: {
        dropoffLocation: Location | null;
        pickupDateTime: string;
        pickupLocation: Location | null;
        rideEta: RideEta | null;
        timeZone: string;
    };
}> = template_90fd686f2527461e980f1ba746a4fa60(`
  {{#if (and (notEq @pickupLocation null) (notEq @dropoffLocation null))}}
    <UiTimeline data-test-id='route-summary' as |timeline|>
      <timeline.Item>
        {{#if @pickupLocation}}
          <SummaryItem
            data-test-id='pickup-location'
            @label='Pickup'
            @location={{@pickupLocation}}
            @dateTime={{@pickupDateTime}}
            @timeZone={{@timeZone}}
          />
        {{/if}}
      </timeline.Item>

      <timeline.Item @isLast={{true}}>
        {{#if @dropoffLocation}}
          <SummaryItem
            data-test-id='dropoff-location'
            @label='Estimated Dropoff'
            @location={{@dropoffLocation}}
            @dateTime={{@rideEta.arrivalTime}}
            @timeZone={{@timeZone}}
          />
        {{/if}}
      </timeline.Item>
    </UiTimeline>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteSummary;
