import Component from '@glimmer/component';
import type { ValidationError } from './ui-validation-errors';
// @ts-expect-error TODO: Need to add types for this.
import Switch from '@onwardcare/ember-headlessui/components/switch';
import applyId from '../modifiers/apply-id.ts';

type State = 'default' | 'disabled' | 'error';

export interface UiSwitchSignature {
  Element: HTMLDivElement;
  Args: {
    checked?: boolean;
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    labelId: string;
    onChange?: (checked: boolean) => unknown;
  };
  Blocks: {
    placeholder?: [];
  };
}

const stateClasses: Record<State, string> = {
  default:
    'cursor-pointer focus-visible:outline-purple-500 aria-checked:bg-purple-500',
  disabled:
    'cursor-not-allowed focus-visible:outline-purple-500 aria-checked:bg-purple-300',
  error: 'cursor-pointer focus-visible:outline-red-600 aria-checked:bg-red-500',
};

export default class UiSwitchComponent extends Component<UiSwitchSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  <template>
    <Switch
      @isOn={{@checked}}
      @onUpdate={{@onChange}}
      class='flex items-center space-x-4'
      as |switch|
    >
      <switch.Button
        disabled={{@disabled}}
        aria-labelledby={{@labelId}}
        aria-invalid={{this.hasErrors}}
        data-test-id='switch-button'
        class='relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1
          {{this.stateClasses}}'
        {{applyId @id}}
      >
        <span
          class='inline-block h-5 w-5 transform rounded-full bg-white transition duration-200 ease-in-out
            {{if switch.isOn "translate-x-5" "translate-x-0"}}
            '
        ></span>
      </switch.Button>
    </Switch>
  </template>
}
