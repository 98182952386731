import { template as template_0d4c4470b3e540b9921c6dc1e289a078 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type SettingsService from '../services/settings';
type Size = 'small' | 'medium' | 'large';
export interface UiAvatarSignature {
    Element: HTMLSpanElement;
    Args: {
        /**
     * Determines if we are in an active state. This is useful if you are using
     * this in a combobox or listbox and want to add the hover/active state.
     */ isActive?: boolean;
        /**
     * The URL of the photo to display.
     */ photoUrl?: string | null;
        /**
     * The size of the avatar.
     */ size: Size;
    };
    Blocks: {
        /**
     * The fallback content to display if no photoUrl is provided.
     */ fallback: [];
    };
}
export default class UiAvatarComponent extends Component<UiAvatarSignature> {
    @service
    settings: SettingsService;
    get apiUrl() {
        return this.settings.getValue('API_URL');
    }
    get photoUrl() {
        const { photoUrl } = this.args;
        return photoUrl ? new URL(photoUrl, this.apiUrl).toString() : null;
    }
    get sizeClasses() {
        const { size } = this.args;
        if (size === 'small') {
            return 'h-7 w-7';
        } else if (size === 'large') {
            return 'h-14 w-14';
        }
        return 'w-10 h-10';
    }
    get textSizeClasses() {
        const { size } = this.args;
        if (size === 'small') {
            return 'text-xs';
        } else if (size === 'large') {
            return 'text-xl';
        }
        return 'text-base';
    }
    static{
        template_0d4c4470b3e540b9921c6dc1e289a078(`
    <span
      data-test-id='avatar'
      class='inline-flex shrink-0 items-center justify-center overflow-hidden rounded-full
        {{this.sizeClasses}}'
      ...attributes
    >
      {{#if this.photoUrl}}
        <img
          data-test-id='avatar-photo'
          class='inline-block h-full w-full object-cover'
          src={{this.photoUrl}}
          alt=''
        />
      {{else if (has-block 'fallback')}}
        <span
          data-test-id='avatar-fallback'
          class='inline-flex h-full w-full items-center justify-center font-medium leading-none
            {{if
              @isActive
              "bg-white text-purple-500"
              "bg-purple-500 text-white"
            }}
            {{this.textSizeClasses}}'
        >
          {{yield to='fallback'}}
        </span>
      {{else}}
        <span
          data-test-id='avatar-placeholder'
          class='inline-block h-full w-full bg-gray-100'
        >
          <svg
            class='h-full w-full text-gray-300'
            fill='currentColor'
            viewBox='0 0 24 24'
          >
            <path
              d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z'
            />
          </svg>
        </span>
      {{/if}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiAvatar: typeof UiAvatarComponent;
    }
}
