import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { hash, uniqueId } from '@ember/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';

type State = 'checked' | 'checked-disabled' | 'default' | 'disabled';

interface Single {
  selectedValue: string;
  selectedValues?: never;
}

interface Multiple {
  selectedValue?: never;
  selectedValues: string[];
}

export type SegmentedValue = Single | Multiple;

interface UiSegmentSignature {
  Element: HTMLDivElement;
  Args: {
    disabled?: boolean;
    fitToParent?: boolean;
    id?: string;
    multiple?: boolean;
    name: string;
    onChange?: (value: SegmentedValue) => unknown;
    selectedValue?: string | null;
    selectedValues?: string[];
    value: string;
  };
  Blocks: {
    default: [];
  };
}

const baseClasses =
  'flex items-center justify-center w-full h-full px-3 rounded-md border text-sm/6 font-semibold transition-colors outline outline-1 -outline-offset-1 focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2';
const stateClasses: Record<State, string> = {
  checked:
    'cursor-pointer outline-gray-300 bg-white text-purple-500 shadow has-[:focus-visible]:outline-purple-500',
  'checked-disabled':
    'cursor-not-allowed outline-gray-400/50 bg-gray-300 text-gray-700 shadow',
  default:
    'cursor-pointer outline-transparent text-gray-500 hover:text-gray-900 has-[:focus-visible]:outline-purple-500',
  disabled: 'cursor-not-allowed outline-transparent text-gray-500',
};

class UiSegment extends Component<UiSegmentSignature> {
  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';
    const { disabled } = this.args;

    if (disabled && this.isChecked) {
      state = 'checked-disabled';
    } else if (disabled) {
      state = 'disabled';
    } else if (this.isChecked) {
      state = 'checked';
    }

    return stateClasses[state];
  }

  get isChecked() {
    if (this.args.multiple) {
      return this.args.selectedValues?.includes(this.args.value);
    }

    return this.args.selectedValue === this.args.value;
  }

  @action
  handleChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    if (this.args.multiple) {
      const { selectedValues } = this.args;
      let values: string[] = [];

      if (selectedValues) {
        values = selectedValues.includes(value)
          ? selectedValues.filter((v) => v !== value)
          : [...selectedValues, value];
      } else {
        values = [value];
      }

      return this.args.onChange?.({ selectedValues: values });
    }

    return this.args.onChange?.({ selectedValue: value });
  }

  <template>
    <div data-test-id='segment' class='h-full w-full flex-1' ...attributes>
      <label
        data-test-id='segment-label'
        class='{{this.baseClasses}} {{this.stateClasses}}'
      >
        <input
          type={{if @multiple 'checkbox' 'radio'}}
          data-test-id='segment-input'
          name={{@name}}
          value={{@value}}
          disabled={{@disabled}}
          defaultChecked={{this.isChecked}}
          class='sr-only appearance-none'
          {{on 'change' this.handleChange}}
        />

        {{yield}}
      </label>
    </div>
  </template>
}

export interface UiSegmentedControlSignature {
  Element: HTMLDivElement;
  Args: {
    disabled?: boolean;
    fitToParent?: boolean;
    multiple?: boolean;
    name?: string;
    onChange?: (value: SegmentedValue) => unknown;
    selectedValue?: string | null;
    selectedValues?: string[];
  };
  Blocks: {
    default: [
      {
        Segment: WithBoundArgs<
          typeof UiSegment,
          | 'disabled'
          | 'fitToParent'
          | 'multiple'
          | 'name'
          | 'onChange'
          | 'selectedValue'
          | 'selectedValues'
        >;
      },
    ];
  };
}

export default class UiSegmentedControlComponent extends Component<UiSegmentedControlSignature> {
  get name() {
    return this.args.name ?? uniqueId();
  }

  <template>
    <div
      data-test-id='segmented-control'
      class='{{if @fitToParent "min-w-full"}}
        inline-flex h-9 items-start justify-start gap-x-1 rounded-lg bg-gray-200 p-0.5'
    >
      {{yield
        (hash
          Segment=(component
            UiSegment
            disabled=@disabled
            fitToParent=@fitToParent
            multiple=@multiple
            name=this.name
            onChange=@onChange
            selectedValue=@selectedValue
            selectedValues=@selectedValues
          )
        )
      }}
    </div>
  </template>
}
