import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from '../../services/session';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import ROLES from '../../utils/data/user-roles';
import { captureMessage } from '../../utils/error-logging';

export default class ReportingRoute extends Route {
  @service declare router: RouterService;
  @service declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.hasRole(ROLES.CAN_ACCESS_BI) === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }

    if (transition.to?.name === 'authenticated.reporting.index') {
      // TODO: We probably need a better way to handle this. The default route
      //       for the app points to a page for Onward Associates. If the user
      //       is not an Onward Associate, then we redirect to the default page
      //       for normal users.
      if (this.session.isOnwardAssociate) {
        this.router.replaceWith('authenticated.reporting.operations');
      } else {
        this.router.replaceWith('authenticated.reporting.volumes');
      }
    }

    return this.session.loadAccountData.perform();
  }
}
