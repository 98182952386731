import { template as template_074d0e0b90d24ced8d6b8039c7cce22c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import type FinancialResponsibilityModel from '../../../../models/financial-responsibility';
interface FinancialResponsibilitiesListboxSignature {
    Args: {
        financialResponsibilities: FinancialResponsibilityModel[];
        formGroup: typeof UiFormGroup;
        onChange: (financialResponsibility: FinancialResponsibilityModel) => void;
        selectedFinancialResponsibility: FinancialResponsibilityModel | null;
    };
}
export default class FinancialResponsibilitiesListboxComponent extends Component<FinancialResponsibilitiesListboxSignature> {
    get options() {
        const records = this.args.financialResponsibilities;
        if (records.length === 0) {
            return [];
        }
        const payerTypes = records.reduce((acc: string[], record)=>{
            if (!acc.includes(record.displayName)) {
                acc.push(record.displayName);
            }
            return acc;
        }, []);
        return payerTypes.map((payerType)=>{
            const costCenters = records.filter((record)=>record.displayName === payerType);
            if (costCenters.length === 1 && costCenters[0]) {
                return costCenters[0];
            }
            return {
                groupName: payerType,
                options: costCenters
            };
        });
    }
    @action
    handleChange(value: FinancialResponsibilityModel) {
        this.args.onChange(value);
    }
    static{
        template_074d0e0b90d24ced8d6b8039c7cce22c(`
    {{#if @financialResponsibilities}}
      <@formGroup data-test-id='financial-responsibility' as |Group|>
        <Group.Label>Financial Responsibility</Group.Label>
        <Group.NestedListbox
          @options={{this.options}}
          @displayParam='costCenterName'
          @value={{@selectedFinancialResponsibility}}
          @onChange={{this.handleChange}}
        />
      </@formGroup>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::Payment::FinancialResponsibilitiesListbox': typeof FinancialResponsibilitiesListboxComponent;
    }
}
