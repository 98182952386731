import type { TOC } from '@ember/component/template-only';

export interface UiHeadlessListboxGroupTitleSignature {
  Element: HTMLLIElement;
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends UiHeadlessListboxGroupTitleSignature {
  Args: {
    guid: string;
  };
}

const UiHeadlessListboxGroupTitleComponent: TOC<InternalSignature> = <template>
  <li role='presentation' id={{@guid}} ...attributes>
    {{yield}}
  </li>
</template>;

export default UiHeadlessListboxGroupTitleComponent;
