import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { eq } from 'ember-truth-helpers';
import UiBaseLink from './ui-base-link.gts';
import type { UiBaseLinkArgs } from './ui-base-link.gts';
import UiIcon from './ui-icon.ts';

export type ButtonVariant = 'danger' | 'primary' | 'secondary';
export type ButtonSize = 'mini' | 'small' | 'default';
export type ButtonGroupPosition = 'leading' | 'trailing' | 'middle';
export type IconPosition = 'leading' | 'trailing';

export interface UiButtonArgs extends UiBaseLinkArgs {
  'data-test-id'?: string;
  disabled?: boolean;
  full?: boolean;
  icon?: string;
  iconPosition?: IconPosition;
  isRunning?: boolean;
  onClick?: (event: Event) => unknown;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
  /**
   * @private
   * Set by the `UiInputGroup` component to ensure the button is styled
   * correctly since there is only one button on either side of the input.
   */
  buttonGroupPosition?: ButtonGroupPosition;
  /**
   * @private
   * Set by the `UiButtonGroup` component.
   */
  isInGroup?: boolean;
}

export interface UiButtonSignature {
  Element: HTMLButtonElement | HTMLAnchorElement;
  Args: UiButtonArgs;
  Blocks: {
    default: [];
  };
}

// The link and button classes have to be split out since links don't support
// the `enabled` helper.
const baseClasses =
  'transition inline-flex items-center border font-semibold whitespace-nowrap focus:outline-none focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2';
const baseButtonClasses = 'enabled:active:shadow-md disabled:opacity-60';
const baseLinkClasses = 'active:shadow-md';
const variantBaseClasses: Record<ButtonVariant, string> = {
  danger: 'bg-red-600 text-white border-red-700 focus-visible:outline-red-600',
  primary:
    'bg-purple-500 text-white border-purple-600 focus-visible:outline-purple-500',
  secondary:
    'bg-white text-gray-900 border-gray-300 focus-visible:outline-purple-500',
};
const variantButtonClasses: Record<ButtonVariant, string> = {
  danger: 'enabled:hover:bg-red-700 enabled:active:bg-red-700',
  primary: 'enabled:hover:bg-purple-700 enabled:active:bg-purple-600',
  secondary: 'enabled:hover:bg-gray-50 enabled:active:bg-white',
};
const variantLinkClasses: Record<ButtonVariant, string> = {
  danger: 'hover:bg-red-700 active:bg-red-700',
  primary: 'hover:bg-purple-700 active:bg-purple-600',
  secondary: 'hover:bg-gray-50 active:bg-white',
};
const sizeClasses: Record<ButtonSize, string> = {
  default: 'px-3 py-2 text-sm gap-x-1.5',
  small: 'px-2.5 py-1.5 text-sm gap-x-1.5',
  mini: 'px-2 py-1 text-xs gap-x-1.5',
};

export default class UiButtonComponent extends Component<UiButtonSignature> {
  @tracked isRunning = false;

  get 'data-test-id'() {
    return this.args['data-test-id'] ?? 'button';
  }

  get type() {
    return this.args.type ?? 'button';
  }

  get isInGroup() {
    return (
      this.args.isInGroup === true ||
      this.args.buttonGroupPosition !== undefined
    );
  }

  get baseClasses() {
    let base = baseClasses;

    if (this.isInGroup) {
      const { buttonGroupPosition } = this.args;
      base = `${base} focus:z-10`;

      if (buttonGroupPosition === 'leading') {
        base = `${base} rounded-l-md`;
      } else if (buttonGroupPosition === 'trailing') {
        base = `${base} -ml-px rounded-r-md`;
      } else if (buttonGroupPosition === 'middle') {
        base = `${base} -ml-px`;
      } else {
        base = `${base} -ml-px first:-ml-0 first:rounded-l-md last:rounded-r-md`;
      }
    } else {
      base = `${base} rounded-md`;
    }

    let baseButton = baseButtonClasses;
    if (!this.isInGroup) {
      baseButton = `${baseButton} enabled:shadow-sm`;
    }

    let baseLink = baseLinkClasses;
    if (!this.isInGroup) {
      baseLink = `${baseLink} shadow-sm`;
    }

    return this.isLink ? `${base} ${baseLink}` : `${base} ${baseButton}`;
  }

  get variant() {
    if (this.args.variant) {
      return this.args.variant;
    }

    return 'secondary';
  }

  get variantClasses() {
    return this.isLink
      ? `${variantBaseClasses[this.variant]} ${
          variantLinkClasses[this.variant]
        }`
      : `${variantBaseClasses[this.variant]} ${
          variantButtonClasses[this.variant]
        }`;
  }

  get size() {
    return this.args.size ?? 'default';
  }

  get sizeClasses() {
    return sizeClasses[this.size];
  }

  get showIndicator() {
    return (
      this.buttonTask.isRunning ||
      this.args.isRunning === true ||
      this.isRunning === true
    );
  }

  get buttonDisabled() {
    return (
      this.buttonTask.isRunning ||
      this.args.isRunning === true ||
      this.isRunning === true ||
      this.args.disabled === true
    );
  }

  get isLink() {
    return this.args.href !== undefined || this.args.route !== undefined;
  }

  get iconPosition() {
    return this.args.iconPosition ?? 'leading';
  }

  get iconClasses() {
    const position = this.iconPosition === 'leading' ? '-ml-0.5' : '-mr-0.5';
    const size = this.size === 'mini' ? 'w-3 h-3' : 'w-4 h-4';
    return `${position} ${size}`;
  }

  buttonTask = restartableTask(async (event: MouseEvent) => {
    let result: ReturnType<NonNullable<UiButtonArgs['onClick']>>;

    if (this.args.onClick) {
      result = this.args.onClick(event);
    }

    if (result) {
      this.isRunning = true;
      // Wrap this in a Promise because it might just be a regular function and
      // you shouldn't await regular functions.
      await Promise.resolve(result);
    }

    this.isRunning = false;
  });

  <template>
    {{#if this.isLink}}
      <UiBaseLink
        data-test-id={{this.data-test-id}}
        class='{{this.baseClasses}}
          {{this.variantClasses}}
          {{this.sizeClasses}}
          {{if @full "w-full justify-center"}}'
        @download={{@download}}
        @href={{@href}}
        @route={{@route}}
        @model={{@model}}
        @models={{@models}}
        @query={{@query}}
        ...attributes
      >
        {{yield}}
      </UiBaseLink>
    {{else}}
      <button
        data-test-id={{this.data-test-id}}
        data-test-variant={{this.variant}}
        type={{this.type}}
        class='{{this.baseClasses}}
          {{this.sizeClasses}}
          {{this.variantClasses}}
          {{if @full "w-full justify-center"}}'
        disabled={{this.buttonDisabled}}
        {{on 'click' (perform this.buttonTask)}}
        ...attributes
      >
        {{#if (eq this.iconPosition 'trailing')}}
          {{yield}}
        {{/if}}

        {{#if this.showIndicator}}
          <UiIcon
            data-test-id='loading-indicator'
            @icon='spinner'
            @type='mini'
            @spin={{true}}
            class={{this.iconClasses}}
          />
        {{else if @icon}}
          <UiIcon @icon={{@icon}} @type='mini' class={{this.iconClasses}} />
        {{/if}}

        {{#if (eq this.iconPosition 'leading')}}
          {{yield}}
        {{/if}}
      </button>
    {{/if}}
  </template>
}
