import { template as template_7a3e25239fdd4c50a5c68ff11644f64f } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface DesktopNavigationSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
const DesktopNavigation: TOC<DesktopNavigationSignature> = template_7a3e25239fdd4c50a5c68ff11644f64f(`
  <div class='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DesktopNavigation;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::DesktopNavigation': typeof DesktopNavigation;
    }
}
