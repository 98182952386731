import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
import UiButton from './ui-button.gts';
import UiIcon from './ui-icon.ts';
import UiInput, { type InputState } from './ui-input.gts';
import type { ValidationError } from './ui-validation-errors.ts';

const InputIcon: TOC<{
  Blocks: {
    default: [];
  };
}> = <template>
  <div class='shrink-0 select-none text-base text-gray-400 sm:text-sm/6'>
    {{yield}}
  </div>
</template>;

export interface UiInputGroupSignature {
  Element: HTMLDivElement;
  Args: {
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    /**
     * @private
     * Set by the `UiFormGroup` component.
     */
    id?: string;
  };
  Blocks: {
    leadingButton: [
      WithBoundArgs<
        typeof UiButton,
        'buttonGroupPosition' | 'disabled' | 'size'
      >,
    ];
    leadingIcon: [WithBoundArgs<typeof UiIcon, 'type'>];
    input: [
      WithBoundArgs<
        typeof UiInput,
        | 'disabled'
        | 'errors'
        | 'hasErrors'
        | 'id'
        | 'isInGroup'
        | 'leadingIcon'
        | 'trailingIcon'
      >,
    ];
    trailingButton: [
      WithBoundArgs<
        typeof UiButton,
        'buttonGroupPosition' | 'disabled' | 'size'
      >,
    ];
    trailingIcon: [WithBoundArgs<typeof UiIcon, 'type'>];
  };
}

const stateClasses: Record<InputState, string> = {
  default: 'bg-white ring-gray-300 focus-within:ring-purple-500',
  disabled: 'bg-gray-200 ring-gray-300 cursor-not-allowed',
  error: 'bg-white ring-red-400 focus-within:ring-red-500',
};

export default class UiInputGroupComponent extends Component<UiInputGroupSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get stateClasses() {
    let state: InputState = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  <template>
    <div
      data-test-id='input-group'
      class='flex w-full rounded-md shadow-sm'
      ...attributes
    >
      {{#if (has-block 'leadingButton')}}
        {{yield
          (component
            UiButton
            buttonGroupPosition='leading'
            disabled=@disabled
            size='small'
          )
          to='leadingButton'
        }}
      {{/if}}

      <div
        class='flex w-full items-center px-3 ring-1 ring-inset transition focus-within:ring-2 focus-within:ring-inset
          {{this.stateClasses}}
          {{if
            (has-block "leadingButton")
            "-ml-px rounded-l-none focus-within:relative"
            "rounded-l-md"
          }}
          {{if
            (has-block "trailingButton")
            "-mr-px rounded-r-none focus-within:relative"
            "rounded-r-md"
          }}'
      >
        {{#if (has-block 'leadingIcon')}}
          <InputIcon>
            {{yield (component UiIcon type='mini') to='leadingIcon'}}
          </InputIcon>
        {{/if}}

        {{#if (has-block 'input')}}
          {{yield
            (component
              UiInput
              disabled=@disabled
              errors=@errors
              hasErrors=@hasErrors
              id=@id
              isInGroup=true
              leadingIcon=(has-block 'leadingIcon')
              trailingIcon=(has-block 'trailingIcon')
            )
            to='input'
          }}
        {{/if}}

        {{#if (has-block 'trailingIcon')}}
          <InputIcon>
            {{yield (component UiIcon type='mini') to='trailingIcon'}}
          </InputIcon>
        {{/if}}
      </div>

      {{#if (has-block 'trailingButton')}}
        {{yield
          (component
            UiButton
            buttonGroupPosition='trailing'
            disabled=@disabled
            size='small'
          )
          to='trailingButton'
        }}
      {{/if}}
    </div>
  </template>
}
