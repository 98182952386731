import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';

export interface UiHeadlessComboboxLabelSignature {
  Element: HTMLLabelElement;
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends UiHeadlessComboboxLabelSignature {
  Args: {
    guid: string;
    handleLabelClick: (event: MouseEvent) => void;
    registerLabelElement: (element: HTMLLabelElement) => void;
  };
}

export default class UiHeadlessComboboxLabelComponent extends Component<InternalSignature> {
  registerLabelElement = modifier((element: HTMLLabelElement) => {
    this.args.registerLabelElement(element);
  });

  <template>
    <label
      id='{{@guid}}-label'
      for='{{@guid}}-button'
      {{on 'click' @handleLabelClick}}
      {{this.registerLabelElement}}
      ...attributes
    >
      {{yield}}
    </label>
  </template>
}
