import { template as template_21d6ac60c5c24f16a2544582bbacbbba } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type ArrayProxy from '@ember/array/proxy';
import set from 'ember-set-helper/helpers/set';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import { notEq } from 'ember-truth-helpers';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import type VectorcareServiceLevel from 'onward-portal/models/vectorcare-service-level';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiConfirmation from '../ui-confirmation';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiMenu from '../ui-menu';
import UiTable from '../ui-table';
import ServiceLevelForm from './service-level-form';
export interface ServiceLevelsGridSignature {
    Element: HTMLDivElement;
    Args: {
        serviceLevels: ArrayProxy<VectorcareServiceLevel>;
    };
    Blocks: EmptyObject;
}
export default class ServiceLevelsGridComponent extends Component<ServiceLevelsGridSignature> {
    @service
    store: Store;
    @tracked
    deleteErrorMessage: string | null = null;
    @tracked
    levelToDelete: VectorcareServiceLevel | null = null;
    @tracked
    levelToEdit: VectorcareServiceLevel | null = null;
    deleteServiceLevel = restartableTask(async ()=>{
        this.deleteErrorMessage = null;
        if (this.levelToDelete === null) {
            return;
        }
        try {
            await this.levelToDelete.destroyRecord();
        } catch (ex) {
            this.levelToDelete.rollbackAttributes();
            this.deleteErrorMessage = extractErrorMessage(ex, 'Something went wrong trying to delete the service level.');
        }
        this.levelToDelete = null;
    });
    @action
    async cancel() {
        this.levelToEdit = null;
    }
    @action
    newServiceLevel() {
        this.levelToEdit = this.store.createRecord('vectorcare-service-level');
    }
    static{
        template_21d6ac60c5c24f16a2544582bbacbbba(`
    <div ...attributes>
      {{#if this.deleteErrorMessage}}
        <UiAlert data-test-id='error-message' @variant='danger' class='mb-4'>
          {{this.deleteErrorMessage}}
        </UiAlert>
      {{/if}}

      <div class='flex justify-end'>
        <UiButton
          data-test-id='add-level-btn'
          @icon='plus-circle'
          @onClick={{this.newServiceLevel}}
        >
          Add Service Level
        </UiButton>
      </div>

      <UiTable class='mt-4' as |Table|>
        <Table.Head as |Head|>
          <Head.Th>
            Service Level
          </Head.Th>
          <Head.Th class='w-24'>
            Enabled
          </Head.Th>
          <Head.Th class='w-24'>
            <span class='sr-only'>Actions</span>
          </Head.Th>
        </Table.Head>
        <Table.Body as |Body|>
          {{#each (sortBy 'name' @serviceLevels) as |level|}}
            <Body.Tr data-test-id='level-row' as |Row|>
              <Row.Td data-test-id='name' @accent={{true}}>
                {{level.name}}
              </Row.Td>
              <Row.Td data-test-id='enabled'>
                {{if level.enabled 'Yes' 'No'}}
              </Row.Td>
              <Row.Td @align='right'>
                <div class='flex justify-end'>
                  <UiMenu as |menu|>
                    <menu.Trigger
                      data-test-id='grid-actions'
                      class='-ml-px flex h-6 rounded-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-500'
                    >
                      <UiIcon @icon='ellipsis-horizontal' @type='micro' />
                      <UiIcon @icon='chevron-down' @type='micro' />
                      <span class='sr-only'>Open menu</span>
                    </menu.Trigger>

                    <menu.Button
                      data-test-id='edit-btn'
                      @onClick={{set this 'levelToEdit' level}}
                    >
                      Edit
                    </menu.Button>
                    <menu.Button
                      data-test-id='delete-btn'
                      @onClick={{set this 'levelToDelete' level}}
                    >
                      Delete
                    </menu.Button>
                  </UiMenu>
                </div>
              </Row.Td>
            </Body.Tr>
          {{else}}
            <Body.Empty>
              There are no service levels.
            </Body.Empty>
          {{/each}}
        </Table.Body>
      </UiTable>

      <ServiceLevelForm
        @serviceLevel={{this.levelToEdit}}
        @onCancel={{this.cancel}}
        @onSave={{this.cancel}}
      />

      <UiConfirmation
        @isOpen={{notEq this.levelToDelete null}}
        @onCancel={{set this 'levelToDelete' null}}
        @onConfirm={{perform this.deleteServiceLevel}}
      >
        <:title>Delete Service Level?</:title>
        <:body>
          <p>
            Do you really want to delete the service level "{{this.levelToDelete.name}}"?
          </p>
        </:body>
      </UiConfirmation>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Vectorcare::ServiceLevelsGrid': typeof ServiceLevelsGridComponent;
    }
}
