import { template as template_d86140deabec457982224a972e1a6fd0 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import perform from 'ember-concurrency/helpers/perform';
import { findRecord, query } from 'ember-data-resources';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import DriverModel from '../../models/driver';
import type { PageMeta } from '../ui-pagination';
import { formatNumber } from '../../utils/number-format';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
export interface CustodianFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        onChange: (custodianId: string | null) => void;
        selectedCustodianId: string | null;
    };
    Blocks: EmptyObject;
}
export default class CustodianFilterComponent extends Component<CustodianFilterSignature> {
    @tracked
    term = '';
    get queryParams() {
        const search = this.term ?? undefined;
        return {
            filter: search ? {
                display_name: {
                    match: search
                }
            } : undefined,
            page: {
                size: PAGE_SIZE
            }
        };
    }
    custodian = findRecord<DriverModel>(this, 'user', ()=>this.args.selectedCustodianId);
    custodians = query<DriverModel>(this, 'user', ()=>({
            ...this.queryParams
        }));
    get loading() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.custodians.hasRan === false;
        return this.custodians.isLoading || waitForFirstFetch;
    }
    get moreCustodiansCount() {
        // @ts-expect-error - We need to figure out how to type this out.
        const meta = this.custodians.records.meta as PageMeta | undefined;
        const { perPage, totalCount, totalPages } = meta || {};
        if (totalPages && totalPages > 1) {
            const remaining = (totalCount ?? 0) - (perPage ?? 0);
            return remaining > 0 ? formatNumber(remaining) : null;
        }
        return null;
    }
    filterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.term) {
            return;
        }
        this.term = term;
    });
    static{
        template_d86140deabec457982224a972e1a6fd0(`
    <@form.Group data-test-id='custodian' as |Group|>
      <Group.Label>Coordinator</Group.Label>
      <Group.Combobox
        @searchPlaceholder='Search for a Coordinator...'
        @value={{@selectedCustodianId}}
        @allowClear={{true}}
        @onChange={{@onChange}}
        @onFilterChange={{perform this.filterChanged}}
        as |combobox|
      >
        <combobox.Button @placeholder='All Coordinators'>
          {{this.custodian.record.displayName}}
        </combobox.Button>

        {{#if this.loading}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each
            (sortBy 'displayName' this.custodians.records)
            as |custodian|
          }}
            <combobox.Option @value={{custodian.id}}>
              {{custodian.displayName}}
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No coordinators found.
            </combobox.Empty>
          {{/each}}

          {{#if this.moreCustodiansCount}}
            <combobox.Option @disabled={{true}} @value=''>
              {{this.moreCustodiansCount}}
              more available.
            </combobox.Option>
          {{/if}}
        {{/if}}
      </Group.Combobox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::CustodianFilter': typeof CustodianFilterComponent;
    }
}
