import Component from '@glimmer/component';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';

export interface UiHeadlessComboboxInputSignature {
  Element: HTMLInputElement;
  Args: {
    onChange?: (event: Event) => void;
  };
}

interface InternalSignature extends UiHeadlessComboboxInputSignature {
  Args: {
    guid: string;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    name?: string;
    onChange: (event: Event) => void;
    registerInputElement: (
      inputComponent: UiHeadlessComboboxInputComponent,
      element: HTMLInputElement,
    ) => void;
  };
}

export default class UiHeadlessComboboxInputComponent extends Component<InternalSignature> {
  registerInput = modifier((element: HTMLInputElement) => {
    this.args.registerInputElement(this, element);
  });

  @action
  clearInput() {
    // @ts-expect-error TODO: Figure out how to type this.
    this.args.onChange?.({ target: { value: '' } });
  }

  <template>
    <input
      type='text'
      name={{if @name @name 'option'}}
      id='{{@guid}}-input'
      {{on 'keydown' @handleKeyDown}}
      {{on 'keyup' @handleKeyUp}}
      {{on 'keypress' @handleKeyPress}}
      {{on 'input' @onChange}}
      {{this.registerInput}}
      ...attributes
    />
  </template>
}
