import { template as template_f88021af51e04f538a7833be24bc8c4b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { notEq } from 'ember-truth-helpers';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import InfoButton from '../../../info-button';
import UiModal from '../../../ui-modal';
import { type RideLocation } from '../location';
const LocationPhoto: TOC<{
    Args: {
        location: RideLocation | null;
        onClose: () => void;
        typeName: string;
    };
}> = template_f88021af51e04f538a7833be24bc8c4b(`
  <UiModal @isOpen={{notEq @location null}} @onClose={{@onClose}} @size='xl'>
    <:title>
      {{@typeName}}
      Location Photo
    </:title>

    <:body as |Body|>
      <UiForm as |Form|>
        <Form.Group class='mx-auto'>
          <img
            data-test-id='location-photo'
            src={{@location.locationPhotoUrl}}
            alt=''
            class='max-h-[900px] object-contain'
          />
        </Form.Group>

        {{#if @location.instructions}}
          <Form.Group data-test-id='location-instructions' as |Group|>
            <Group.Label>
              {{@typeName}}
              Location Instructions

              <InfoButton class='relative top-0.5 text-purple-200'>
                Where specifically should the driver meet the rider?
              </InfoButton>
            </Group.Label>
            <Group.Textarea
              @value={{@location.instructions}}
              readonly='true'
              rows='2'
            />
          </Form.Group>
        {{/if}}

        <Form.Actions>
          <UiButton @onClick={{Body.close}} @full={{true}}>
            Close
          </UiButton>
        </Form.Actions>
      </UiForm>
    </:body>
  </UiModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LocationPhoto;
