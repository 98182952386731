import { template as template_783c1cff84314a73b08340be0f1b549b } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type SessionService from '../services/session';
import LocationItem from './ui-location-combobox/location-item';
const DEBOUNCE_MS = 400;
export interface Location {
    address: string;
    id?: string;
    latitude: number;
    longitude: number;
    name: string;
    type: string;
    zipcode: string;
}
interface GoogleLocation {
    description: string;
    place_id: string;
    reference: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    };
}
interface GoogleLocationDetails {
    address_components: [{
            long_name: string;
            short_name: string;
            types: string[];
        }];
    geometry: {
        location: {
            lat: () => number;
            lng: () => number;
        };
    };
    formatted_address: string;
    name: string;
}
interface LocationComboboxSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        isLoading?: boolean;
        label: string;
        location?: Location | null;
        onChange: (location: Location) => unknown;
        onFilterChange?: (term: string) => unknown;
        suggestedLocations?: Location[];
    };
    Blocks: EmptyObject;
}
export default class LocationCombobox extends Component<LocationComboboxSignature> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @service
    googleMapsApi: any;
    @service
    session: SessionService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @tracked
    autocompleteService: any | null = null;
    @tracked
    filter = '';
    @tracked
    autocompleteOptions: GoogleLocation[] = [];
    get locations() {
        const suggestedLocations = this.args.suggestedLocations ?? [];
        const autocomplete = this.autocompleteOptions.map((option)=>{
            return {
                address: option.description,
                id: option.reference,
                name: option.structured_formatting.main_text,
                type: 'google'
            };
        });
        return [
            ...suggestedLocations,
            ...autocomplete
        ];
    }
    get isLoading() {
        return this.args.isLoading === true || this.fetchGoogleLocations.isRunning;
    }
    filterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.filter) {
            return;
        }
        this.filter = term;
        if (term !== '') {
            this.fetchGoogleLocations.perform();
            this.args.onFilterChange?.(term);
        }
    });
    fetchGoogleLocations = restartableTask(async ()=>{
        if (!this.autocompleteService) {
            const api = await this.googleMapsApi.google;
            this.autocompleteService = new api.maps.places.AutocompleteService();
        }
        // TODO: Remove if Sohayla is fine with that or keep it and make it load
        // from the account. Will do this in another PR.
        // const centerLocation = new this.googleMapsApi.google.maps.LatLng(
        //   37.84073,
        //   -122.251691,
        // );
        // const radius = 160000;
        await this.autocompleteService.getPlacePredictions({
            input: this.filter,
            // locationBias: new this.googleMapsApi.google.maps.Circle({
            //   center: centerLocation,
            //   radius: radius,
            // }),
            components: 'country:us'
        }, (results: GoogleLocation[] | null)=>{
            this.autocompleteOptions = results ?? [];
        });
    });
    convertGoogleToLocation(option: GoogleLocationDetails) {
        let zipcode = null;
        const postalCodeComponent = option.address_components.find((c)=>{
            return c.types.includes('postal_code');
        });
        if (postalCodeComponent) {
            zipcode = postalCodeComponent.short_name;
        }
        return {
            name: option.name,
            address: option.formatted_address,
            latitude: option.geometry.location.lat(),
            longitude: option.geometry.location.lng(),
            type: 'google',
            zipcode
        } as Location;
    }
    @action
    handleLocationChange(location: Location) {
        if (location.type === 'google') {
            let places = new this.googleMapsApi.google.maps.places.PlacesService(document.createElement('div'));
            places.getDetails({
                reference: location.id
            }, (details: GoogleLocationDetails)=>{
                const updatedLocation = this.convertGoogleToLocation(details);
                this.args.onChange(updatedLocation);
            });
        } else {
            this.args.onChange(location);
        }
        this.autocompleteOptions = [];
    }
    static{
        template_783c1cff84314a73b08340be0f1b549b(`
    <@formGroup ...attributes as |Group|>
      <Group.Label>{{@label}}</Group.Label>
      <Group.Combobox
        data-test-id='location-combobox'
        @value={{@location}}
        @onChange={{this.handleLocationChange}}
        @onFilterChange={{perform this.filterChanged}}
        as |combobox|
      >
        <combobox.Button>
          <:placeholder>
            <span class='flex items-center gap-2'>
              <UiIcon @icon='map-pin' @type='mini' />
              <span>Search for location...</span>
            </span>
          </:placeholder>

          <:default>
            {{#if @location}}
              <LocationItem @location={{@location}} />
            {{/if}}
          </:default>
        </combobox.Button>

        {{#if this.isLoading}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each this.locations as |location|}}
            <combobox.Option @value={{location}} as |option|>
              <LocationItem
                @location={{location}}
                @isActive={{option.active}}
              />
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No locations found.
            </combobox.Empty>
          {{/each}}
        {{/if}}
      </Group.Combobox>
    </@formGroup>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
