import { template as template_8b9a9f4af70d41f5a46f8a1f44a8fa77 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { modifier } from 'ember-modifier';
import { dateTime } from '@onwardcare/core/utils/date-format';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import rideCompletionStatuses from '../../../utils/data/ride-completion-status';
import PageLoader from '../../page-loader';
import RideCompletionStatus from '../../ride-completion-status';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import type { MatchingRide } from 'onward-portal/models/vetride-trip';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiButtonSet from '@onwardcare/ui-components/components/ui-button-set';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiModal from '../../ui-modal';
interface LinkRideFormSignature {
    Element: HTMLDivElement;
    Args: {
        isOpen: boolean;
        onSaved: () => unknown;
        onCancel: () => unknown;
        rideRequest: VetrideTripModel;
    };
    Blocks: EmptyObject;
}
export default class LinkRideFormComponent extends Component<LinkRideFormSignature> {
    @tracked
    closeRide = false;
    @tracked
    errorMessage: string | null = null;
    @tracked
    matchingRides: MatchingRide[] = [];
    @tracked
    selectedRide: MatchingRide | null = null;
    get singleChoice() {
        return this.matchingRides.length === 1;
    }
    get terminalRideStatuses() {
        return rideCompletionStatuses.filter((status)=>status.state === 'historical');
    }
    fetchData = modifier(()=>{
        this.fetchMatchingRides.perform();
    });
    fetchMatchingRides = restartableTask(async ()=>{
        this.matchingRides = await this.args.rideRequest.matchingRides();
        if (this.matchingRides.length > 0 && this.matchingRides[0]) {
            this.rideSelected(this.matchingRides[0]);
        }
    });
    linkRide = restartableTask(async (close: () => unknown, event: SubmitEvent)=>{
        const rideRequest = this.args.rideRequest;
        async function closeConfirmation() {
            close();
            // The fade duration for the modal is 300.
            await timeout(350);
        }
        if (event.target instanceof HTMLFormElement) {
            const data = new FormData(event.target);
            const rideId = data.get('ride') as string;
            const shouldCloseRequest = (data.get('close-request') as string) === 'on';
            try {
                rideRequest.rideId = Number(rideId);
                if (shouldCloseRequest) {
                    rideRequest.requestStatus = 'closed';
                } else {
                    rideRequest.requestStatus = 'open';
                }
                await rideRequest.save();
                await closeConfirmation();
                this.args.onSaved?.();
            } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
                rideRequest.rollbackAttributes();
                this.errorMessage = extractErrorMessage(ex, 'Something went wrong trying to link the ride to the request.');
            }
        }
    });
    dateTime(date: Date | null) {
        return dateTime(date, {
            nullText: 'N/A'
        });
    }
    dateTimeString(dateString: string) {
        return dateTime(new Date(dateString));
    }
    @action
    isChecked(ride: MatchingRide) {
        if (this.selectedRide) {
            return this.selectedRide.id === ride.id;
        }
    }
    @action
    rideSelected(ride: MatchingRide) {
        this.selectedRide = ride;
        const isTerminalStatus = this.terminalRideStatuses.find((status)=>status.id === ride.ride_completion_status);
        this.closeRide = isTerminalStatus ? true : false;
    }
    static{
        template_8b9a9f4af70d41f5a46f8a1f44a8fa77(`
    <UiModal @isOpen={{@isOpen}} @onClose={{@onCancel}}>
      <:title>
        Link to Onward Ride
      </:title>

      <:body as |Body|>
        <UiForm
          class='pt-4'
          @onSubmit={{perform this.linkRide Body.close}}
          {{this.fetchData}}
          as |Form|
        >
          {{#if this.errorMessage}}
            <UiAlert data-test-id='error-message' @variant='danger'>
              {{this.errorMessage}}
            </UiAlert>
          {{/if}}

          <div>
            <div>
              Vetride Request Trip Details
            </div>

            <ul class='rounded bg-gray-100 p-4 text-xs'>
              <li>
                <span class='font-medium'>Trip Type:</span>
                {{@rideRequest.tripType}}
              </li>
              <li>
                <span class='font-medium'>Est Pickup:</span>
                {{this.dateTime @rideRequest.estimatedPickupTime}}
              </li>
              <li>
                <span class='font-medium'>Earliest Dep:</span>
                {{this.dateTime @rideRequest.earliestDepartureTime}}
              </li>
              <li>
                <span class='font-medium'>Latest Arr:</span>
                {{this.dateTime @rideRequest.latestArrivalTime}}
              </li>
              <li>
                <span class='font-medium'>Appt Time:</span>
                {{this.dateTime @rideRequest.appointmentTime}}
              </li>
              <li>
                <span class='font-medium'>Pickup:</span>
                {{@rideRequest.pickupAddress}}
              </li>
              <li>
                <span class='font-medium'>Dropoff:</span>
                {{@rideRequest.dropoffAddress}}
              </li>
            </ul>
          </div>

          {{#if this.fetchMatchingRides.isRunning}}
            <div class='h-44'>
              <PageLoader />
            </div>
          {{else}}
            <fieldset data-test-id='onward-ride' class='mt-4'>
              <legend>
                {{#if this.singleChoice}}
                  This ride was found that matches your request:
                {{else}}
                  Select a ride to link to this request:
                {{/if}}
              </legend>
              <div class='mt-2 space-y-5'>
                {{#each this.matchingRides as |ride|}}
                  <div class='relative flex items-start'>
                    <div class='flex h-6 items-center'>
                      <input
                        id='ride-{{ride.id}}'
                        aria-describedby='{{ride.id}}-description'
                        name='ride'
                        type='radio'
                        value={{ride.id}}
                        checked={{this.isChecked ride}}
                        class='h-4 w-4 border-gray-300 text-purple-500 focus:ring-purple-500
                          {{if this.singleChoice "hidden"}}'
                        {{on 'change' (fn this.rideSelected ride)}}
                      />
                    </div>
                    <div
                      class='{{if this.singleChoice "ml-0" "ml-3"}}
                        text-sm leading-6'
                    >
                      <label
                        for='ride-{{ride.id}}'
                        class='font-medium text-gray-900'
                      >
                        Ride ID
                        {{ride.id}}
                        <RideCompletionStatus
                          class='ml-1'
                          @size='small'
                          @status={{ride.ride_completion_status}}
                        />
                      </label>
                      <p
                        id='{{ride.id}}-description'
                        class='text-xs text-gray-500'
                      >
                        <ul>
                          <li>
                            <span class='font-medium'>Start Time:</span>
                            {{this.dateTimeString ride.requested_start_time}}
                          </li>
                          <li>
                            <span class='font-medium'>Pickup:</span>
                            {{ride.start_location}}
                          </li>
                          <li>
                            <span class='font-medium'>Dropoff:</span>
                            {{ride.end_location}}
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                {{/each}}
              </div>
            </fieldset>

            <Form.Group data-test-id='close-request' as |Group|>
              <Group.Checkbox @name='close-request' @checked={{this.closeRide}}>
                Close Request
              </Group.Checkbox>
            </Form.Group>

            <Form.Actions class='flex justify-end' as |Actions|>
              <UiButtonSet>
                <UiButton data-test-id='cancel-btn' @onClick={{Body.close}}>
                  Cancel
                </UiButton>
                <Actions.Submit>
                  Link
                </Actions.Submit>
              </UiButtonSet>
            </Form.Actions>
          {{/if}}
        </UiForm>
      </:body>
    </UiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::Request::LinkRideForm': typeof LinkRideFormComponent;
    }
}
