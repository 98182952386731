import { tracked } from '@glimmer/tracking';
import Modifier, { type PositionalArgs } from 'ember-modifier';
import type * as Highcharts from 'highcharts';
import { dropTask } from 'ember-concurrency';

export interface InitChartModifierSignature {
  Element: HTMLElement;
  Args: {
    Positional: [Highcharts.Options];
  };
}

export default class InitChartModifier extends Modifier<InitChartModifierSignature> {
  protected chart: Highcharts.Chart | undefined;
  @tracked highcharts: typeof Highcharts | undefined;

  modify(
    element: HTMLElement,
    [opts]: PositionalArgs<InitChartModifierSignature>,
  ) {
    if (this.chart) {
      this.chart.update(opts, true, true);
    } else if (this.highcharts) {
      this.chart = this.highcharts.chart(element, opts);
    } else {
      this.loadHighcharts.perform().catch(() => {
        // Handle error
      });
    }
  }

  loadHighcharts = dropTask(async () => {
    const highcharts = await import('highcharts');

    highcharts.setOptions({
      lang: {
        // Use a comma for the thousandths separator in the x/y axis.
        thousandsSep: ',',
      },
    });

    this.highcharts = highcharts;
  });
}
