import { template as template_d57d73b3c46a44afa88b4876df73e1c9 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { EmptyObject } from '@ember/component/helper';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type SessionService from '../../services/session';
interface HelpLinkSignature {
    Element: HTMLDivElement;
    Args: {
        icon: string;
        href: string;
    };
    Blocks: {
        default: [];
    };
}
const HelpLink: TOC<HelpLinkSignature> = template_d57d73b3c46a44afa88b4876df73e1c9(`
  <div class='flex items-center gap-3 text-sm font-semibold text-gray-700'>
    <div class='rounded-lg border border-gray-200 p-1'>
      <UiIcon @icon={{@icon}} @type='micro' class='text-gray-400' />
    </div>
    <UiBaseLink
      @href={{@href}}
      class='hover:underline focus:outline-purple-500'
    >
      {{yield}}
    </UiBaseLink>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface HelpContentSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class HelpContentComponent extends Component<HelpContentSignature> {
    @service
    router: RouterService;
    @service
    session: SessionService;
    static{
        template_d57d73b3c46a44afa88b4876df73e1c9(`
    <div class='flex flex-col gap-8'>
      <div class='flex flex-col gap-4'>
        <p class='text-xs font-semibold text-gray-400'>Need Help?</p>

        <HelpLink @icon='phone-arrow-up-right' @href='tel:+18007004797'>
          1 (800) 700-4797
        </HelpLink>

        <HelpLink @icon='envelope' @href='mailto:support@onwardrides.com'>
          support@onwardrides.com
        </HelpLink>

        <HelpLink
          @icon='question-mark-circle'
          @href='https://onwardrides.zendesk.com/hc/en-us/categories/8697550582935-Health-System-Facility-Partners'
        >
          Help Library
        </HelpLink>
      </div>

      <div class='flex gap-1 text-xs font-semibold text-gray-400'>
        <UiBaseLink
          @href='https://onwardrides.com/terms'
          class='hover:underline focus:outline-purple-500'
        >
          Terms of Service
        </UiBaseLink>
        |
        <UiBaseLink
          @href='https://onwardrides.com/privacy'
          class='hover:underline focus:outline-purple-500'
        >
          Privacy Policy
        </UiBaseLink>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::HelpContent': typeof HelpContentComponent;
    }
}
