import { template as template_4ff8d15a83074501b617158b3da3675f } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface AcceptanceMedianTimeSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const AcceptanceMedianTime: TOC<AcceptanceMedianTimeSignature> = template_4ff8d15a83074501b617158b3da3675f(`
  <BasicColumnChart
    @modelName='acceptance-median-time'
    @title='Median Time to Acceptance (Minutes)'
    @tooltipText='Median number of minutes it took from the time the trip was requested until the trip was claimed or accepted by a driver.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AcceptanceMedianTime;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::AcceptanceMedianTime': typeof AcceptanceMedianTime;
    }
}
