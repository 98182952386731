import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import { getHttpStatusCode } from '../utils/error-handlers';

export default class FeatureService extends Service {
  @service declare store: Store;

  @tracked _flags: string[] = [];

  /**
   * Loads the feature flags.
   */
  load = restartableTask(async () => {
    try {
      const flags = await this.store.findAll('feature-flag', {
        // Needed so that if you switch from unauthenticated to authenticated, we
        // don't used the cached flags.
        reload: true,
      });
      const flagsArray = flags.slice();

      if (flagsArray.length > 0 && flagsArray[0]) {
        this._flags = flagsArray[0].values;
      }
    } catch (ex) {
      if (getHttpStatusCode(ex) !== 401) {
        throw ex;
      }
    }
  });

  /**
   * Checks if a flag is enabled.
   *
   * @param flag The flag to check.
   * @returns Returns true if the flag is enabled, false otherwise.
   */
  isEnabled(flag: string) {
    return this._flags.includes(flag);
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:feature')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('feature') declare altName: FeatureService;`.
declare module '@ember/service' {
  interface Registry {
    feature: FeatureService;
  }
}
