import { template as template_d2b2a502317040958dc8dfa8a498dd85 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { query } from 'ember-data-resources';
import set from 'ember-set-helper/helpers/set';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import type AccountTransportType from '../../../../models/account-transport-type';
import { type RideLocation } from '../location';
import SuggestedLocationModel from '../../../../models/suggested-location';
import UiLocationCombobox, { type Location } from '../../../ui-location-combobox';
import { concat } from '@ember/helper';
import { action } from '@ember/object';
const PAGE_SIZE = 50;
function mapSuggestedLocationToRideLocation(location: SuggestedLocationModel) {
    return {
        address: location.address,
        instructions: location.startLocationInstructions,
        latitude: location.latitude,
        locationId: location.locationId,
        locationPhotoUrl: location.locationPhotoUrl,
        longitude: location.longitude,
        name: location.name,
        suggestedLocationId: location.id,
        type: location.type,
        zipcode: location.zipcode
    } as RideLocation;
}
interface LocationComboboxSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        location: RideLocation | null;
        onChange: (location: RideLocation) => void;
        riderId?: string;
        transportType: AccountTransportType;
        typeName: string;
    };
    Blocks: EmptyObject;
}
export default class LocationCombobox extends Component<LocationComboboxSignature> {
    @tracked
    filter = '';
    get isLoading() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.locationData.hasRan === false;
        return this.locationData.isLoading || waitForFirstFetch;
    }
    get selectedLocation() {
        const found = this.locations.find((location)=>'suggestedLocationId' in location && location.suggestedLocationId === this.args.location?.suggestedLocationId);
        return found ?? this.args.location;
    }
    get queryParams() {
        const query = this.filter !== '' ? this.filter : undefined;
        return {
            filter: {
                rider_id: this.args.riderId,
                transport_type: this.args.transportType.name,
                query
            },
            page: {
                size: PAGE_SIZE
            }
        };
    }
    locationData = query<SuggestedLocationModel>(this, 'suggested-location', ()=>({
            ...this.queryParams
        }));
    get locations() {
        const locations = this.locationData.records ?? [];
        return locations.slice();
    }
    @action
    handleOnChange(location: Location) {
        if (location instanceof SuggestedLocationModel) {
            this.args.onChange(mapSuggestedLocationToRideLocation(location));
        }
        this.args.onChange(location);
    }
    static{
        template_d2b2a502317040958dc8dfa8a498dd85(`
    <UiLocationCombobox
      @formGroup={{@formGroup}}
      @isLoading={{this.isLoading}}
      @label={{concat @typeName ' Location'}}
      @location={{@location}}
      @onChange={{this.handleOnChange}}
      @onFilterChange={{set this 'filter'}}
      @suggestedLocations={{this.locations}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
