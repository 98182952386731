import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { debounce, scheduleOnce } from '@ember/runloop';
import { TrackedSet } from 'tracked-built-ins';
import ButtonComponent from './ui-headless-listbox/-button.gts';
import OptionComponent from './ui-headless-listbox/-option.gts';
import OptionsComponent from './ui-headless-listbox/-options.gts';

const ACTIVATE_NONE = 0;
const ACTIVATE_FIRST = 1;
const ACTIVATE_LAST = 2;

const PREVENTED_KEYDOWN_EVENTS = new Set([
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'PageUp',
  'PageDown',
  'Home',
  'End',
]);

export interface UiHeadlessListboxSignature {
  Args: {
    disabled?: boolean;
    labelId?: string;
    isOpen?: boolean;
    multiple?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    default: [
      {
        isOpen: boolean;
        closeListbox: () => void;
        disabled: boolean;
        openListbox: () => void;
        Options: WithBoundArgs<
          typeof OptionsComponent,
          | 'activeOptionGuid'
          | 'guid'
          | 'handleClickOutside'
          | 'handleKeyDown'
          | 'handleKeyPress'
          | 'handleKeyUp'
          | 'isOpen'
          | 'labelId'
          | 'multiple'
          | 'registerOptionElement'
          | 'registerOptionsElement'
          | 'scrollIntoView'
          | 'selectedOptionGuids'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'selectedValue'
          | 'unregisterOptionsElement'
          | 'unsetActiveOption'
        >;
        Button: WithBoundArgs<
          typeof ButtonComponent,
          | 'guid'
          | 'isOpen'
          | 'labelId'
          | 'registerButtonElement'
          | 'unregisterButtonElement'
          | 'handleButtonClick'
          | 'handleKeyPress'
          | 'handleKeyDown'
          | 'handleKeyUp'
          | 'isDisabled'
        >;
      },
    ];
  };
}

export default class UiHeadlessListboxComponent extends Component<UiHeadlessListboxSignature> {
  @tracked activeOptionIndex: number | undefined = undefined;
  activateBehaviour = ACTIVATE_NONE;
  buttonElement: HTMLButtonElement | undefined = undefined;
  guid = `${guidFor(this)}-headlessui-listbox`;
  @tracked _isOpen = this.args.isOpen || false;
  labelElement: HTMLLabelElement | undefined = undefined;
  optionsElement: HTMLUListElement | undefined = undefined;
  optionElements: HTMLLIElement[] = [];
  optionComponents: OptionComponent[] = [];
  search = '';
  @tracked selectedOptionIndexes = new TrackedSet<number>();

  get activeOptionGuid() {
    return this.activeOptionIndex !== undefined
      ? this.optionElements[this.activeOptionIndex]?.id
      : undefined;
  }

  get isDisabled() {
    return !!this.args.disabled;
  }

  get selectedOptionGuids() {
    return Array.from(this.selectedOptionIndexes).map(
      (i) => this.optionElements[i]!.id,
    );
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(isOpen) {
    if (isOpen) {
      this.activeOptionIndex = undefined;
      this.selectedOptionIndexes.clear();
      this.optionElements = [];
      this._isOpen = true;
    } else {
      this._isOpen = false;
    }
  }

  @action
  closeListbox() {
    this.isOpen = false;
  }

  @action
  handleButtonClick(e: MouseEvent) {
    if (e.button !== 0) return;
    this.activateBehaviour = ACTIVATE_NONE;
    this.isOpen = !this.isOpen;
  }

  @action
  handleClickOutside(e: MouseEvent) {
    const isClickOutsideButton = !(e.srcElement as HTMLElement | null)?.closest(
      `#${this.buttonElement?.id}`,
    );
    if (isClickOutsideButton) {
      this.closeListbox();
    }
    return true;
  }

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (PREVENTED_KEYDOWN_EVENTS.has(event.key)) {
      event.preventDefault();
    }
  }

  @action
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      if (!this.isOpen) {
        this.activateBehaviour = ACTIVATE_FIRST;
        this.isOpen = true;
      } else {
        this.setNextOptionActive();
      }
    } else if (event.key === 'ArrowRight') {
      if (this.isOpen) {
        this.setNextOptionActive();
      }
    } else if (event.key === 'ArrowUp') {
      if (!this.isOpen) {
        this.activateBehaviour = ACTIVATE_LAST;
        this.isOpen = true;
      } else {
        this.setPreviousOptionActive();
      }
    } else if (event.key === 'ArrowLeft') {
      if (this.isOpen) {
        this.setPreviousOptionActive();
      }
    } else if (event.key === 'Home' || event.key === 'PageUp') {
      this.setFirstOptionActive();
    } else if (event.key === 'End' || event.key === 'PageDown') {
      this.setLastOptionActive();
    } else if (event.key === 'Escape') {
      this.isOpen = false;
    }
  }

  @action
  handleKeyPress(event: KeyboardEvent) {
    if (
      event.key === 'Enter' ||
      ((event.key === 'Space' || event.key === ' ') && this.search === '')
    ) {
      this.activateBehaviour = ACTIVATE_FIRST;
      if (this.isOpen) {
        if (event.target) {
          this.setSelectedOption(event.target as HTMLLIElement, event);
        }
        if (!this.args.multiple) {
          this.isOpen = false;
        }
      } else {
        this.isOpen = true;
      }
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key.length === 1) {
      this.addSearchCharacter(event.key);
    }
  }
  @action
  openListbox() {
    this.isOpen = true;
  }

  @action
  registerButtonElement(buttonElement: HTMLButtonElement) {
    this.buttonElement = buttonElement;
  }

  @action
  unregisterButtonElement() {
    this.buttonElement = undefined;
  }

  @action
  registerLabelElement(labelElement: HTMLLabelElement) {
    this.labelElement = labelElement;
  }

  @action
  registerOptionElement(
    optionComponent: OptionComponent,
    optionElement: HTMLLIElement,
  ) {
    this.optionElements.push(optionElement);

    // store the index at which the option appears in the list
    // so we can avoid a O(n) find operation later
    const index = this.optionElements.length - 1;
    optionComponent.index = index;
    optionElement.setAttribute('data-index', index.toString());

    this.optionComponents[index] = optionComponent;

    // eslint-disable-next-line ember/no-runloop, @typescript-eslint/unbound-method
    scheduleOnce('afterRender', this, this.setDefaultActiveOption);
  }

  setDefaultActiveOption() {
    const selectedIndexes = this.optionComponents
      .filter((o) => o.isSelected)
      .map((o) => o.index);

    this.selectedOptionIndexes = new TrackedSet(selectedIndexes);

    if (this.selectedOptionIndexes.size === 0) {
      switch (this.activateBehaviour) {
        case ACTIVATE_FIRST:
          this.setFirstOptionActive();
          break;
        case ACTIVATE_LAST:
          this.setLastOptionActive();
          break;
      }
    } else {
      this.activeOptionIndex = Math.min(...this.selectedOptionIndexes);
      const element = this.optionElements[this.activeOptionIndex];
      if (element) {
        this.scrollIntoView(element);
      }
    }
  }

  @action
  registerOptionsElement(optionsElement: HTMLUListElement) {
    this.optionsElement = optionsElement;
  }

  @action
  unregisterOptionsElement() {
    this.optionsElement = undefined;
  }

  @action
  setActiveOption(optionComponent: OptionComponent) {
    this.optionElements.forEach((o, i) => {
      if (o.id === optionComponent.guid && !o.hasAttribute('disabled')) {
        this.activeOptionIndex = i;
        // For some reason, eslint thinks that we don't need to assert the type
        // here, but we do. If we don't assert it, it will be a normal `Element`
        // type, which doesn't have the `focus` method.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const element = document.querySelector(
          '#' + optionComponent.guid,
        ) as HTMLLIElement | null;
        element?.focus();
      }
    });
  }

  @action
  setSelectedOption(
    optionComponent: OptionComponent | HTMLLIElement,
    e: Event,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let optionIndex, optionValue: any;

    if (optionComponent instanceof OptionComponent) {
      optionValue = optionComponent.args.value;
      optionIndex = optionComponent.index;
    } else if (this.activeOptionIndex !== undefined) {
      optionValue = this.optionComponents[this.activeOptionIndex]?.args.value;
      const elementIndex =
        this.optionElements[this.activeOptionIndex]?.getAttribute('data-index');
      optionIndex = elementIndex ? parseInt(elementIndex) : -1;
    } else {
      return;
    }

    if (!this.optionElements[optionIndex]?.hasAttribute('disabled')) {
      if (this.args.multiple) {
        const value = this.args.value ?? [];

        if (this.selectedOptionIndexes.has(optionIndex)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          optionValue = value.filter((i: any) => i !== optionValue);
          this.selectedOptionIndexes.delete(optionIndex);
        } else {
          optionValue = [...value, optionValue];
          this.selectedOptionIndexes.add(optionIndex);
        }
      } else {
        this.selectedOptionIndexes.add(optionIndex);
      }

      this.args.onChange?.(optionValue);

      if (e.type === 'click' && !this.args.multiple) {
        this.isOpen = false;
      }
    } else {
      this.optionsElement?.focus();
    }
  }

  scrollIntoView(optionElement: HTMLLIElement) {
    // Cannot use optionElement.scrollIntoView() here because that function
    // also scrolls the *window* by some amount. Here, we don't want to
    // jerk the window, we just want to make the the option element visible
    // inside its container.

    optionElement.parentElement?.scroll(
      0,
      optionElement.offsetTop - optionElement.parentElement.offsetTop,
    );
  }

  @action
  unsetActiveOption() {
    this.activeOptionIndex = undefined;
  }

  setNextOptionActive() {
    if (this.activeOptionIndex === undefined) {
      return;
    }

    for (
      let i = this.activeOptionIndex + 1;
      i < this.optionElements.length;
      i++
    ) {
      if (!this.optionElements[i]?.hasAttribute('disabled')) {
        this.activeOptionIndex = i;
        break;
      }
    }
  }

  setPreviousOptionActive() {
    if (this.activeOptionIndex === undefined) {
      return;
    }

    for (let i = this.activeOptionIndex - 1; i >= 0; i--) {
      if (!this.optionElements[i]?.hasAttribute('disabled')) {
        this.activeOptionIndex = i;
        break;
      }
    }
  }

  setFirstOptionActive() {
    for (let i = 0; i < this.optionElements.length; i++) {
      if (!this.optionElements[i]?.hasAttribute('disabled')) {
        this.activeOptionIndex = i;
        break;
      }
    }
  }

  setLastOptionActive() {
    for (let i = this.optionElements.length - 1; i >= 0; i--) {
      if (!this.optionElements[i]?.hasAttribute('disabled')) {
        this.activeOptionIndex = i;
        break;
      }
    }
  }

  @action
  clearSearch() {
    this.search = '';
  }

  addSearchCharacter(key: string) {
    // eslint-disable-next-line ember/no-runloop, @typescript-eslint/unbound-method
    debounce(this, this.clearSearch, 500);

    this.search += key.toLowerCase();

    for (let i = 0; i < this.optionElements.length; i++) {
      const optionElement = this.optionElements[i];

      if (
        !optionElement?.hasAttribute('disabled') &&
        optionElement?.textContent?.trim().toLowerCase().startsWith(this.search)
      ) {
        this.scrollIntoView(optionElement);

        this.activeOptionIndex = i;
        break;
      }
    }
  }

  <template>
    {{yield
      (hash
        isOpen=this.isOpen
        disabled=this.isDisabled
        openListbox=this.openListbox
        closeListbox=this.closeListbox
        Options=(component
          OptionsComponent
          activeOptionGuid=this.activeOptionGuid
          guid=this.guid
          handleClickOutside=this.handleClickOutside
          handleKeyDown=this.handleKeyDown
          handleKeyPress=this.handleKeyPress
          handleKeyUp=this.handleKeyUp
          isOpen=this.isOpen
          labelId=@labelId
          multiple=@multiple
          registerOptionElement=this.registerOptionElement
          registerOptionsElement=this.registerOptionsElement
          scrollIntoView=this.scrollIntoView
          selectedOptionGuids=this.selectedOptionGuids
          selectedValue=@value
          setActiveOption=this.setActiveOption
          setSelectedOption=this.setSelectedOption
          unregisterOptionsElement=this.unregisterOptionsElement
          unsetActiveOption=this.unsetActiveOption
        )
        Button=(component
          ButtonComponent
          guid=this.guid
          isOpen=this.isOpen
          labelId=@labelId
          registerButtonElement=this.registerButtonElement
          unregisterButtonElement=this.unregisterButtonElement
          handleButtonClick=this.handleButtonClick
          handleKeyPress=this.handleKeyPress
          handleKeyDown=this.handleKeyDown
          handleKeyUp=this.handleKeyUp
          isDisabled=this.isDisabled
        )
      )
    }}
  </template>
}
