import { template as template_090e73dd12f3467787d8c207f66eba23 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiLocationCombobox, { type Location } from '../../../ui-location-combobox';
export class PayerContact {
    @tracked
    firstName: string | null = null;
    @tracked
    lastName: string | null = null;
    @tracked
    address: Location | null = null;
    @tracked
    email: string | null = null;
    @tracked
    phone: string | null = null;
    constructor(initialState?: PayerContact){
        if (initialState) {
            this.firstName = initialState.firstName;
            this.lastName = initialState.lastName;
            this.address = initialState.address;
            this.email = initialState.email;
            this.phone = initialState.phone;
        }
    }
    reset() {
        this.firstName = null;
        this.lastName = null;
        this.address = null;
        this.email = null;
        this.phone = null;
    }
}
interface PayerContactDetailsSignature {
    Args: {
        formGroup: typeof UiFormGroup;
        onChange: (payerContact: PayerContact) => void;
        payerContact?: PayerContact;
        riderId?: string;
    };
}
export default class PayerContactDetailsComponent extends Component<PayerContactDetailsSignature> {
    @action
    handleFirstNameChange(value: string) {
        const payerContact = new PayerContact(this.args.payerContact);
        payerContact.firstName = value;
        this.args.onChange(payerContact);
    }
    @action
    handleLastNameChange(value: string) {
        const payerContact = new PayerContact(this.args.payerContact);
        payerContact.lastName = value;
        this.args.onChange(payerContact);
    }
    @action
    handleAddressChange(location: Location) {
        const payerContact = new PayerContact(this.args.payerContact);
        payerContact.address = location;
        this.args.onChange(payerContact);
    }
    @action
    handleEmailChange(value: string) {
        const payerContact = new PayerContact(this.args.payerContact);
        payerContact.email = value;
        this.args.onChange(payerContact);
    }
    @action
    handlePhoneChange(value: string) {
        const payerContact = new PayerContact(this.args.payerContact);
        payerContact.phone = value;
        this.args.onChange(payerContact);
    }
    static{
        template_090e73dd12f3467787d8c207f66eba23(`
    <div data-test-id='payer-contact-details' class='flex flex-col gap-2'>
      <div class='flex flex-col gap-2 sm:flex-row'>
        <@formGroup data-test-id='first-name' class='w-full' as |Group|>
          <Group.Label>First Name</Group.Label>
          <Group.Input
            @value={{@payerContact.firstName}}
            @onChange={{this.handleFirstNameChange}}
          />
        </@formGroup>

        <@formGroup data-test-id='last-name' class='w-full' as |Group|>
          <Group.Label>Last Name</Group.Label>
          <Group.Input
            @value={{@payerContact.lastName}}
            @onChange={{this.handleLastNameChange}}
          />
        </@formGroup>
      </div>

      <UiLocationCombobox
        data-test-id='address'
        @formGroup={{@formGroup}}
        @label='Address'
        @location={{@payerContact.address}}
        @onChange={{this.handleAddressChange}}
      />

      <@formGroup data-test-id='email' as |Group|>
        <Group.Label>Email</Group.Label>
        <Group.InputGroup>
          <:leadingIcon as |Icon|>
            <Icon @icon='envelope' />
          </:leadingIcon>

          <:input as |I|>
            <I
              type='email'
              @value={{@payerContact.email}}
              @onChange={{this.handleEmailChange}}
            />
          </:input>
        </Group.InputGroup>
      </@formGroup>

      <@formGroup data-test-id='phone' as |Group|>
        <Group.Label>Phone</Group.Label>
        <Group.InputGroup>
          <:leadingIcon as |Icon|>
            <Icon @icon='phone' />
          </:leadingIcon>

          <:input as |I|>
            <I
              type='tel'
              @value={{@payerContact.phone}}
              @onChange={{this.handlePhoneChange}}
            />
          </:input>
        </Group.InputGroup>
      </@formGroup>

      <div
        class='flex items-start gap-2 rounded bg-purple-100 p-4 text-sm text-purple-700'
      >
        <span>
          <UiIcon @icon='document-arrow-up' @type='solid' />
        </span>
        <p>
          A payment link will be sent to this private payer contact to allow
          them to pay for this ride.
        </p>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::Payment::PayerContactDetails': typeof PayerContactDetailsComponent;
    }
}
