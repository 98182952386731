import type { TOC } from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';
import type { UiHeadlessListboxGroupTitleSignature } from '../ui-headless-listbox/-group-title.gts';

export interface UiListboxGroupTitleSignature {
  Element: UiHeadlessListboxGroupTitleSignature['Element'];
  Blocks: {
    default: [];
  };
}

interface InternalSignature extends UiListboxGroupTitleSignature {
  Args: {
    titleComponent: ComponentLike<UiHeadlessListboxGroupTitleSignature>;
  };
}

const UiListboxGroupTitleComponent: TOC<InternalSignature> = <template>
  <@titleComponent
    data-test-id='listbox-group-title'
    class='outline-none'
    ...attributes
  >
    <span class='relative block py-2 pl-3 pr-9 font-semibold text-gray-900'>
      <span class='block truncate'>
        {{yield}}
      </span>
    </span>
  </@titleComponent>
</template>;

export default UiListboxGroupTitleComponent;
