import { template as template_8392f60365ee4214952a65c2a54a6f5d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { restartableTask, timeout } from 'ember-concurrency';
import { modifier } from 'ember-modifier';
import { action } from '@ember/object';
import { and, eq } from 'ember-truth-helpers';
// @ts-expect-error need to add types
import StripeCard from '@adopted-ember-addons/ember-stripe-elements/components/stripe-card';
import { type StripeCardElement } from '@stripe/stripe-js';
import type SetupIntentModel from '../models/setup-intent';
import { captureException } from '../utils/error-logging';
import UiValidationErrors, { type ValidationError } from '@onwardcare/ui-components/components/ui-validation-errors';
interface UiStripeCardSignature {
    Element: HTMLFormElement;
    Args: {
        ownerId?: string;
        ownerType: string;
        onSubmit: () => unknown;
    };
}
/**
 * Stripe styles to use for the card element.
 *
 * See: https://docs.stripe.com/js/appendix/style
 */ let style = {
    style: {
        base: {
            color: '#111827',
            fontFamily: '"Inter var", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSize: '16px',
            lineHeight: '24px',
            '::placeholder': {
                color: '#9ca3af'
            }
        },
        invalid: {
            color: '#ef4444',
            iconColor: '#ef4444'
        }
    }
};
export default class UiStripeCard extends Component<UiStripeCardSignature> {
    @service
    store: Store;
    // @ts-expect-error need to add types
    @service('stripev3')
    stripe;
    @tracked
    errors: ValidationError[] = [];
    @tracked
    setupIntent: SetupIntentModel | null = null;
    @tracked
    _showAddButton = false;
    @tracked
    _stripeElement: StripeCardElement | null = null;
    get cardOptions() {
        return {
            clientSecret: this.setupIntent?.setupIntent,
            hidePostalCode: true,
            ...style
        };
    }
    initStripe = modifier(()=>{
        // Ensure that if the Stripe element is already rendered, that we enable it.
        if (this._stripeElement) {
            this._stripeElement.update({
                disabled: false
            });
        }
        this.setupStripe.perform();
    });
    setupStripe = restartableTask(async ()=>{
        try {
            this.setupIntent = await this.store.queryRecord('setup-intent', {
                owner_type: this.args.ownerType,
                owner_id: this.args.ownerId
            });
            await this.stripe.load(this.setupIntent.publishableKey);
        } catch (ex) {
            this.errors = [
                {
                    message: 'Error loading. Try another payment method or contact Onward support.'
                }
            ];
            captureException(ex);
            // Re-throw the error to ensure the task is marked as errored.
            throw ex;
        }
    });
    submitCard = restartableTask(async (stripeElement: StripeCardElement)=>{
        try {
            this._stripeElement = stripeElement;
            this.errors = [];
            stripeElement.update({
                disabled: true
            });
            const result = await this.stripe.confirmCardSetup(this.setupIntent?.setupIntent, {
                payment_method: {
                    card: stripeElement
                }
            });
            if (result.error) {
                this.errors = [
                    {
                        message: result.error.message
                    }
                ];
                stripeElement.update({
                    disabled: false
                });
            } else {
                // wait for a second to ensure the Stripe API hook has finished
                await timeout(1000);
                this.args.onSubmit?.();
            }
        } catch (ex) {
            this.errors = [
                {
                    message: 'Something went wrong trying to add the card.'
                }
            ];
            stripeElement.update({
                disabled: false
            });
            captureException(ex);
        }
    });
    @action
    handleChange(stripeElement: StripeCardElement, details: {
        brand: string;
        complete: boolean;
        empty: boolean;
        error: {
            code: string;
            message: string;
            type: string;
        } | undefined;
    }) {
        if (details.complete) {
            this.submitCard.perform(stripeElement);
            return;
        }
        if (details.error) {
            this.errors = [
                {
                    message: details.error.message
                }
            ];
        } else {
            this.errors = [];
        }
    }
    static{
        template_8392f60365ee4214952a65c2a54a6f5d(`
    <div
      data-test-id='stripe-card'
      class={{if
        (and this.submitCard.last.isSuccessful (eq this.errors.length 0))
        'stripe-success'
      }}
      {{this.initStripe}}
    >
      {{#if this.setupStripe.lastSuccessful}}
        <StripeCard
          @autofocus={{true}}
          @onChange={{this.handleChange}}
          @options={{this.cardOptions}}
        >
          <UiValidationErrors class='mt-2' @errors={{this.errors}} />
        </StripeCard>
      {{else if this.errors}}
        <UiValidationErrors @errors={{this.errors}} />
      {{else}}
        <p class='text-sm text-gray-500'>Loading...</p>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
