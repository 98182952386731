import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type ArrayProxy from '@ember/array/proxy';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type SessionService from '../../services/session';
import type VectorcareServiceLevelModel from '../../models/vectorcare-service-level';
import type VectorcareZoneModel from '../../models/vectorcare-zone';
import { captureMessage } from '../../utils/error-logging';

type VectorcareIndexModel = {
  serviceLevels: ArrayProxy<VectorcareServiceLevelModel>;
  zones: ArrayProxy<VectorcareZoneModel>;
};

export default class VectorcareRoute extends Route {
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare store: Store;

  async beforeModel(transition: Transition) {
    if (this.session.isOnwardAssociate === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }
  }

  async model(): Promise<VectorcareIndexModel> {
    const serviceLevels = await this.store.findAll('vectorcare-service-level');
    const zones = await this.store.findAll('vectorcare-zone');

    return {
      serviceLevels,
      zones,
    };
  }
}
