import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { isBlank } from '@ember/utils';
import applyId from '../modifiers/apply-id.ts';
// @ts-expect-error TODO: Figure out how to fix this.
import Transition from '@onwardcare/ember-headlessui/components/transition';
import UiHeadlessListbox from './ui-headless-listbox.gts';
import UiIcon from './ui-icon.ts';
import UiNestedListboxOptions from './ui-nested-listbox/options.gts';
import type { ValidationError } from './ui-validation-errors';
import { and, or } from 'ember-truth-helpers';

type State = 'default' | 'disabled' | 'error';

export interface UiNestedListboxSignature {
  Element: HTMLDivElement;
  Args: {
    disabled?: boolean;
    displayParam?: string;
    emptyMessage?: string;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    labelId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    placeholder?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
  };
  Blocks: {
    placeholder?: [];
  };
}

const baseClasses =
  'transition-[outline] grid w-full h-9 grid-cols-1 rounded-md shadow-sm py-1.5 pl-3 pr-2 text-left outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 sm:text-sm/6';
const stateClasses: Record<State, string> = {
  default: 'bg-white text-gray-900 outline-gray-300 focus:outline-purple-500',
  disabled:
    'cursor-not-allowed bg-gray-200 text-gray-600 outline-gray-300 focus:outline-purple-500',
  error: 'bg-white text-gray-900 outline-red-400 focus:outline-red-500',
};

export default class UiNestedListboxComponent extends Component<UiNestedListboxSignature> {
  @tracked expandedGroup: string | null = null;

  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  get groupForValue() {
    const { options, value } = this.args;

    return options.find((option) => {
      if (typeof option === 'string') {
        return option === value;
      }

      if ('options' in option) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return option.options.find((groupOption: any) => {
          return groupOption === value;
        });
      }
    });
  }

  get noSelectedValue() {
    return isBlank(this.args.value);
  }

  get display() {
    const { displayParam, value } = this.args;

    if (isBlank(value)) {
      return null;
    }

    let displayValue = '';

    if (displayParam) {
      displayValue = value[displayParam];
    } else {
      displayValue = value;
    }

    const groupName = this.groupForValue?.groupName;

    if (groupName) {
      return `${groupName} - ${displayValue}`;
    }

    return displayValue;
  }

  initSelectedGroup = modifier(() => {
    const selectedGroup = this.groupForValue;

    this.expandedGroup = selectedGroup ? selectedGroup.groupName : null;
  });

  <template>
    <UiHeadlessListbox
      @disabled={{@disabled}}
      @labelId={{@labelId}}
      @onChange={{@onChange}}
      @value={{@value}}
      as |listbox|
    >
      <div data-test-id='nested-listbox' class='relative' ...attributes>
        <listbox.Button
          data-test-id='nested-listbox-button'
          aria-invalid={{this.hasErrors}}
          class='{{this.baseClasses}} {{this.stateClasses}} pr-10'
          {{applyId @id}}
        >
          {{#let
            (and
              this.noSelectedValue (or (has-block 'placeholder') @placeholder)
            )
            as |showPlaceholder|
          }}
            <span
              class='col-start-1 row-start-1 truncate pr-6
                {{if showPlaceholder "text-gray-400"}}'
            >
              {{#if showPlaceholder}}
                <span class='truncate text-gray-400'>
                  {{#if (has-block 'placeholder')}}
                    {{yield to='placeholder'}}
                  {{else}}
                    {{@placeholder}}
                  {{/if}}
                </span>
              {{else}}
                {{this.display}}
              {{/if}}
            </span>
          {{/let}}

          <span class='col-start-1 row-start-1 self-center justify-self-end'>
            <UiIcon
              @icon='chevron-up-down'
              @type='mini'
              class='text-gray-400'
            />
          </span>
        </listbox.Button>

        <Transition
          @show={{listbox.isOpen}}
          @appear={{true}}
          @leave='transition ease-in duration-100'
          @leaveFrom='opacity-100'
          @leaveTo='opacity-0'
          class='absolute z-10 w-full'
        >
          <listbox.Options
            data-test-id='nested-listbox-options'
            class='mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            @isOpen={{true}}
            {{this.initSelectedGroup}}
            as |listboxOptions|
          >
            <UiNestedListboxOptions
              @displayParam={{@displayParam}}
              @emptyMessage={{@emptyMessage}}
              @expandedGroup={{this.expandedGroup}}
              {{! @glint-expect-error - Need to figure out how to fix the type. }}
              @listboxOptionsComponent={{listboxOptions}}
              @options={{@options}}
              @value={{@value}}
            />
          </listbox.Options>
        </Transition>
      </div>
    </UiHeadlessListbox>
  </template>
}
