import { template as template_a9faa796d1aa4beeaeaa4aff1f5b07e5 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
// This is the minimum interface that we need in this component. The actual
// argument for this is a ember-data-resource.
interface DataResource {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
export interface ChartContainerSignature {
    Element: HTMLDivElement;
    Args: {
        dataResource: DataResource;
        isEmpty?: boolean;
    };
    Blocks: {
        default: [];
    };
}
const ChartContainerComponent: TOC<ChartContainerSignature> = template_a9faa796d1aa4beeaeaa4aff1f5b07e5(`
  {{#if @dataResource.isLoading}}
    <div
      data-test-id='chart-loading'
      class='overflow-hidden rounded-md border border-gray-200 bg-white'
      ...attributes
    >
      <div class='h-full animate-pulse bg-gray-200'></div>
    </div>
  {{else if @dataResource.isError}}
    <div
      data-test-id='chart-error'
      class='flex items-center justify-center rounded-md border border-red-200 bg-red-100 text-red-950'
      ...attributes
    >
      <p class='text-lg'>Failed to load the chart.</p>
    </div>
  {{else if @dataResource.isSuccess}}
    {{#if @isEmpty}}
      <div
        data-test-id='chart-empty'
        class='flex items-center justify-center rounded-md border border-gray-300 bg-gray-200 text-gray-950'
        ...attributes
      >
        <p class='text-lg'>No data to display.</p>
      </div>
    {{else}}
      {{yield}}
    {{/if}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartContainerComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ChartContainer: typeof ChartContainerComponent;
    }
}
