import type { TOC } from '@ember/component/template-only';
import UiBaseLink from './ui-base-link.gts';
import type { UiBaseLinkArgs } from './ui-base-link.gts';

export interface UiLinkSignature {
  Element: HTMLAnchorElement;
  Args: UiBaseLinkArgs;
  Blocks: {
    default: [];
  };
}

const UiLinkComponent: TOC<UiLinkSignature> = <template>
  <UiBaseLink
    data-test-id='link'
    class='font-semibold leading-6 text-purple-500 transition hover:text-purple-700 hover:underline'
    @download={{@download}}
    @href={{@href}}
    @route={{@route}}
    @model={{@model}}
    @models={{@models}}
    @query={{@query}}
    ...attributes
  >{{yield}}</UiBaseLink>
</template>;

export default UiLinkComponent;
