import Component from '@glimmer/component';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { hash } from '@ember/helper';
import type Owner from '@ember/owner';
import { Keys } from '@onwardcare/ember-headlessui/utils/keyboard';
import ComboboxButton, {
  type UiHeadlessComboboxButtonSignature,
} from './ui-headless-combobox/-button.gts';
import ComboboxContent, {
  type UiHeadlessComboboxContentSignature,
} from './ui-headless-combobox/-content.gts';
import ComboboxInput from './ui-headless-combobox/-input.gts';
import ComboboxLabel, {
  type UiHeadlessComboboxLabelSignature,
} from './ui-headless-combobox/-label.gts';
import ComboboxOption from './ui-headless-combobox/-option.gts';

const ACTIVATE_NONE = 0;
const ACTIVATE_FIRST = 1;
const ACTIVATE_LAST = 2;
const ACTIVATE_SEARCH = 3;

const PREVENTED_KEYDOWN_EVENTS = new Set([
  'ArrowUp',
  'ArrowDown',
  'PageUp',
  'PageDown',
  'Home',
  'End',
]);

export interface UiHeadlessComboboxSignature {
  Args: {
    disabled?: boolean;
    isOpen?: boolean;
    labelId: string;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
  Blocks: {
    default: [
      {
        closeCombobox: () => void;
        isDisabled: boolean;
        isOpen: boolean;
        Button: ComponentLike<UiHeadlessComboboxButtonSignature>;
        Content: ComponentLike<UiHeadlessComboboxContentSignature>;
        Label: ComponentLike<UiHeadlessComboboxLabelSignature>;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessComboboxSignature {
  Args: {
    disabled?: boolean;
    isOpen?: boolean;
    labelId: string;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
  Blocks: {
    default: [
      {
        closeCombobox: () => void;
        isDisabled: boolean;
        isOpen: boolean;
        Button: WithBoundArgs<
          typeof ComboboxButton,
          | 'guid'
          | 'handleButtonClick'
          | 'handleKeyDown'
          | 'handleKeyPress'
          | 'handleKeyUp'
          | 'isDisabled'
          | 'isOpen'
          | 'labelId'
          | 'registerButtonElement'
        >;
        Content: WithBoundArgs<
          typeof ComboboxContent,
          | 'activeOptionGuid'
          | 'guid'
          | 'handleClickOutside'
          | 'handleKeyDown'
          | 'handleKeyPress'
          | 'handleKeyUp'
          | 'isOpen'
          | 'labelId'
          | 'name'
          | 'onChange'
          | 'optionsFallbackFocus'
          | 'optionsReturnFocus'
          | 'registerInputElement'
          | 'registerOptionElement'
          | 'registerOptionsElement'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unregisterOptionElement'
          | 'unsetActiveOption'
          | 'value'
        >;
        Label: WithBoundArgs<
          typeof ComboboxLabel,
          'guid' | 'handleLabelClick' | 'registerLabelElement'
        >;
      },
    ];
  };
}

interface OptionElement {
  id: string;
  element: HTMLElement;
}

export default class ComboboxComponent extends Component<InternalSignature> {
  @tracked _isOpen = this.args.isOpen || false;
  //TODO: `options` would be a better name as we store other stuff in it, too.
  @tracked optionElements: OptionElement[] = [];
  @tracked activateBehaviour = ACTIVATE_NONE;
  @tracked _activeOptionGuid: string | null = null;
  _originalValue;
  options: ComboboxOption[] = [];

  guid = `${guidFor(this)}-headlessui-combobox`;
  optionsElement: HTMLUListElement | null = null;
  buttonElement: HTMLButtonElement | null = null;
  labelElement: HTMLLabelElement | null = null;
  inputComponent: ComboboxInput | null = null;
  inputElement: HTMLInputElement | null = null;

  constructor(owner: Owner, args: InternalSignature['Args']) {
    super(owner, args);

    this._originalValue = this.args.value;
  }

  get firstSelectedOption() {
    return this.options?.find((o) => o.isSelectedOption);
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(isOpen) {
    if (this.isDisabled) return;

    this.inputComponent?.clearInput();

    if (!isOpen) {
      this._activeOptionGuid = null;
      this.optionElements = [];
    }

    if (isOpen) {
      this.inputElement?.focus();
      this._isOpen = true;
    } else {
      this._isOpen = false;
    }
  }

  get isDisabled() {
    return !!this.args.disabled;
  }

  get isMultiselectable() {
    return Array.isArray(this.args.value);
  }

  get activeOptionGuid() {
    if (this._activeOptionGuid) {
      return this._activeOptionGuid;
    }

    if (this.firstSelectedOption) {
      return this.firstSelectedOption.guid;
    }

    if (this.activateBehaviour === ACTIVATE_FIRST) {
      return this.firstNonDisabledOption?.id;
    }

    if (this.activateBehaviour === ACTIVATE_LAST) {
      return this.lastNonDisabledOption?.id;
    }

    return null;
  }

  get activeOption() {
    const activeGuid = this.activeOptionGuid;
    return this.optionElements.find((option) => option.id === activeGuid);
  }

  setActiveAsSelected() {
    const active = this.options?.find((o) => o.guid === this.activeOptionGuid);
    if (active) {
      active.callOnChangeWithSelectedValue();
    }
  }

  @action
  handleButtonClick(e: MouseEvent) {
    if (e.button !== 0) return;
    this.activateBehaviour = ACTIVATE_FIRST;

    if (!this.isOpen) {
      this.inputElement?.focus();
    }

    this.isOpen = !this.isOpen;
  }

  @action
  handleLabelClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.ctrlKey || e.button !== 0) return;
    this.inputElement?.focus();
  }

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === Keys.Tab) {
      this.closeCombobox();
      return;
    }

    if (PREVENTED_KEYDOWN_EVENTS.has(event.key)) {
      event.preventDefault();
    }
  }

  @action
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === Keys.ArrowDown) {
      if (!this.isOpen) {
        this.activateBehaviour = ACTIVATE_FIRST;
        this.isOpen = true;
      } else {
        this.setNextOptionActive();
      }
    } else if (event.key === Keys.ArrowUp) {
      if (!this.isOpen) {
        this.activateBehaviour = ACTIVATE_LAST;
        this.isOpen = true;
      } else {
        this.setPreviousOptionActive();
      }
    } else if (event.key === Keys.Home || event.key === Keys.PageUp) {
      this.setFirstOptionActive();
    } else if (event.key === Keys.End || event.key === Keys.PageDown) {
      this.setLastOptionActive();
    } else if (event.key === Keys.Escape) {
      this.isOpen = false;
    }
  }

  @action
  handleKeyPress(event: KeyboardEvent) {
    if (
      event.key === Keys.Enter ||
      ((event.key === 'Space' || event.key === Keys.Space) && !this.isOpen)
    ) {
      this.activateBehaviour = ACTIVATE_FIRST;
      if (this.isOpen) {
        this.setActiveAsSelected();
        this.setSelectedOption(event);
        this.isOpen = false;
      } else {
        this.isOpen = true;
        this.inputElement?.focus();
      }

      event.preventDefault();
      event.stopPropagation();
    } else {
      this.activateBehaviour = ACTIVATE_SEARCH;
    }
  }

  @action
  optionsFallbackFocus() {
    if (document.activeElement === this.inputElement) return this.inputElement;
    if (document.activeElement === this.buttonElement)
      return this.buttonElement;

    return this.inputElement;
  }

  @action
  optionsReturnFocus() {
    return this.buttonElement;
  }

  @action
  handleClickOutside(e: MouseEvent) {
    let hasClickedButtonElement = false;
    let hasClickedInputElement = false;

    if (
      e.composedPath().some((element) => {
        if (element === this.inputElement) {
          hasClickedInputElement = true;
          return false;
        } else if (element === this.buttonElement) {
          hasClickedButtonElement = true;
          return false;
        } else if (element === this.optionsElement) {
          return true;
        } else if (
          element instanceof HTMLElement &&
          this.optionElements
            .map(({ element }: OptionElement) => element)
            .includes(element)
        ) {
          return true;
        }
      })
    ) {
      return false;
    }

    if (hasClickedInputElement || hasClickedButtonElement) {
      return true;
    }

    this.closeCombobox();

    return true;
  }

  @action
  closeCombobox() {
    this.isOpen = false;
  }

  @action
  registerInputElement(
    inputComponent: ComboboxInput,
    inputElement: HTMLInputElement,
  ) {
    this.inputComponent = inputComponent;
    this.inputElement = inputElement;
  }

  @action
  registerLabelElement(labelElement: HTMLLabelElement) {
    this.labelElement = labelElement;
  }

  @action
  registerButtonElement(buttonElement: HTMLButtonElement) {
    this.buttonElement = buttonElement;
  }

  rebuildOptionElements(optionElement?: HTMLLIElement) {
    const queryElement = this.optionsElement || optionElement?.parentElement;
    // We should always have the `ul` element here, but this is is here just to
    // make TypeScript happy.
    if (!queryElement) {
      return;
    }

    const optionDOMElements = Array.from(
      queryElement.querySelectorAll('[role="option"]'),
    );

    const newElements: OptionElement[] = [];
    optionDOMElements.forEach((anOptionElement) => {
      const data = {
        id: anOptionElement.id,
        element: anOptionElement as HTMLElement,
      };

      newElements.push(data);
    });

    this.optionElements = newElements;
  }

  indexForOptionElement(optionElement: HTMLLIElement) {
    const optionElements =
      optionElement.parentElement?.querySelectorAll('[role="option"]');

    if (!optionElements) {
      return -1;
    }

    return Array.from(optionElements).findIndex(
      (anOptionElement) => anOptionElement.id === optionElement.id,
    );
  }

  @action
  registerOptionElement(
    optionComponent: ComboboxOption,
    optionElement: HTMLLIElement,
  ) {
    this.options.push(optionComponent);
    this.rebuildOptionElements(optionElement);
    const index = this.indexForOptionElement(optionElement);

    // store the index at which the option appears in the list
    // so we can avoid a O(n) find operation later
    optionElement.setAttribute('data-index', index.toString());
  }

  get firstNonDisabledOption() {
    return this.optionElements.find((optionElement) => {
      return !optionElement.element.hasAttribute('disabled');
    });
  }

  get lastNonDisabledOption() {
    return [...this.optionElements].reverse().find((optionElement) => {
      return !optionElement.element.hasAttribute('disabled');
    });
  }

  setNextOptionActive() {
    let match = false;

    const option = this.activeOption?.id
      ? this.optionElements.find((o) => {
          if (match && !o.element.hasAttribute('disabled')) {
            return true;
          } else if (o.id === this.activeOption?.id) {
            match = true;
          }
        })
      : this.firstNonDisabledOption;

    if (option) {
      this._activeOptionGuid = option.id;
    }
  }

  setPreviousOptionActive() {
    let match = false;
    const option = this.activeOption?.id
      ? [...this.optionElements].reverse().find((o) => {
          if (match && !o.element.hasAttribute('disabled')) {
            return true;
          } else if (o.id === this.activeOption?.id) {
            match = true;
          }
        })
      : this.lastNonDisabledOption;

    if (option) {
      this._activeOptionGuid = option.id;
    }
  }

  setFirstOptionActive() {
    const option = this.optionElements.find((o) => {
      if (!o.element.hasAttribute('disabled')) {
        return true;
      }
    });

    if (option) {
      this._activeOptionGuid = option.id;
    }
  }

  setLastOptionActive() {
    const option = [...this.optionElements].reverse().find((o) => {
      if (!o.element.hasAttribute('disabled')) {
        return true;
      }
    });

    if (option) {
      this._activeOptionGuid = option.id;
    }
  }

  @action
  registerOptionsElement(optionsElement: HTMLUListElement) {
    this.optionsElement = optionsElement;

    this.optionsElement
      .querySelectorAll(':scope > *:not([role="option"]')
      .forEach((element) => {
        element.setAttribute('role', 'none');
      });

    this.rebuildOptionElements();
  }

  @action
  setSelectedOption(e: Event) {
    let optionToCheck;
    const activeOption = this.activeOption;
    const firstOption = this.optionElements[0];

    if (activeOption) {
      optionToCheck = activeOption;
    } else if (firstOption) {
      optionToCheck = firstOption;
    } else {
      return;
    }

    if (optionToCheck?.element.hasAttribute('disabled')) {
      return;
    }

    if (e?.type === 'click') {
      if (!this.isMultiselectable) {
        this.closeCombobox();
      }
      this.inputElement?.focus();
    }
  }

  @action
  unregisterOptionElement(
    optionComponent: ComboboxOption,
    optionElement: HTMLLIElement,
  ) {
    const ix = this.options.indexOf(optionComponent);
    this.options.splice(ix, 1);

    // TODO: Need to figure out how to move away from ember runloop.
    // eslint-disable-next-line ember/no-runloop
    next(() => {
      this.optionElements = this.optionElements.filter((anOptionElement) => {
        return optionElement.id !== anOptionElement.id;
      });

      this.optionElements.forEach((optionElement, i) => {
        optionElement.element.setAttribute('data-index', i.toString());
      });

      this.rebuildOptionElements();
    });
  }

  @action
  setActiveOption({ guid }: ComboboxOption) {
    this.optionElements.forEach((optionElement) => {
      if (
        optionElement.id === guid &&
        !optionElement.element.hasAttribute('disabled')
      ) {
        optionElement.element.focus();
        this._activeOptionGuid = guid;
      }
    });
  }

  @action
  unsetActiveOption() {
    this._activeOptionGuid = null;
    this.activateBehaviour = ACTIVATE_NONE;
  }

  <template>
    {{yield
      (hash
        isOpen=this.isOpen
        isDisabled=this.isDisabled
        closeCombobox=this.closeCombobox
        Content=(component
          ComboboxContent
          activeOptionGuid=this.activeOptionGuid
          guid=this.guid
          handleClickOutside=this.handleClickOutside
          handleKeyDown=this.handleKeyDown
          handleKeyPress=this.handleKeyPress
          handleKeyUp=this.handleKeyUp
          isOpen=this.isOpen
          labelId=@labelId
          name=@name
          onChange=@onChange
          optionsFallbackFocus=this.optionsFallbackFocus
          optionsReturnFocus=this.optionsReturnFocus
          registerInputElement=this.registerInputElement
          registerOptionElement=this.registerOptionElement
          registerOptionsElement=this.registerOptionsElement
          selectedValue=@value
          setActiveOption=this.setActiveOption
          setSelectedOption=this.setSelectedOption
          unregisterOptionElement=this.unregisterOptionElement
          unsetActiveOption=this.unsetActiveOption
          value=@value
        )
        Button=(component
          ComboboxButton
          guid=this.guid
          handleButtonClick=this.handleButtonClick
          handleKeyDown=this.handleKeyDown
          handleKeyPress=this.handleKeyPress
          handleKeyUp=this.handleKeyUp
          isDisabled=this.isDisabled
          isOpen=this.isOpen
          labelId=@labelId
          registerButtonElement=this.registerButtonElement
        )
        Label=(component
          ComboboxLabel
          guid=this.guid
          registerLabelElement=this.registerLabelElement
          handleLabelClick=this.handleLabelClick
        )
      )
    }}
  </template>
}
