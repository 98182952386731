import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import type UiComboboxOptionComponent from './ui-combobox/option.ts';
import { action } from '@ember/object';
import type UiComboboxButtonComponent from './ui-combobox/button.gts';
import type UiComboboxEmptyComponent from './ui-combobox/empty.gts';
import type { ValidationError } from './ui-validation-errors.ts';

type State = 'default' | 'disabled' | 'error';

export interface UiComboboxSignature {
  Element: HTMLDivElement;
  Args: {
    allowClear?: boolean;
    disabled?: boolean;
    errors?: ValidationError[];
    hasErrors?: boolean;
    id?: string;
    labelId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any | null) => void;
    onFilterChange?: (value: string) => void;
    searchPlaceholder?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<
          typeof UiComboboxButtonComponent,
          'showClear' | 'value'
        >;
        Empty: WithBoundArgs<typeof UiComboboxEmptyComponent, never>;
        Option: WithBoundArgs<
          typeof UiComboboxOptionComponent,
          'optionComponent'
        >;
      },
    ];
  };
}

const baseClasses =
  'transition-[outline] grid w-full grid-cols-1 rounded-md shadow-sm py-1.5 pl-3 pr-2 text-left outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 sm:text-sm/6';
const stateClasses: Record<State, string> = {
  default: 'bg-white text-gray-900 outline-gray-300 focus:outline-purple-500',
  disabled:
    'cursor-not-allowed bg-gray-200 text-gray-600 outline-gray-300 focus:outline-purple-500',
  error: 'bg-white text-gray-900 outline-red-400 focus:outline-red-500',
};

export default class UiComboboxComponent extends Component<UiComboboxSignature> {
  get hasErrors() {
    return this.args.hasErrors || (this.args.errors || []).length > 0;
  }

  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  get showClear() {
    if (this.args.disabled) {
      return false;
    }

    // Always show clear if the combobox is in multi-select mode.
    if (Array.isArray(this.args.value)) {
      return this.args.value.length > 0;
    }

    return this.args.allowClear ? this.args.value !== null : false;
  }

  @action
  handleFilterChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.args.onFilterChange?.(target.value);
  }

  @action
  handleClear(event: Event) {
    // This prevents event propagation so that it doesn't open up the combobox
    // options when the clear button is clicked.
    event.stopPropagation();
    this.args.onChange?.(Array.isArray(this.args.value) ? [] : null);
  }
}
