import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type SessionService from '../../../services/session';
import { captureMessage } from '../../../utils/error-logging';

export default class FinanceRoute extends Route {
  @service declare router: RouterService;
  @service declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.isOnwardAssociate === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }
  }
}
