import { template as template_fed55bcdbe0c4689820459a2b9a09b70 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import perform from 'ember-concurrency/helpers/perform';
import { findRecord, query } from 'ember-data-resources';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import DriverModel from '../../models/driver';
import type { PageMeta } from '../ui-pagination';
import { formatNumber } from '../../utils/number-format';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
export interface DriverFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        onChange: (driverId: string | null) => void;
        selectedDriverId: string | null;
    };
    Blocks: EmptyObject;
}
export default class DriverFilterComponent extends Component<DriverFilterSignature> {
    @tracked
    term = '';
    get queryParams() {
        const search = this.term ?? undefined;
        return {
            filter: search ? {
                display_name: {
                    match: search
                }
            } : undefined,
            page: {
                size: PAGE_SIZE
            }
        };
    }
    driver = findRecord<DriverModel>(this, 'driver', ()=>this.args.selectedDriverId);
    drivers = query<DriverModel>(this, 'driver', ()=>({
            ...this.queryParams
        }));
    get loading() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.drivers.hasRan === false;
        return this.drivers.isLoading || waitForFirstFetch;
    }
    get moreDriversCount() {
        // @ts-expect-error - We need to figure out how to type this out.
        const meta = this.drivers.records.meta as PageMeta | undefined;
        const { perPage, totalCount, totalPages } = meta || {};
        if (totalPages && totalPages > 1) {
            const remaining = (totalCount ?? 0) - (perPage ?? 0);
            return remaining > 0 ? formatNumber(remaining) : null;
        }
        return null;
    }
    filterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.term) {
            return;
        }
        this.term = term;
    });
    static{
        template_fed55bcdbe0c4689820459a2b9a09b70(`
    <@form.Group data-test-id='driver' as |Group|>
      <Group.Label>Driver</Group.Label>
      <Group.Combobox
        @searchPlaceholder='Search for a Driver...'
        @value={{@selectedDriverId}}
        @allowClear={{true}}
        @onChange={{@onChange}}
        @onFilterChange={{perform this.filterChanged}}
        as |combobox|
      >
        <combobox.Button @placeholder='All Drivers'>
          {{this.driver.record.displayName}}
        </combobox.Button>

        {{#if this.loading}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each (sortBy 'displayName' this.drivers.records) as |driver|}}
            <combobox.Option @value={{driver.id}}>
              {{driver.displayName}}
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No drivers found.
            </combobox.Empty>
          {{/each}}

          {{#if this.moreDriversCount}}
            <combobox.Option @disabled={{true}} @value=''>
              {{this.moreDriversCount}}
              more available.
            </combobox.Option>
          {{/if}}
        {{/if}}
      </Group.Combobox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::DriverFilter': typeof DriverFilterComponent;
    }
}
