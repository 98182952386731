import { template as template_5e3fc75dca8d4c5bb2bbacd4aceb97ff } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { PaymentDetails, PaymentOption } from '../payment';
const PaymentSummary: TOC<{
    Element: HTMLDivElement;
    Args: {
        isInvoiced: boolean;
        paymentDetails: PaymentDetails;
        selectedPaymentOption: PaymentOption | null;
    };
}> = template_5e3fc75dca8d4c5bb2bbacd4aceb97ff(`
  <div
    data-test-id='payment-summary'
    class='text-sm font-normal text-gray-400'
    ...attributes
  >
    {{#if @paymentDetails.payerType}}
      <div data-test-id='financial-responsibility'>
        <span data-test-id='payer-type'>{{@paymentDetails.payerType}}</span>
        -
        <span data-test-id='cost-center'>{{@paymentDetails.costCenter}}</span>
      </div>
    {{/if}}

    {{#if @isInvoiced}}
      <div data-test-id='invoiced'>To Be Invoiced</div>
    {{else if @selectedPaymentOption}}
      <div data-test-id='payment-method'>
        {{@selectedPaymentOption.label}}
      </div>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PaymentSummary;
