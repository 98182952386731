import OnwardJSONAPIAdapter from './shared/onward-jsonapi';

export default class RideEtaAdapter extends OnwardJSONAPIAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'ride-eta': RideEtaAdapter;
  }
}
