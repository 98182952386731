import Component from '@glimmer/component';
import { eq } from 'ember-truth-helpers';

export type UiBadgeColor =
  | 'blue'
  | 'gray'
  | 'green'
  | 'purple'
  | 'red'
  | 'yellow';

export type UiBadgeSize = 'default' | 'small';
export type UiBadgeType = 'default' | 'dot';

export interface UiBadgeSignature {
  Element: HTMLSpanElement;
  Args: {
    size?: UiBadgeSize;
    type?: UiBadgeType;
    color?: UiBadgeColor;
  };
  Blocks: {
    default: [];
  };
}

const colorDefaultClasses: Record<UiBadgeColor, string> = {
  blue: 'bg-blue-50 text-blue-700 ring-blue-600/10',
  gray: 'bg-gray-50 text-gray-600 ring-gray-500/10',
  green: 'bg-green-50 text-green-700 ring-green-600/10',
  purple: 'bg-purple-50 text-purple-500 ring-purple-400/10',
  red: 'bg-red-50 text-red-700 ring-red-600/10',
  yellow: 'bg-yellow-50 text-yellow-700 ring-yellow-600/10',
};
const dotClasses: Record<UiBadgeColor, string> = {
  blue: 'fill-blue-500',
  gray: 'fill-gray-500',
  green: 'fill-green-500',
  purple: 'fill-purple-400',
  red: 'fill-red-500',
  yellow: 'fill-yellow-500',
};
const sizeClasses: Record<UiBadgeSize, string> = {
  default: 'px-2 py-1 text-xs',
  small: 'px-2 py-px text-xs',
};

export default class UiBadgeComponent extends Component<UiBadgeSignature> {
  get color() {
    return this.args.color ?? 'gray';
  }

  get colorClasses() {
    return this.type === 'dot'
      ? 'text-gray-700 ring-gray-200'
      : colorDefaultClasses[this.color];
  }

  get dotColorClasses() {
    return dotClasses[this.color];
  }

  get size() {
    return this.args.size ?? 'default';
  }

  get sizeClasses() {
    return sizeClasses[this.size];
  }

  get type() {
    return this.args.type ?? 'default';
  }

  <template>
    <span
      data-test-id='badge'
      class='inline-flex items-center gap-x-1.5 whitespace-nowrap rounded-md font-medium ring-1 ring-inset
        {{this.colorClasses}}
        {{this.sizeClasses}}'
      ...attributes
    >
      {{#if (eq this.type 'dot')}}
        <svg
          class='h-1.5 w-1.5 {{this.dotColorClasses}}'
          viewBox='0 0 6 6'
          aria-hidden='true'
        >
          <circle cx='3' cy='3' r='3' />
        </svg>
      {{/if}}

      {{yield}}
    </span>
  </template>
}
