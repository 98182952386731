import { template as template_535d59593a66496eaed5e0489c577133 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { findAll } from 'ember-data-resources';
// @ts-expect-error - We need to figure out how to create the types for this.
import { sortBy } from 'ember-composable-helpers/helpers/sort-by';
import type PayerTypeModel from '../../models/payer-type';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import { action } from '@ember/object';
const ACCOUNT_OPTION = {
    name: 'Account',
    value: 'None'
};
export interface FinancialResponsibilityFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        financialResponsibilities: string[];
        onChange: (financialResponsibilities: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class FinancialResponsibilityFilterComponent extends Component<FinancialResponsibilityFilterSignature> {
    financialResponsibilitiesData = findAll<PayerTypeModel>(this, 'payer-type');
    get options() {
        let records = this.financialResponsibilitiesData.records;
        if (!records) {
            return [] as PayerTypeModel[];
        }
        const items = [
            ...records.slice(),
            {
                id: '0',
                name: ACCOUNT_OPTION.name
            }
        ];
        return sortBy([
            'name',
            items
        ]) as PayerTypeModel[];
    }
    get selectedValues() {
        const { financialResponsibilities } = this.args;
        return financialResponsibilities.map((financialResponsibility)=>{
            if (financialResponsibility === ACCOUNT_OPTION.value) {
                return ACCOUNT_OPTION.name;
            }
            return financialResponsibility;
        });
    }
    get financialResponsibilitiesDisplay() {
        const filteredFinancialResponsibilities = this.options.filter((financialResponsibility)=>{
            return ((financialResponsibility.name === ACCOUNT_OPTION.name && this.selectedValues.includes(ACCOUNT_OPTION.value)) || this.selectedValues.includes(financialResponsibility.name));
        });
        return filteredFinancialResponsibilities ? filteredFinancialResponsibilities.map((financialResponsibility)=>financialResponsibility.name).join(', ') : null;
    }
    @action
    handleOnChange(financialResponsibilities: string[]) {
        const updatedItems = financialResponsibilities.map((item)=>{
            if (item === ACCOUNT_OPTION.name) {
                return ACCOUNT_OPTION.value;
            }
            return item;
        });
        this.args.onChange(updatedItems);
    }
    static{
        template_535d59593a66496eaed5e0489c577133(`
    <@form.Group data-test-id='financial-responsibilities' as |Group|>
      <Group.Label>Financial Responsibilities</Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{this.selectedValues}}
        @onChange={{this.handleOnChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Financial Responsibilities'>
          {{this.financialResponsibilitiesDisplay}}
        </listbox.Button>

        <listbox.Options as |options|>
          {{#each this.options as |financialResponsibility|}}
            <options.Option @value={{financialResponsibility.name}}>
              {{financialResponsibility.name}}
            </options.Option>
          {{else}}
            <options.Empty>
              There are no Financial Responsibilities.
            </options.Empty>
          {{/each}}
        </listbox.Options>
      </Group.Listbox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::FinancialResponsibilityFilter': typeof FinancialResponsibilityFilterComponent;
    }
}
