import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { and, or } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { type UiHeadlessListboxSignature } from '../ui-headless-listbox.gts';
import UiIcon from '../ui-icon.ts';
import applyId from '../../modifiers/apply-id.ts';

export interface UiListboxButtonSignature {
  Element: HTMLSpanElement;
  Args: {
    placeholder?: string;
  };
  Blocks: {
    placeholder?: [];
    default: [];
  };
}

interface InternalSignature extends UiListboxButtonSignature {
  Args: {
    disabled?: boolean;
    hasErrors: boolean;
    id?: string;
    listboxComponent: UiHeadlessListboxSignature['Blocks']['default'][0];
    onClear: (event: Event) => void;
    placeholder?: string;
    showClear: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any | null;
  };
}

type State = 'default' | 'disabled' | 'error';

const baseClasses =
  'transition-[outline] grid w-full grid-cols-1 rounded-md shadow-sm py-1.5 pl-3 pr-2 text-left outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 sm:text-sm/6';
const stateClasses: Record<State, string> = {
  default: 'bg-white text-gray-900 outline-gray-300 focus:outline-purple-500',
  disabled:
    'cursor-not-allowed bg-gray-200 text-gray-600 outline-gray-300 focus:outline-purple-500',
  error: 'bg-white text-gray-900 outline-red-400 focus:outline-red-500',
};

export default class UiListboxButtonComponent extends Component<InternalSignature> {
  get baseClasses() {
    return baseClasses;
  }

  get stateClasses() {
    let state: State = 'default';

    if (this.args.disabled) {
      state = 'disabled';
    } else if (this.args.hasErrors) {
      state = 'error';
    }

    return stateClasses[state];
  }

  get noValue() {
    return isEmpty(this.args.value);
  }

  <template>
    <@listboxComponent.Button
      data-test-id='listbox-button'
      aria-invalid={{@hasErrors}}
      class='{{this.baseClasses}} {{this.stateClasses}}'
      {{applyId @id}}
    >
      {{#let
        (and this.noValue (or (has-block 'placeholder') @placeholder))
        as |showPlaceholder|
      }}
        <span
          class='col-start-1 row-start-1 truncate
            {{if showPlaceholder "text-gray-400"}}
            {{if @showClear "pr-14" "pr-6"}}'
        >
          {{#if showPlaceholder}}
            <span class='text-gray-400'>
              {{#if (has-block 'placeholder')}}
                {{yield to='placeholder'}}
              {{else}}
                {{@placeholder}}
              {{/if}}
            </span>
          {{else}}
            {{yield}}
          {{/if}}
        </span>
      {{/let}}

      {{#if @showClear}}
        <button
          type='button'
          data-test-id='listbox-clear'
          class='col-start-1 row-start-1 mr-7 self-center justify-self-end text-gray-400 focus:text-gray-700 focus:outline-none'
          aria-label='Clear'
          {{on 'click' @onClear}}
        >
          <UiIcon @icon='x-circle' @type='mini' />
        </button>
      {{/if}}

      <span
        class='col-start-1 row-start-1 flex h-6 items-center self-center justify-self-end'
      >
        <UiIcon @icon='chevron-up-down' @type='mini' class='text-gray-400' />
      </span>
    </@listboxComponent.Button>
  </template>
}
