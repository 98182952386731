import Component from '@glimmer/component';
import { uniqueId } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import type { TabVariation } from '../ui-tab-group.ts';

export interface TabPanelSignature {
  Element: HTMLDivElement;
  Args: {
    onPanelRegistered: (panelElement: HTMLDivElement) => unknown;
    panelIds: string[];
    selectedIndex: number;
    tabIds: string[];
    variation: TabVariation;
  };
  Blocks: {
    default: [];
  };
}

const tabPanelVariationClasses: Record<TabVariation, string> = {
  default:
    'transition-colors rounded-lg focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-purple-500',
  none: '',
  segmented:
    'transition-colors rounded-lg focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-purple-500',
};

export default class TabPanel extends Component<TabPanelSignature> {
  id = uniqueId();

  get variationClasses() {
    return tabPanelVariationClasses[this.args.variation];
  }

  get isSelected() {
    return this.args.panelIds[this.args.selectedIndex] === this.id;
  }

  get tabId() {
    const index = this.args.panelIds.findIndex((id) => id === this.id);
    return this.args.tabIds[index];
  }

  get panelStyles() {
    return htmlSafe(
      'position:fixed;top:1px;left:1px;width:1px;height:0;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0',
    );
  }

  registerPanel = modifier((element: HTMLDivElement) => {
    this.args.onPanelRegistered(element);
  });

  // Not sure why I have to disable these template lint rules since I'm using `htmlSafe`.
  <template>
    {{! template-lint-disable no-inline-styles }}
    {{! template-lint-disable style-concatenation }}
    <div
      id={{this.id}}
      role='tabpanel'
      aria-labelledby={{this.tabId}}
      tabindex={{if this.isSelected '0' '-1'}}
      data-test-id='tab-panel'
      data-selected={{this.isSelected}}
      {{this.registerPanel}}
      class='{{this.variationClasses}}'
      ...attributes
      style='{{unless this.isSelected this.panelStyles}}'
    >
      {{#if this.isSelected}}
        {{yield}}
      {{/if}}
    </div>
  </template>
}
