import { template as template_457bdc4193734f0cbdd5d3ecaa61a900 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiSegmentedTabGroup from '@onwardcare/ui-components/components/ui-segmented-tab-group';
import HeaderSection from './ride-form/header-section';
import OneWayForm from './ride-form/one-way-form';
import { DEFAULT_ROUTE } from '../../utils/default-routes';
import type AccountTransportType from '../../models/account-transport-type';
import type TransportTypeOption from '../../models/transport-type-option';
export type RideType = 'one-way' | 'round-trip';
const tabs: RideType[] = [
    'one-way',
    'round-trip'
];
interface RideFormSignature {
    Element: HTMLDivElement;
    Args: {
        onRideTypeChanged: (rideType: RideType) => unknown;
        rideType: RideType;
        transportType: AccountTransportType;
        transportTypeOptions: TransportTypeOption;
    };
    Blocks: EmptyObject;
}
export default class RideFormComponent extends Component<RideFormSignature> {
    @service
    router: RouterService;
    get selectedTabIndex() {
        return tabs.indexOf(this.args.rideType) ?? 0;
    }
    @action
    handleRideType(tabIndex: number) {
        this.args.onRideTypeChanged(tabs[tabIndex] ?? 'one-way');
    }
    @action
    handleOneWaySaved(rideId: string) {
        console.log('Saved one-way ride with ID:', rideId);
    }
    @action
    handleOneWayCanceled() {
        console.log('Canceled one-way ride');
        this.router.transitionTo(DEFAULT_ROUTE);
    }
    static{
        template_457bdc4193734f0cbdd5d3ecaa61a900(`
    <div class='max-w-[532px] bg-white text-gray-900'>
      <HeaderSection @transportType={{@transportType}} />

      <div class='p-6'>
        <div>
          <div class='text-sm font-semibold'>Ride Type</div>
          <UiSegmentedTabGroup
            class='mt-2'
            @fitToParent={{true}}
            @selectedIndex={{this.selectedTabIndex}}
            @onChange={{this.handleRideType}}
            as |Group|
          >
            <Group.TabList as |List|>
              <List.Tab class='flex-col'>
                <UiIcon
                  @icon='arrow-right-end-on-rectangle'
                  @type='mini'
                  class='mr-1'
                />
                One-Way
              </List.Tab>
              <List.Tab>
                <UiIcon @icon='arrow-path' @type='mini' class='mr-1' />
                Round-Trip
              </List.Tab>
            </Group.TabList>

            <Group.TabPanels as |Panels|>
              {{#let 'mt-6' as |classes|}}
                <Panels.TabPanel class={{classes}} tabindex='-1'>
                  <OneWayForm
                    @transportType={{@transportType}}
                    @transportTypeOptions={{@transportTypeOptions}}
                    @onCanceled={{this.handleOneWayCanceled}}
                    @onSaved={{this.handleOneWaySaved}}
                  />
                </Panels.TabPanel>

                <Panels.TabPanel class={{classes}}>
                  Round-Trip Content
                </Panels.TabPanel>
              {{/let}}
            </Group.TabPanels>
          </UiSegmentedTabGroup>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm': typeof RideFormComponent;
    }
}
