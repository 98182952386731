import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AccountService from '../../../services/account';
import type AccountTransportType from '../../../models/account-transport-type';
import type RidesNewController from '../../../controllers/authenticated/rides/new';
import type SessionService from '../../../services/session';

export default class RidesNewRoute extends Route {
  @service declare account: AccountService;
  @service declare session: SessionService;
  @service declare store: Store;

  queryParams = {
    transportType: { refreshModel: true },
  };

  async model(params: { transport_type?: string }) {
    let transportType: AccountTransportType | undefined;
    let optionsName: string | undefined;

    if (params.transport_type === 'delivery') {
      // TODO: We probably need to do something differently here.
      transportType = {
        name: 'delivery',
        shortName: 'Delivery',
        fullName: 'Delivery',
      } as AccountTransportType;
      // Since delivery isn't a real transport type and it's based on the
      // companion transport type, we'll just use that when fetching options.
      optionsName = 'companion';
    } else {
      transportType = this.session.accountTransportTypes.find(
        (type) => type.name === params.transport_type,
      );
      optionsName = transportType?.name;
    }

    const transportTypeOptions = await this.store.queryRecord(
      'transport-type-option',
      { transport_type: optionsName },
    );
    this.account.loadRideSettings.perform();

    return {
      transportType,
      transportTypeOptions,
    };
  }

  resetController(controller: RidesNewController, isExiting: boolean) {
    // We want to reset the ride type back to it's default value when the user
    // leaves the page.
    if (isExiting) {
      controller.rideType = 'one-way';
    }
  }

  @action
  didTransition() {
    // Animate scrolling to the top of the page when params change.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}
