import { template as template_88dadb4e817240f1b6e95f546fcd7c62 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiConfirmation from '../../ui-confirmation';
import set from 'ember-set-helper/helpers/set';
interface RequestMarkClosedFormSignature {
    Element: HTMLDivElement;
    Args: {
        onSaved: () => unknown;
        rideRequest: VetrideTripModel;
    };
    Blocks: EmptyObject;
}
export default class RequestMarkClosedFormComponent extends Component<RequestMarkClosedFormSignature> {
    @tracked
    errorMessage: string | null = null;
    @tracked
    isConfirmationOpen = false;
    closeRequest = restartableTask(async (close: () => unknown)=>{
        const rideRequest = this.args.rideRequest;
        this.errorMessage = null;
        async function closeConfirmation() {
            close();
            // The fade duration for the modal is 300.
            await timeout(350);
        }
        try {
            rideRequest.requestStatus = 'closed';
            await rideRequest.save();
            await closeConfirmation();
            this.args.onSaved?.();
        } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
            rideRequest.rollbackAttributes();
            this.errorMessage = extractErrorMessage(ex, 'Something went wrong trying to close the request.');
            await closeConfirmation();
        }
    });
    static{
        template_88dadb4e817240f1b6e95f546fcd7c62(`
    <div>
      {{#if this.errorMessage}}
        <UiAlert data-test-id='error-message' @variant='danger' class='mb-6'>
          {{this.errorMessage}}
        </UiAlert>
      {{/if}}

      <UiConfirmation
        @isOpen={{this.isConfirmationOpen}}
        @onCancel={{set this 'isConfirmationOpen' false}}
        @onConfirm={{perform this.closeRequest}}
      >
        <:title>Close Request?</:title>
        <:body>
          <p>
            Do you really want to close this request?
          </p>
        </:body>
      </UiConfirmation>

      <UiButton
        data-test-id='mark-closed-btn'
        @variant='primary'
        @size='small'
        @onClick={{set this 'isConfirmationOpen' true}}
      >
        Close Request
      </UiButton>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::Request::RequestMarkClosedForm': typeof RequestMarkClosedFormComponent;
    }
}
