import Model, { attr } from '@ember-data/model';

export default class FinancialResponsibilityModel extends Model {
  @attr() declare costCenterName: string;
  @attr() declare displayName: string;
  @attr() declare displayOrder: number;
  @attr() declare name: string;
  @attr() declare promptForPayerDemographics: boolean;
  @attr() declare requirePaymentFromPrivatePayerOnBooking: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'financial-responsibility': FinancialResponsibilityModel;
  }
}
