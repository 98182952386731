import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import UiIcon from '../ui-icon.ts';
import { type UiHeadlessListboxOptionSignature } from '../ui-headless-listbox/-option.gts';
import { type UiHeadlessListboxOptionsSignature } from '../ui-headless-listbox/-options.gts';

export interface UiListboxOptionSignature {
  Element: UiHeadlessListboxOptionSignature['Element'];
  Args: {
    disabled?: boolean;
    value: string | number | object;
  };
  Blocks: UiHeadlessListboxOptionSignature['Blocks'];
}

interface InternalSignature extends UiListboxOptionSignature {
  Args: {
    disabled?: boolean;
    inGroup?: boolean;
    optionsBlock: UiHeadlessListboxOptionsSignature['Blocks']['default'][0];
    value: string | number | object;
  };
}

const UiListboxOptionComponent: TOC<InternalSignature> = <template>
  <@optionsBlock.Option
    data-test-id='listbox-option'
    @value={{@value}}
    @disabled={{@disabled}}
    class='outline-none'
    ...attributes
    as |option|
  >
    <span
      class='{{if option.active "bg-purple-500 text-white" "text-gray-900"}}
        relative block cursor-default select-none py-2
        {{if @inGroup "pl-8" "pl-3"}}
        pr-9'
    >
      <span
        class='{{if option.selected "font-semibold" "font-normal"}}
          {{if option.disabled "text-gray-400"}}
          block truncate'
      >
        {{yield
          (hash
            active=option.active
            disabled=option.disabled
            selected=option.selected
          )
        }}
      </span>

      {{#if option.selected}}
        <span
          class='{{if option.active "text-white" "text-purple-500"}}
            absolute inset-y-0 right-0 flex items-center pr-4'
        >
          <UiIcon @icon='check' @type='mini' />
        </span>
      {{/if}}
    </span>
  </@optionsBlock.Option>
</template>;

export default UiListboxOptionComponent;
