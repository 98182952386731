import Model, { attr } from '@ember-data/model';

export default class SetupIntentModel extends Model {
  @attr() declare setupIntent: string;
  @attr() declare ephemeralKey: string;
  @attr() declare customer: string;
  @attr() declare publishableKey: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'setup-intent': SetupIntentModel;
  }
}
