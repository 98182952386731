import Controller from '@ember/controller';
import { service } from '@ember/service';
import type SessionService from '../services/session';
import { DEFAULT_ROUTE } from '../utils/default-routes';

export default class AccessDeniedController extends Controller {
  @service declare session: SessionService;

  defaultRoute = DEFAULT_ROUTE;
}
