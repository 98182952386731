import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { hash, uniqueId } from '@ember/helper';
import AccordionContent from './content.gts';
import AccordionTrigger from './trigger.gts';

export interface AccordionItemSignature {
  Element: HTMLDivElement;
  Blocks: {
    default: [
      {
        /**
         * Whether the accordion item is expanded.
         */
        isExpanded: boolean;
        /**
         * The AccordionContent component.
         */
        Content: WithBoundArgs<
          typeof AccordionContent,
          'isExpanded' | 'triggerId' | 'value'
        >;
        /**
         * The AccordionTrigger component.
         */
        Trigger: WithBoundArgs<
          typeof AccordionTrigger,
          'id' | 'isExpanded' | 'onChange' | 'value'
        >;
      },
    ];
  };
  Args: {
    /**
     * The value of the accordion item.
     */
    value: string;
  };
}

interface InternalSignature extends AccordionItemSignature {
  Args: AccordionItemSignature['Args'] & {
    selectedValue?: string | string[];
    onChange: (value: string) => unknown;
  };
}

export default class AccordionItem extends Component<InternalSignature> {
  triggerId = uniqueId();

  get isExpanded() {
    if (Array.isArray(this.args.selectedValue)) {
      return this.args.selectedValue.includes(this.args.value);
    }

    return this.args.selectedValue === this.args.value;
  }

  <template>
    <div
      class='rounded-lg border border-gray-100 bg-white shadow-md'
      data-expanded={{if this.isExpanded 'true' 'false'}}
      ...attributes
    >
      {{yield
        (hash
          isExpanded=this.isExpanded
          Content=(component
            AccordionContent
            isExpanded=this.isExpanded
            triggerId=this.triggerId
            value=@value
          )
          Trigger=(component
            AccordionTrigger
            id=this.triggerId
            isExpanded=this.isExpanded
            onChange=@onChange
            value=@value
          )
        )
      }}
    </div>
  </template>
}
