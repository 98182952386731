import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { fn, hash } from '@ember/helper';
import type Owner from '@ember/owner';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';

export interface UiHeadlessListboxOptionSignature {
  Element: HTMLLIElement;
  Args: {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
  Blocks: {
    default: [
      {
        active: boolean;
        selected: boolean;
        disabled: boolean;
      },
    ];
  };
}

interface InternalSignature extends UiHeadlessListboxOptionSignature {
  Args: {
    activeOptionGuid?: string;
    disabled?: boolean;
    multiple?: boolean;
    registerOptionElement: (
      optionComponent: UiHeadlessListboxOptionComponent,
      element: HTMLLIElement,
    ) => void;
    scrollIntoView: (optionElement: HTMLLIElement) => void;
    selectedOptionGuids: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (
      optionComponent: UiHeadlessListboxOptionComponent,
    ) => void;
    setSelectedOption: (
      optionComponent: UiHeadlessListboxOptionComponent,
      event: Event,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    unsetActiveOption: () => void;
  };
}

export default class UiHeadlessListboxOptionComponent extends Component<InternalSignature> {
  @tracked index = -1;
  @tracked guid = `${guidFor(this)}-headlessui-listbox-option`;
  @tracked shouldScroll = false;

  constructor(owner: Owner, args: InternalSignature['Args']) {
    super(owner, args);

    if (this.args.selectedValue && !this.args.multiple) {
      this.shouldScroll = this.args.selectedValue === this.args.value;
    } else {
      this.shouldScroll = false;
    }
  }

  registerOption = modifier((element: HTMLLIElement) => {
    this.args.registerOptionElement(this, element);
  });

  scroll = modifier(
    (
      element: HTMLLIElement,
      [shouldScroll, scrollFn]: [
        boolean,
        (optionElement: HTMLLIElement) => void,
      ],
    ) => {
      if (shouldScroll) {
        scrollFn(element);
      }
    },
  );

  @action
  handleClick(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.args.setSelectedOption(this, e);
  }

  get isActive() {
    return this.args.activeOptionGuid == this.guid;
  }

  get isSelected() {
    if (this.args.multiple) {
      const selected = this.args.selectedValue ?? [];
      return selected.includes(this.args.value);
    } else {
      return (
        // allow 0 and null to as possible values
        this.args.selectedValue !== undefined &&
        this.args.selectedValue === this.args.value
      );
    }
  }

  <template>
    <li
      role='option'
      id={{this.guid}}
      tabindex={{unless @disabled '-1'}}
      {{this.registerOption}}
      {{this.scroll this.shouldScroll @scrollIntoView}}
      {{on 'focus' (fn @setActiveOption this)}}
      {{on 'mouseover' (fn @setActiveOption this)}}
      {{on 'mouseout' @unsetActiveOption}}
      {{on 'click' this.handleClick}}
      aria-selected={{if this.isSelected 'true' 'false'}}
      aria-disabled={{if @disabled 'true' 'false'}}
      disabled={{if @disabled true false}}
      ...attributes
    >
      {{yield
        (hash
          active=this.isActive
          selected=this.isSelected
          disabled=(if @disabled true false)
        )
      }}
    </li>
  </template>
}
