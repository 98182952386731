import { template as template_1159e77b86734aa2a3dfc29b17e4b6fc } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import set from 'ember-set-helper/helpers/set';
import { eq } from 'ember-truth-helpers';
import UiButtonGroup from '@onwardcare/ui-components/components/ui-button-group';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type SessionService from '../../../services/session';
import type AccountTransportType from '../../../models/account-transport-type';
import InfoButton from '../../info-button';
import LocationCombobox from './location/location-combobox';
import LocationPhoto from './location/location-photo';
import UiConfirmation from '../../ui-confirmation';
import UiSubfield from '../../ui-subfield';
export interface RideLocation {
    address: string;
    instructions?: string | null;
    latitude: number;
    locationId?: number;
    locationPhotoUrl?: string | null;
    longitude: number;
    name: string;
    roomNumber?: string | null;
    suggestedLocationId?: string;
    type: string;
    zipcode: string;
}
interface LocationSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        location: RideLocation | null;
        onChange: (location: RideLocation) => void;
        riderId?: string;
        transportType: AccountTransportType;
        type: 'pickup' | 'dropoff';
    };
}
export default class LocationComponent extends Component<LocationSignature> {
    @service
    session: SessionService;
    @tracked
    locationPhoto: RideLocation | null = null;
    @tracked
    removePhotoConfirmation = false;
    get promptForRoomNumber() {
        return (this.args.location !== null && this.session.account?.promptForRoomNumbers === true);
    }
    get typeName() {
        return this.args.type === 'pickup' ? 'Pickup' : 'Dropoff';
    }
    @action
    updateRideLocation(newFields: Partial<RideLocation>) {
        const locationCopy = Object.assign({}, this.args.location);
        const newLocation = {
            ...locationCopy,
            ...newFields
        };
        this.args.onChange(newLocation);
    }
    @action
    handleLocationChange(location: RideLocation) {
        this.updateRideLocation({
            ...location,
            roomNumber: null
        });
    }
    @action
    handleRoomNumberChange(value: string) {
        this.updateRideLocation({
            roomNumber: value !== '' ? value : null
        });
    }
    @action
    handleInstructionsChange(value: string) {
        this.updateRideLocation({
            instructions: value !== '' ? value : null
        });
    }
    @action
    removePhoto() {
        this.updateRideLocation({
            locationPhotoUrl: null
        });
        this.removePhotoConfirmation = false;
    }
    static{
        template_1159e77b86734aa2a3dfc29b17e4b6fc(`
    <div
      data-test-id='location'
      class='flex flex-col gap-3 rounded-lg bg-gray-50 p-3'
      ...attributes
    >
      <LocationCombobox
        @formGroup={{@formGroup}}
        @location={{@location}}
        @onChange={{this.handleLocationChange}}
        @riderId={{@riderId}}
        @transportType={{@transportType}}
        @typeName={{this.typeName}}
      />

      {{#if @location}}
        <UiSubfield>
          <div class='flex flex-col gap-3'>
            {{#if this.promptForRoomNumber}}
              <@formGroup
                class='flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between'
                data-test-id='room-number'
                as |Group|
              >
                <Group.Label>
                  {{this.typeName}}
                  Apt/Room Number
                </Group.Label>
                <Group.Input
                  class='sm:max-w-36'
                  autocomplete='off'
                  @value={{@location.roomNumber}}
                  @onChange={{this.handleRoomNumberChange}}
                />
              </@formGroup>
            {{/if}}

            <@formGroup data-test-id='instructions' as |Group|>
              <Group.Label>
                {{this.typeName}}
                Location Instructions

                <InfoButton class='relative top-0.5 text-purple-200'>
                  {{#if (eq @type 'pickup')}}
                    Where specifically should the driver meet the rider?
                  {{else}}
                    Where specifically should the driver park and take the
                    rider?
                  {{/if}}
                </InfoButton>
              </Group.Label>
              <Group.Textarea
                @value={{@location.instructions}}
                @onChange={{this.handleInstructionsChange}}
                rows='2'
              />
            </@formGroup>

            {{#if @location.locationPhotoUrl}}
              <UiButtonGroup data-test-id='loction-photo' as |BGroup|>
                <BGroup.Button
                  data-test-id='view-photo'
                  @onClick={{set this 'locationPhoto' @location}}
                >
                  <span class='inline-flex items-center gap-4'>
                    <img
                      src={{@location.locationPhotoUrl}}
                      alt=''
                      class='-m-2 h-7 w-16 rounded object-cover'
                    />
                    <span class='inline-flex items-center gap-2'>
                      <UiIcon @icon='camera' @type='mini' />
                      <span class='sr-only sm:not-sr-only'>
                        View Location Photo
                      </span>
                    </span>
                  </span>
                </BGroup.Button>
                <BGroup.Button
                  data-test-id='remove-photo'
                  aria-label='Remove Location Photo'
                  class='group'
                  @onClick={{set this 'removePhotoConfirmation' true}}
                >
                  <UiIcon
                    @icon='trash'
                    @type='mini'
                    class='-mx-1.5 text-gray-300 group-hover:text-gray-500'
                  />
                </BGroup.Button>
              </UiButtonGroup>

              <LocationPhoto
                @location={{this.locationPhoto}}
                @onClose={{set this 'locationPhoto' null}}
                @typeName={{this.typeName}}
              />

              <UiConfirmation
                @isOpen={{this.removePhotoConfirmation}}
                @onCancel={{set this 'removePhotoConfirmation' false}}
                @cancelText='Cancel'
                @onConfirm={{this.removePhoto}}
                @confirmText='Yes, Remove'
              >
                <:title>Remove Location Photo</:title>
                <:body>
                  <p>
                    Are you sure you want to remove this photo from the ride? If
                    it is removed, it will remain associated with this saved
                    location, but it will not be shown to the Driver during this
                    ride.
                  </p>
                </:body>
              </UiConfirmation>
            {{/if}}
          </div>
        </UiSubfield>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::Location': typeof LocationComponent;
    }
}
