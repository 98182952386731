import { template as template_5758a44759084f3aaf8ce3d3c2b43ae3 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
// @ts-expect-error: TODO: Figure out how to fix this.
import Transition from '@onwardcare/ember-headlessui/components/transition';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import { isActiveRoute } from '../../utils/route';
import { isInternalItem, isVisibleItem, type MenuItem } from '../main-layout';
import type SessionService from '../../services/session';
import InternalIcon from '../internal-icon';
import SubMenuItem from './sub-menu-item';
export interface MenuItemSignature {
    Element: HTMLAnchorElement;
    Args: {
        item: MenuItem;
        isChild?: boolean;
    };
    Blocks: EmptyObject;
}
export default class MenuItemComponent extends Component<MenuItemSignature> {
    @service
    router: RouterService;
    @service
    session: SessionService;
    @tracked
    _isActive: boolean | undefined = undefined;
    @tracked
    isExpanded = false;
    get buttonClasses() {
        return `group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold transition focus:outline-purple-500 ${this.isActive ? 'bg-purple-50 text-purple-500' : 'text-gray-700 hover:bg-purple-50 hover:text-purple-500'}`;
    }
    get iconClasses() {
        return `transition shrink-0 ${this.isActive ? 'text-purple-500' : 'text-gray-400 group-hover:text-purple-500'}`;
    }
    get isActive() {
        if (this._isActive === undefined) {
            return this.isActiveRoute();
        }
        return this._isActive;
    }
    set isActive(value: boolean) {
        this._isActive = value;
    }
    get isFolder() {
        return this.args.item.type === 'folder';
    }
    get isInternal() {
        return isInternalItem(this.args.item);
    }
    get isVisible() {
        return isVisibleItem(this.args.item, this.session);
    }
    constructor(owner: unknown, args: MenuItemSignature['Args']){
        super(owner, args);
        this.updateMenuState();
        this.router.on('routeDidChange', ()=>{
            this.updateMenuState();
        });
    }
    updateMenuState() {
        this._isActive = this.isActiveRoute();
        if (this.isActive && this.isFolder) {
            this.isExpanded = true;
        }
    }
    isActiveRoute() {
        const { route, model, models } = this.args.item;
        return isActiveRoute(this.router, route, model, models);
    }
    @action
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }
    static{
        template_5758a44759084f3aaf8ce3d3c2b43ae3(`
    {{#if this.isVisible}}
      {{#if this.isFolder}}
        <button
          data-test-id={{@item.testId}}
          type='button'
          class={{this.buttonClasses}}
          {{on 'click' this.toggleExpanded}}
        >

          {{#if @item.icon}}
            <UiIcon @icon={{@item.icon}} class={{this.iconClasses}} />
          {{/if}}

          <span class='flex grow items-center gap-2 text-left'>
            {{@item.name}}

            {{#if this.isInternal}}
              <InternalIcon @isActive={{this.isActive}} />
            {{/if}}
          </span>

          <UiIcon
            @icon='chevron-right'
            @type='mini'
            class='ml-auto shrink-0 text-gray-400 transition
              {{if
                this.isActive
                "text-purple-500"
                "group-hover:text-purple-500"
              }}
              {{if this.isExpanded "rotate-90" "rotate-0"}}'
          />
        </button>

        <Transition
          @show={{this.isExpanded}}
          @enter='transition duration-100 ease-out'
          @enterFrom='transform scale-95 opacity-0'
          @enterTo='transform scale-100 opacity-100'
          @leave='transition duration-75 ease-out'
          @leaveFrom='transform scale-100 opacity-100'
          @leaveTo='transform scale-95 opacity-0'
        >
          <ul class='mt-1 px-2'>
            {{#each @item.children as |child|}}
              <SubMenuItem
                @item={{child}}
                @isParentInternal={{this.isInternal}}
              />
            {{/each}}
          </ul>
        </Transition>
      {{else if (eq @item.type 'item')}}
        <UiBaseLink
          data-test-id={{@item.testId}}
          @href={{@item.href}}
          @route={{@item.route}}
          @model={{@item.model}}
          @models={{@item.models}}
          class={{this.buttonClasses}}
        >
          {{#if @item.icon}}
            <UiIcon @icon={{@item.icon}} class={{this.iconClasses}} />
          {{/if}}

          {{@item.name}}

          {{#if this.isInternal}}
            <InternalIcon @isActive={{this.isActive}} />
          {{/if}}
        </UiBaseLink>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::MenuItem': typeof MenuItemComponent;
    }
}
