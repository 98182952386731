import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import UiButton from './ui-button.gts';

export interface UiButtonGroupSignature {
  Element: HTMLSpanElement;
  Blocks: {
    default: [
      {
        Button: WithBoundArgs<typeof UiButton, 'isInGroup'>;
      },
    ];
  };
}

export default class UiButtonGroupComponent extends Component<UiButtonGroupSignature> {
  @tracked isRunning = false;

  <template>
    <span class='isolate inline-flex rounded-md shadow-sm' ...attributes>
      {{yield (hash Button=(component UiButton isInGroup=true))}}
    </span>
  </template>
}
