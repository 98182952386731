import { template as template_666d4511fc0b4e7eb3215e643debb13c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BookNewMenu from './book-new-menu';
import HelpContent from './help-content';
import type { MenuItem } from '../main-layout';
import MenuItemComponent from './menu-item';
export interface NavigationMenuSignature {
    Element: HTMLDivElement;
    Args: {
        menuItems: MenuItem[];
    };
    Blocks: EmptyObject;
}
const NavigationMenu: TOC<NavigationMenuSignature> = template_666d4511fc0b4e7eb3215e643debb13c(`
  <div
    class='flex h-full grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'
  >
    <div class='flex h-16 shrink-0 items-center'>
      <img src='/images/logo-full.svg' alt='Onward' class='h-10' />
      <span class='sr-only'>Onward</span>
    </div>
    <nav data-test-id='main-menu' class='flex flex-1 flex-col'>
      <BookNewMenu />

      <ul role='list' class='flex flex-1 flex-col gap-y-7'>
        <li>
          <ul role='list' class='-mx-2 space-y-1'>
            {{#each @menuItems as |item|}}
              <li>
                <MenuItemComponent @item={{item}} />
              </li>
            {{/each}}
          </ul>
        </li>

        <li class='mt-auto'>
          <HelpContent />
        </li>
      </ul>
    </nav>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default NavigationMenu;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::NavigationMenu': typeof NavigationMenu;
    }
}
