import { template as template_bd83ba06eea54daaa7fc341bb92d2e68 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type { EmptyObject } from '@ember/component/helper';
import { restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import perform from 'ember-concurrency/helpers/perform';
import { query } from 'ember-data-resources';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import { formatNumber } from '../../../utils/number-format';
import type { PageMeta } from '../../ui-pagination';
import type RiderModel from '../../../models/rider';
import type SessionService from '../../../services/session';
import UiAvatar from '../../ui-avatar';
import { getInitials } from '../../../utils/initials';
import RiderDetails from './rider-details';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
const RiderItem: TOC<{
    Args: {
        isActive?: boolean;
        rider: RiderModel | null;
    };
}> = template_bd83ba06eea54daaa7fc341bb92d2e68(`
  <span class='flex items-center gap-2'>
    <UiAvatar
      @isActive={{@isActive}}
      @photoUrl={{@rider.profilePhotoUrl}}
      @size='small'
    >
      <:fallback>
        {{getInitials @rider.firstName @rider.lastName}}
      </:fallback>
    </UiAvatar>

    <RiderDetails @isActive={{@isActive}} @rider={{@rider}} />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface RiderComboboxSignature {
    Element: HTMLFormElement;
    Args: {
        formGroup: typeof UiFormGroup;
        onChange: (rider: RiderModel) => void;
        rider: RiderModel | null;
    };
    Blocks: EmptyObject;
}
export default class RiderComboboxComponent extends Component<RiderComboboxSignature> {
    @service
    session: SessionService;
    @tracked
    filter = '';
    get queryParams() {
        const search = this.filter ? {
            display_name: {
                match: this.filter
            }
        } : undefined;
        return {
            filter: {
                account_id: this.session.user?.accountId,
                ...search
            },
            page: {
                size: PAGE_SIZE
            }
        };
    }
    get isLoading() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.riders.hasRan === false;
        return this.riders.isLoading || waitForFirstFetch;
    }
    get moreRidersCount() {
        // @ts-expect-error - We need to figure out how to type this out.
        const meta = this.riders.records?.meta as PageMeta | undefined;
        const { perPage, totalCount, totalPages } = meta || {};
        if (totalPages && totalPages > 1) {
            const remaining = (totalCount ?? 0) - (perPage ?? 0);
            return remaining > 0 ? formatNumber(remaining) : null;
        }
        return null;
    }
    riders = query<RiderModel>(this, 'rider', ()=>({
            ...this.queryParams
        }));
    filterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.filter) {
            return;
        }
        this.filter = term;
    });
    static{
        template_bd83ba06eea54daaa7fc341bb92d2e68(`
    <@formGroup as |Group|>
      <Group.Label>Rider</Group.Label>
      <Group.Combobox
        class='mt-2'
        data-test-id='rider-combobox'
        @value={{@rider}}
        @onChange={{@onChange}}
        @onFilterChange={{perform this.filterChanged}}
        as |combobox|
      >
        <combobox.Button>
          <:placeholder>
            <span class='flex items-center gap-2'>
              <UiIcon @icon='users' @type='mini' />
              <span>Choose a rider...</span>
            </span>
          </:placeholder>

          <:default>
            {{#if @rider}}
              <RiderItem @rider={{@rider}} />
            {{/if}}
          </:default>
        </combobox.Button>

        {{#if this.isLoading}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each (sortBy 'displayName' this.riders.records) as |rider|}}
            <combobox.Option @value={{rider}} as |option|>
              <RiderItem @rider={{rider}} @isActive={{option.active}} />
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No riders found.
            </combobox.Empty>
          {{/each}}

          {{#if this.moreRidersCount}}
            <combobox.Option @disabled={{true}} @value=''>
              {{this.moreRidersCount}}
              more available.
            </combobox.Option>
          {{/if}}
        {{/if}}
      </Group.Combobox>
    </@formGroup>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::RiderCombobox': typeof RiderComboboxComponent;
    }
}
