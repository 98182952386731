import { template as template_8e34824c6e0f42e3b3e44870fc12fcce } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { hash } from '@ember/helper';
import type { DateRangeOption, FilterDateRange } from '../../utils/filters/date-range';
import CustomDateRange from './custom-date-range';
import type { DateRange } from './custom-date-range';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export interface DateRangeFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: FilterDateRange | null;
        label: string;
        options: DateRangeOption[];
        onChange: (dateRange: FilterDateRange) => unknown;
    };
    Blocks: {
        helpText: [];
    };
}
export default class DateRangeFilterComponent extends Component<DateRangeFilterSignature> {
    @tracked
    startDate: string | null = this.args.dateRange?.startDate ?? null;
    @tracked
    endDate: string | null = this.args.dateRange?.endDate ?? null;
    get selectedDateRange() {
        return this.args.options.find((dateRange)=>dateRange.id === this.args.dateRange?.datePeriod);
    }
    get showCustom() {
        return this.args.dateRange?.datePeriod === 'custom';
    }
    get groupingClasses() {
        return `border-l-4 ${this.showCustom ? 'border-purple-500' : 'border-transparent'} pl-3 -ml-4`;
    }
    @action
    dateRangeSelected(option: DateRangeOption) {
        this.args.onChange({
            datePeriod: option.id,
            // We don't want to send the dates for named ranges.
            startDate: null,
            endDate: null
        });
    }
    @action
    customDateRangeSelected(dateRange: DateRange) {
        this.startDate = dateRange.startDate;
        this.endDate = dateRange.endDate;
        this.args.onChange({
            datePeriod: 'custom',
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        });
    }
    static{
        template_8e34824c6e0f42e3b3e44870fc12fcce(`
    <div
      class='flex flex-col gap-2 transition {{this.groupingClasses}}'
      ...attributes
    >
      <@form.Group data-test-id='date-range' as |Group|>
        <Group.Label class='flex items-center gap-1'>
          {{@label}}
        </Group.Label>
        <Group.Listbox
          @value={{this.selectedDateRange}}
          @onChange={{this.dateRangeSelected}}
          as |listbox|
        >
          <listbox.Button @placeholder='All Time'>
            {{this.selectedDateRange.label}}
          </listbox.Button>

          <listbox.Options as |options|>
            {{#each @options as |dateRange|}}
              <options.Option @value={{dateRange}} as |option|>
                <span class='flex flex-col gap-1'>
                  <span data-test-id='label'>
                    {{dateRange.label}}
                  </span>
                  <span
                    data-test-id='description'
                    class='{{if option.active "text-white" "text-gray-500"}}
                      text-xs'
                  >
                    {{dateRange.description}}
                  </span>
                </span>
              </options.Option>
            {{else}}
              <options.Empty>
                There are no Date Ranges.
              </options.Empty>
            {{/each}}
          </listbox.Options>
        </Group.Listbox>

        {{#if (has-block 'helpText')}}
          <Group.HelpText>
            {{yield to='helpText'}}
          </Group.HelpText>
        {{/if}}
      </@form.Group>

      {{#if this.showCustom}}
        <CustomDateRange
          @form={{@form}}
          @dateRange={{hash startDate=this.startDate endDate=this.endDate}}
          @onChange={{this.customDateRangeSelected}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::DateRangeFilter': typeof DateRangeFilterComponent;
    }
}
