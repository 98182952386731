import { template as template_ba495715dd94423b9182ae61928b8ac1 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import type AccountService from '../../../services/account';
import type TransportReasonModel from '../../../models/transport-reason';
import UiSubfield from '../../ui-subfield';
interface TransportReasonsListboxSignature {
    Args: {
        formGroup: typeof UiFormGroup;
        onChange: (transportReasonId: string | null, comment: string | null) => void;
        transportReasonId: string | null;
        transportReasonComment: string | null;
    };
}
export default class TransportReasonsListboxComponent extends Component<TransportReasonsListboxSignature> {
    @service
    account: AccountService;
    get options() {
        const records = this.account.transportReasons;
        if (records.length === 0) {
            return [];
        }
        const categories = records.reduce((acc: string[], record)=>{
            if (!acc.includes(record.categoryName)) {
                acc.push(record.categoryName);
            }
            return acc;
        }, []);
        return categories.map((category)=>{
            const categoryRecords = records.filter((record)=>record.categoryName === category);
            return {
                groupName: category,
                options: categoryRecords
            };
        });
    }
    get selectedOption() {
        const records = this.account.transportReasons;
        return records.find((record)=>record.id === this.args.transportReasonId);
    }
    get showComment() {
        return this.selectedOption?.requireComment ?? false;
    }
    @action
    handleChange(value: TransportReasonModel) {
        // We need to clear the comment since they are changing the transport
        // reason.
        this.args.onChange(value.id, null);
    }
    @action
    handleCommentChange(value: string) {
        this.args.onChange(this.args.transportReasonId, value);
    }
    static{
        template_ba495715dd94423b9182ae61928b8ac1(`
    {{#if this.account.transportReasons}}
      <@formGroup data-test-id='transport-reason' as |Group|>
        <Group.Label>Reason for Transport</Group.Label>
        <Group.NestedListbox
          @options={{this.options}}
          @displayParam='name'
          @value={{this.selectedOption}}
          @onChange={{this.handleChange}}
        />
      </@formGroup>

      {{#if this.showComment}}
        <UiSubfield class='-mt-3'>
          <@formGroup data-test-id='transport-reason-comment' as |Group|>
            <Group.Label class='flex items-center'>
              Comment
            </Group.Label>
            <Group.Textarea
              @value={{@transportReasonComment}}
              @onChange={{this.handleCommentChange}}
              rows='2'
            />
          </@formGroup>
        </UiSubfield>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::TransportReasonsListbox': typeof TransportReasonsListboxComponent;
    }
}
