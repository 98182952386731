import { template as template_e90196d447a949e8bcc3ef9f6ebf457c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface VolumeByDayOfWeekSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const VolumeByDayOfWeek: TOC<VolumeByDayOfWeekSignature> = template_e90196d447a949e8bcc3ef9f6ebf457c(`
  <BasicColumnChart
    @modelName='volume-by-day-of-week'
    @stacking='normal'
    @title='Volume by Day of Week'
    @tooltipText='Count of requested trips within the date range grouped by day of week.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default VolumeByDayOfWeek;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByDayOfWeek': typeof VolumeByDayOfWeek;
    }
}
