import EmberRouter from '@ember/routing/router';
import config from 'onward-portal/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('access-denied');
  this.route('sign-in');
  // The sign-out route is necessary because when we clear out the session of
  // the user roles and other information, if they were on a page that required
  // roles, they will get an access denied error. Redirecting them to a public
  // route allows us to clear things out on a public page without errors.
  this.route('sign-out');

  this.route('authenticated', { path: '' }, function () {
    this.route('admin', function () {
      this.route('rides', function () {
        this.route('details', { path: '/:id/details' });
      });

      // This is the old route and is not used. It's only here so that if a user
      // tries to go to this old route, we can redirect them to the new one.
      this.route('vectorcare');
    });

    this.route('reporting', function () {
      this.route('finance');
      this.route('operations');
      this.route('performance');
      this.route('volumes');
    });

    this.route('rides', function () {
      this.route('new', { path: '/new/:transport_type' });
    });

    this.route('impersonate');
    this.route('stop-impersonation');
    this.route('settings');

    this.route('va-tools', function () {
      this.route('ride-requests', function () {
        this.route('index', { path: '/' });
        this.route('import');
        this.route('new');
        this.route('open');
        this.route('closed');
        this.route('details', { path: '/:id/details' });
      });
    });

    this.route('vectorcare');
  });

  // This must be the last route since it has a "catch all" path.
  this.route('not-found', { path: '/*path' });
});
