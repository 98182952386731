import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
import { or } from 'ember-truth-helpers';
import { hash } from '@ember/helper';
// @ts-expect-error TODO: Figure out how to fix this.
import headlessuiFocusTrap from '@onwardcare/ember-headlessui/modifiers/headlessui-focus-trap.js';
import ComboboxInput from './-input.gts';
import ComboboxOption from './-option.gts';
import ComboboxOptions from './-options.gts';

export interface UiHeadlessComboboxContentSignature {
  Element: HTMLDivElement;
  Args: {
    /**
     * Will hold onto the last active option when moving your mouse off of the
     * combobox options.
     */
    hold?: boolean;
    /**
     * When static, it will always render the combobox options.
     */
    static?: boolean;
  };
}

interface InternalSignature extends UiHeadlessComboboxContentSignature {
  Args: {
    activeOptionGuid?: string | null;
    guid: string;
    handleClickOutside: (event: MouseEvent) => void;
    handleKeyDown: (event: KeyboardEvent) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    handleKeyUp: (event: KeyboardEvent) => void;
    hold?: boolean;
    isOpen: boolean;
    labelId: string;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
    optionsFallbackFocus: () => HTMLElement | null;
    optionsReturnFocus: () => HTMLElement | null;
    registerInputElement: (
      inputComponent: ComboboxInput,
      element: HTMLInputElement,
    ) => void;
    registerOptionElement: (
      optionComponent: ComboboxOption,
      element: HTMLLIElement,
    ) => void;
    registerOptionsElement: (element: HTMLUListElement) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedValue?: any;
    setActiveOption: (optionComponent: ComboboxOption) => void;
    setSelectedOption: (event: Event) => void;
    static?: boolean;
    unregisterOptionElement: (
      optionComponent: ComboboxOption,
      element: HTMLLIElement,
    ) => void;
    unsetActiveOption: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  };
  Blocks: {
    default: [
      {
        Input: WithBoundArgs<
          typeof ComboboxInput,
          | 'guid'
          | 'handleKeyDown'
          | 'handleKeyPress'
          | 'handleKeyUp'
          | 'name'
          | 'registerInputElement'
        >;
        Options: WithBoundArgs<
          typeof ComboboxOptions,
          | 'activeOptionGuid'
          | 'guid'
          | 'handleKeyDown'
          | 'handleKeyPress'
          | 'handleKeyUp'
          | 'hold'
          | 'labelId'
          | 'onChange'
          | 'registerOptionElement'
          | 'registerOptionsElement'
          | 'selectedValue'
          | 'setActiveOption'
          | 'setSelectedOption'
          | 'unregisterOptionElement'
          | 'unsetActiveOption'
        >;
      },
    ];
  };
}

const UiHeadlessComboboxContentComponent: TOC<InternalSignature> = <template>
  {{#if (or @isOpen @static)}}
    <div
      {{headlessuiFocusTrap
        focusTrapOptions=(hash
          clickOutsideDeactivates=@handleClickOutside
          fallbackFocus=@optionsFallbackFocus
          setReturnFocus=@optionsReturnFocus
        )
      }}
      ...attributes
    >
      {{yield
        (hash
          Input=(component
            ComboboxInput
            guid=@guid
            handleKeyDown=@handleKeyDown
            handleKeyPress=@handleKeyPress
            handleKeyUp=@handleKeyUp
            name=@name
            registerInputElement=@registerInputElement
          )
          Options=(component
            ComboboxOptions
            activeOptionGuid=@activeOptionGuid
            guid=@guid
            handleKeyDown=@handleKeyDown
            handleKeyPress=@handleKeyPress
            handleKeyUp=@handleKeyUp
            hold=@hold
            labelId=@labelId
            onChange=@onChange
            registerOptionElement=@registerOptionElement
            registerOptionsElement=@registerOptionsElement
            selectedValue=@selectedValue
            setActiveOption=@setActiveOption
            setSelectedOption=@setSelectedOption
            unregisterOptionElement=@unregisterOptionElement
            unsetActiveOption=@unsetActiveOption
          )
        )
      }}
    </div>
  {{/if}}
</template>;

export default UiHeadlessComboboxContentComponent;
