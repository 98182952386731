import type { TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import type { TabVariation } from '../ui-tab-group.ts';
import TabPanel from './tab-panel.gts';

export interface TabPanelsSignature {
  Element: HTMLDivElement;
  Args: {
    onPanelRegistered: (panelElement: HTMLDivElement) => unknown;
    panelIds: string[];
    selectedIndex: number;
    tabIds: string[];
    variation: TabVariation;
  };
  Blocks: {
    default: [
      {
        TabPanel: WithBoundArgs<
          typeof TabPanel,
          | 'onPanelRegistered'
          | 'panelIds'
          | 'selectedIndex'
          | 'tabIds'
          | 'variation'
        >;
      },
    ];
  };
}

const TabPanels: TOC<TabPanelsSignature> = <template>
  <div ...attributes>
    {{yield
      (hash
        TabPanel=(component
          TabPanel
          onPanelRegistered=@onPanelRegistered
          panelIds=@panelIds
          selectedIndex=@selectedIndex
          tabIds=@tabIds
          variation=@variation
        )
      )
    }}
  </div>
</template>;

export default TabPanels;
