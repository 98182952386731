import * as Sentry from '@sentry/ember';
import config from 'onward-portal/config/environment';
import { getEnvironment } from './environment';
import type User from '../models/user';

const ENABLED = config.environment === 'production';

/**
 * Initialize error logging with Sentry.
 */
export function initErrorLogging() {
  if (!ENABLED) {
    console.log('Error logging is disabled in development mode');
    return;
  }

  Sentry.init({
    dsn: config.sentryDsn,
    release: config.releaseCommit,
    environment: getEnvironment(),
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
  });
}

/**
 *  Set the user context for Sentry.
 *
 * @param user The logged in user.
 * @param impersonatedUser (Optional) The user being impersonated.
 */
export function setUserContext(
  user: User | null,
  impersonatedUser?: User | null,
) {
  if (!ENABLED) {
    return;
  }

  if (user === null) {
    Sentry.setUser(null);
  } else {
    Sentry.setUser({
      id: user.id,
      impersonateId: impersonatedUser?.id,
    });
  }
}

/**
 * Additional information to capture with a message for Sentry.
 */
export interface CaptureContext {
  /**
   * The route the user was coming from.
   */
  from?: string;
  /**
   * The route the user was trying to access.
   */
  to?: string;
}

/**
 * Capture an error with Sentry.
 *
 * @param error An error object or a string message.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function captureException(error: any) {
  if (!ENABLED) {
    console.log(error);
    return;
  }

  Sentry.captureException(error instanceof Error ? error : new Error(error));
}

/**
 * Capture a message with Sentry.
 *
 * @param message The message to log.
 * @param context (Optional) Additional context to include.
 */
export function captureMessage(message: string, context?: CaptureContext) {
  if (!ENABLED) {
    console.log(message, context);
    return;
  }

  Sentry.captureMessage(
    message,
    context
      ? {
          extra: { ...context },
        }
      : undefined,
  );
}
