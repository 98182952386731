import { template as template_dbff6adb50814a988f191067dacbe8ae } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type SessionService from '../services/session';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { on } from '@ember/modifier';
import type { RouteModel } from '@onwardcare/ui-components/components/ui-base-link';
import MobileNavigation from './main-layout/mobile-navigation';
import DesktopNavigation from './main-layout/desktop-navigation';
import NavigationMenu from './main-layout/navigation-menu';
import AccountMenu from './main-layout/account-menu';
import UserMenu from './main-layout/user-menu';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type SettingsService from '../services/settings';
import ROLES from '../utils/data/user-roles';
export type MenuItemType = 'folder' | 'item' | 'subitem';
export type MenuItem = {
    name: string;
    type: MenuItemType;
    route?: string;
    href?: string;
    requiredRoles?: string[];
    internalOnly?: boolean;
    icon?: string;
    model?: RouteModel;
    models?: [RouteModel];
    testId: string;
    children?: MenuItem[];
};
/**
 * Determines if a menu item is internal-only or requires admin roles.
 *
 * @param item The menu item.
 * @returns Returns true if the item is internal-only or requires admin roles.
 */ export function isInternalItem(item: MenuItem) {
    return (item.internalOnly || item.requiredRoles?.includes(ROLES.ADMIN) === true);
}
/**
 * Checks to see if a menu item should be visible based on the user's roles.
 *
 * @param item The menu item.
 * @param session The session service.
 * @returns Returns true if the route is active; otherwise, false.
 */ export function isVisibleItem(item: MenuItem, session: SessionService) {
    if (item.internalOnly) {
        return session.isOnwardAssociate;
    } else if (item.requiredRoles) {
        return session.hasRoles(item.requiredRoles);
    }
    return true;
}
export interface MainLayoutSignature {
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
export default class MainLayout extends Component<MainLayoutSignature> {
    @tracked
    menuOpen = false;
    @service
    session: SessionService;
    @service
    settings: SettingsService;
    get menuItems() {
        return [
            {
                name: 'Reporting',
                type: 'folder',
                route: 'authenticated.reporting',
                icon: 'chart-bar',
                testId: 'reporting',
                requiredRoles: [
                    ROLES.CAN_ACCESS_BI
                ],
                children: [
                    {
                        name: 'Finance',
                        type: 'subitem',
                        route: 'authenticated.reporting.finance',
                        testId: 'finance',
                        internalOnly: true
                    },
                    {
                        name: 'Operations',
                        type: 'subitem',
                        route: 'authenticated.reporting.operations',
                        testId: 'operations',
                        internalOnly: true
                    },
                    {
                        name: 'Performance',
                        type: 'subitem',
                        route: 'authenticated.reporting.performance',
                        testId: 'performance'
                    },
                    {
                        name: 'Volumes',
                        type: 'subitem',
                        route: 'authenticated.reporting.volumes',
                        testId: 'volumes'
                    }
                ]
            },
            {
                name: 'VA Tools',
                type: 'folder',
                route: 'authenticated.va-tools',
                requiredRoles: [
                    ROLES.ADMIN
                ],
                icon: 'wrench',
                testId: 'va-tools',
                children: [
                    {
                        name: 'Ride Requests',
                        type: 'subitem',
                        route: 'authenticated.va-tools.ride-requests',
                        testId: 'ride-requests',
                        requiredRoles: [
                            ROLES.ADMIN
                        ]
                    }
                ]
            },
            {
                name: 'Administration',
                type: 'folder',
                route: 'authenticated.admin',
                internalOnly: true,
                icon: 'globe-alt',
                testId: 'admin',
                children: [
                    {
                        name: 'Rides',
                        type: 'subitem',
                        route: 'authenticated.admin.rides',
                        testId: 'rides',
                        internalOnly: true
                    }
                ]
            },
            {
                name: 'Dashboard',
                type: 'item',
                href: this.settings.getValue('DASHBOARD_URL'),
                icon: 'arrow-left-circle',
                testId: 'dashboard'
            }
        ] as MenuItem[];
    }
    @action
    closeMenu() {
        this.menuOpen = false;
    }
    @action
    openMenu() {
        this.menuOpen = true;
    }
    static{
        template_dbff6adb50814a988f191067dacbe8ae(`
    <MobileNavigation @isOpen={{this.menuOpen}} @closeMenu={{this.closeMenu}}>
      <NavigationMenu @menuItems={{this.menuItems}} />
    </MobileNavigation>

    <DesktopNavigation>
      <NavigationMenu @menuItems={{this.menuItems}} />
    </DesktopNavigation>

    <div class='lg:pl-72'>
      <div
        class='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'
      >
        <button
          type='button'
          data-test-id='mobile-menu-button'
          class='-m-2.5 p-2.5 text-gray-700 lg:hidden'
          {{on 'click' this.openMenu}}
        >
          <span class='sr-only'>Open sidebar</span>
          <UiIcon @icon='bars-3' />
        </button>

        {{! Separator }}
        <div class='h-6 w-px bg-gray-900/10 lg:hidden' aria-hidden='true'></div>

        <div class='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
          <div class='relative flex flex-1'>
            {{! Left header content goes here... }}
          </div>
          <div class='flex items-center gap-x-4 lg:gap-x-6'>
            {{#if this.session.isAuthenticated}}
              <AccountMenu />

              {{! Separator }}
              <div
                class='hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10'
                aria-hidden='true'
              ></div>

              <UserMenu />
            {{/if}}
          </div>
        </div>
      </div>

      <main class='relative'>
        {{yield}}
      </main>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        MainLayout: typeof MainLayout;
    }
}
