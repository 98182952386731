import { template as template_6cec2bfab6bf4c0db58de66e9e033682 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import { add, startOfDay, endOfDay } from 'date-fns';
import { createDateRangeItem, formatISOTimestamp, type DateRangeOption, type FilterDateRange } from '../../utils/filters/date-range';
import DateRangeFilter from './date-range-filter';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export const dateRanges: DateRangeOption[] = [
    createDateRangeItem('today', 'Today', startOfDay(new Date()), endOfDay(new Date())),
    createDateRangeItem('today-upcoming', 'Today and Upcoming', startOfDay(new Date()), null),
    createDateRangeItem('upcoming', 'Upcoming', new Date(), null, {
        startDescription: 'Now',
        endDescription: 'Future',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        transformValue: (_date)=>formatISOTimestamp(new Date())
    }),
    createDateRangeItem('yesterday', 'Yesterday', startOfDay(add(new Date(), {
        days: -1
    })), endOfDay(add(new Date(), {
        days: -1
    }))),
    createDateRangeItem('yesterday-before', 'Yesterday and Before', null, endOfDay(add(new Date(), {
        days: -1
    }))),
    createDateRangeItem('past', 'Past', null, new Date(), {
        startDescription: 'Past',
        endDescription: 'Now',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        transformValue: (_date)=>formatISOTimestamp(new Date())
    }),
    createDateRangeItem('custom', 'Custom', null, null)
];
const RequestedStartTimeFilter: TOC<{
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: FilterDateRange | null;
        onChange: (dateRange: FilterDateRange) => unknown;
    };
    Blocks: EmptyObject;
}> = template_6cec2bfab6bf4c0db58de66e9e033682(`
  <DateRangeFilter
    data-test-id='requested-start-time'
    @form={{@form}}
    @dateRange={{@dateRange}}
    @label='Requested Start Time'
    @options={{dateRanges}}
    @onChange={{@onChange}}
  >
    <:helpText>
      Uses the timezone of account the ride was booked in.
    </:helpText>
  </DateRangeFilter>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RequestedStartTimeFilter;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RequestedStartTimeFilter': typeof RequestedStartTimeFilter;
    }
}
